import React from "react";
import dayjs from "dayjs";
import { EventCard } from "../../components";
import {
  useGetEventGroupsQuery,
  useGetEventsQuery,
} from "../../services/event.api";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import { useAppSelector } from "../../app/hooks";
import { authSelect } from "../Authentication/authSlice";
import { AuthModal } from "../Authentication";
import { EventTicket } from "../../models/event.model";
import moment from "moment-timezone";

export const UpcomingEvents = ({ search = "" }) => {
  const { user } = useAppSelector(authSelect);
  const [open, setOpen] = React.useState(user === null);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(9);
  const { data, isLoading, isError } = useGetEventsQuery({
    skip,
    take: take * page,
    search: search || "",
  });
  const { data: eventGroups } = useGetEventGroupsQuery({
    skip,
    take: take * page,
    search: search || "",
  });

  React.useEffect(() => {
    if (user === null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [user]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="px-2 mb-2 lg:mb-4 lg:px-0">
      <div className="text-xl font-semibold uppercase">All Events</div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        {data && data.data.length > 0 ? (
          data &&
          data.data.map((event, index) => {
            return (
              <EventCard
                slug={event.slug}
                title={event.name}
                discount={event.discount}
                title_caption={`${event.performers
                  .map((performer) => performer.performer.name)
                  .join(", ")} ${
                  event.groups.length > 0
                    ? `,${event.groups
                        .map(({ group }) => group.name)
                        .join(", ")}`
                    : ""
                }`}
                isHotDeal={event?.eventTickets && event?.eventTickets
                  .map(({ isHotDeal }: EventTicket) => isHotDeal)
                  .includes(true)}
                venue={`${event.venue.name}, ${event.venue.city}`}
                date={moment
                  .tz(event.dateTime, event.timezone)
                  .format("MMMM DD, YYYY hh:mm A")}
                description={event.description}
                image={
                  event.image
                    ? process.env.REACT_APP_IMAGE_BASE_URL +
                      event.image.fileName
                    : ""
                }
                id={event.id}
                key={index}
              />
            );
          })
        ) : (
          <>
            <div className="text-xl">
              <p>No Available Events</p>
            </div>
          </>
        )}
      </div>
      {data && data?.count > take * page && (
        <div className="flex justify-center my-6">
          <div>
            <button
              onClick={() => setPage(page + 1)}
              className="w-48 p-2 text-white uppercase rounded-sm drop-shadow bg-blue-1100"
            >
              See more
            </button>
          </div>
        </div>
      )}
      <div className="text-xl font-semibold uppercase">Group Events</div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        {/* Event GROUP LIST Start */}
        {eventGroups && eventGroups.data.length > 0 ? (
          eventGroups &&
          eventGroups.data.map((eventGroup, index) => {
            return (
              <EventCard
                isGroup={true}
                link={"event-group"}
                slug={eventGroup.slug}
                title={eventGroup.name}
                title_caption={""}
                venue={eventGroup.description}
                date={eventGroup.description || ""}
                description={eventGroup.description}
                image={
                  eventGroup.image
                    ? process.env.REACT_APP_IMAGE_BASE_URL +
                      eventGroup.image.fileName
                    : ""
                }
                id={eventGroup.id}
                key={index}
              />
            );
          })
        ) : (
          <>
            <div className="text-xl">
              <p>No Available Events</p>
            </div>
          </>
        )}
      </div>
      <AuthModal open={open} setOpen={setOpen} />
      {/* EVENT GROUP LIST END */}
    </div>
  );
};
