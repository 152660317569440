import React from "react";
import { useParams } from "react-router-dom";
import { SelectedEvent } from "../../features";

const SelectedEventPage = () => {
  const { id } = useParams<UpdateEventParams>() as UpdateEventParams;
  type UpdateEventParams = {
    id: string;
  };
  return (
    <div className="w-full">
      <SelectedEvent id={id} />
    </div>
  );
};

export default SelectedEventPage;
