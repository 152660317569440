import {
  ArrowRight,
  ClipboardList,
  Search,
  ShieldCheck,
  Tag,
  Ticket,
} from "lucide-react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import bannerImage from "../../assets/images/resell/banner-1.png";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import { ResellBreadCrumbs } from "./ResellBreadcrumbs";

export default function ResellLandingPage() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Resell Your Tickets | TicketNation</title>
        <meta
          name="description"
          content="Sell your tickets quickly and easily on TicketNation. Our platform attracts thousands of buyers daily, maximizing your chances of a quick sale."
        />
        <meta
          name="keywords"
          content="ticket resell, sell tickets, ticket marketplace, TicketNation"
        />
      </Helmet>
      <div className="min-h-screen bg-gray-50">
        <div className="">
          <ResellBreadCrumbs items={[{ label: "Resell", href: "/resell" }]} />
        </div>
        <header
          className="relative py-20 text-white bg-blue-1100"
          style={{
            backgroundImage: `url(${bannerImage})`,
            backgroundSize: "cover",
          }}
        >
          <div className="container px-4 mx-auto">
            <h1 className="mb-4 text-4xl font-bold">
              Buy and Sell Event Tickets with Confidence
            </h1>
            <p className="mb-8 text-xl">
              TicketNation Resell offers a secure, trusted platform to connect
              ticket buyers and sellers. Whether you're looking to sell extra
              tickets or purchase sold-out event tickets, we've got you covered!
            </p>
            <div className="flex flex-col gap-4 sm:flex-row">
              <Button
                size="lg"
                className="bg-white text-blue-1100 hover:bg-blue-50"
                onClick={() => {
                  navigate("/resell/sellers");
                }}
              >
                Sell Tickets
                <ArrowRight className="w-4 h-4 ml-2" />
              </Button>
              <Button
                size="lg"
                variant="outline"
                className="text-white bg-transparent border-white hover:bg-blue-700"
                onClick={() => {
                  navigate("/search");
                }}
              >
                Find Tickets
                <Search className="w-4 h-4 ml-2" />
              </Button>
              <Button
                size="lg"
                variant="outline"
                className="text-white bg-transparent border-white hover:bg-blue-700"
                onClick={() => {
                  navigate("/resell/buyers");
                }}
              >
                Are you a Buyer?
                <ClipboardList className="w-4 h-4 ml-2" />
              </Button>
            </div>
          </div>
        </header>

        <main className="container px-4 py-16 mx-auto">
          <section className="mb-20">
            <h2 className="mb-8 text-3xl font-bold text-center">
              How TicketNation Resell Works
            </h2>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
              {[
                {
                  title: "List Your Tickets",
                  icon: <Tag className="w-8 h-8 mb-4" />,
                  description:
                    "Easily post your tickets for sale, set your own price, and wait for interested buyers to reach out.",
                },
                {
                  title: "Find Tickets",
                  icon: <Search className="w-8 h-8 mb-4" />,
                  description:
                    "Looking for tickets to an event that's sold out? Browse through available tickets listed by trusted sellers.",
                },
                {
                  title: "Are you a Buyer?",
                  icon: <ClipboardList className="w-8 h-8 mb-4" />,
                  description:
                    "Can't find what you're looking for? Submit a buyer request and let sellers come to you with offers.",
                },
                {
                  title: "Safe Transactions",
                  icon: <ShieldCheck className="w-8 h-8 mb-4" />,
                  description:
                    "Our secure platform ensures a seamless transaction between buyers and sellers with verified profiles and a transparent process.",
                },
              ].map((item, index) => (
                <Card key={index}>
                  <CardHeader>
                    <CardTitle className="flex flex-col items-center">
                      {item.icon}
                      {item.title}
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-center text-gray-600">
                      {item.description}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </section>

          <Tabs defaultValue="buyers" className="mb-20">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="buyers">For Buyers</TabsTrigger>
              <TabsTrigger value="sellers">For Sellers</TabsTrigger>
            </TabsList>
            <TabsContent value="buyers">
              <Card>
                <CardHeader>
                  <CardTitle>Benefits for Buyers</CardTitle>
                  <CardDescription>
                    Find the tickets you want, even for sold-out events
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <ul className="pl-5 space-y-2 list-disc">
                    <li>
                      Access to exclusive tickets even after an event is sold
                      out.
                    </li>
                    <li>
                      Verified sellers to ensure safe and legitimate
                      transactions.
                    </li>
                    <li>
                      Set your own budget using our "Buyer Request" feature and
                      let sellers offer tickets within your price range.
                    </li>
                    <li>Fast and easy checkout with secure payment options.</li>
                  </ul>
                </CardContent>
              </Card>
            </TabsContent>
            <TabsContent value="sellers">
              <Card>
                <CardHeader>
                  <CardTitle>Benefits for Sellers</CardTitle>
                  <CardDescription>
                    Sell your tickets quickly and securely
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <ul className="pl-5 space-y-2 list-disc">
                    <li>
                      Reach a wide audience of ready-to-buy ticket seekers.
                    </li>
                    <li>
                      Set your own price and get offers from interested buyers.
                    </li>
                    <li>
                      No hassle listing – quick and easy process to get your
                      tickets online.
                    </li>
                    <li>
                      Verified buyers ensure you only transact with legitimate
                      users.
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>

          <section className="text-center">
            <h2 className="mb-8 text-3xl font-bold">
              Join the TicketNation Resell community today!
            </h2>
            <div className="flex flex-wrap justify-center gap-4">
              <Button
                size="lg"
                className="text-white bg-blue-1100 hover:bg-blue-700"
                onClick={() => {
                  navigate("/resell/sellers");
                }}
              >
                Sell Tickets
                <Ticket className="w-4 h-4 ml-2" />
              </Button>
              <Button
                size="lg"
                variant="outline"
                onClick={() => navigate("/search")}
              >
                Find Tickets
                <Search className="w-4 h-4 ml-2" />
              </Button>
              <Button
                size="lg"
                variant="outline"
                onClick={() => navigate("/resell/buyers")}
              >
                Are you a Buyer?
                <ClipboardList className="w-4 h-4 ml-2" />
              </Button>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}
