import { Deal, DealVariant } from "./deal.model";
import { EventTicket } from "./event.model";
import { Group } from "./group.model";
import { ProductOrder } from "./order.model";
import { Event, OrderEventTicket } from "./orderEventTickets.model";
import { Performer } from "./performer.model";
import { ProductVariant } from "./product.model";
import { Voucher } from "./voucher.model";

// Main Order Interface
export interface OrderV2 {
  id: string;
  orderNumber: number;
  events: Event[];
  eventTicketOrder?: EventTicket; // Optional to support event tickets in the order
  productOrder?: ProductOrder; // Optional to support products in the order
  dealOrder?: DealOrderV2; // Optional to support deals in the order
  isRefunded: boolean;
  status: OrderStatus;
  category: OrderCategory;
  referenceId?: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  voucher: Voucher;
}

// Product Order Variant Interface
export interface ProductOrderVariantV2 {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  quantity: number;
  tax: number;
  commission: number;
  subtotal: number;
  productOrderId: string;
  productVariantId: string;
  productVariant: ProductVariant;
}
export interface DealItem {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  code: string; // Unique code for each deal item
  isClaimed: boolean; // Indicates if the item has been claimed
  specialNote?: string | null; // Optional special notes for each deal item
  dealOrderId: string; // Reference to the associated deal order
}

export interface DealOrderV2 {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  dealId: string;
  deal: Deal;
  dealVariantId: string;
  quantity: number;
  totalPrice: number;
  orderId: string;
  organizationId: string;
  organizationName: string;
  voucherId?: string | null;
  variantDetails: string; // JSON string containing variant snapshot details
  selectedDates?: string; // JSON string containing variant snapshot details
  dealItems: DealItem[]; // Array of items in the deal order
  dealVariant: DealVariant; // Variant details of the deal
}

// Additional Interfaces and Enums

// Event Ticket Interface for Ticket Details
export interface EventTicketV2 {
  id: string;
  eventId: string;
  name: string;
  description: string;
  type: string;
  quantity: number;
  price: number;
}

// Event Performer and Group for Relational Data
export interface EventPerformerV2 {
  performer: Performer;
}

export interface EventGroupV2 {
  group: Group;
}

// enums for possible order statuses and types

export enum OrderType {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}

// Interface for the CreateOrderV2Response
export interface CreateOrderV2Response {
  id: string;
  orderNumber: number;
  createdAt: string;
  updatedAt: string;
  expiredAt: string;
  status: OrderStatus;
  type: OrderType;
  isPrinted: boolean;
  isExpiredEmailSent: boolean;
  exchange: string;
  referenceId: string | null;
  userId: string;
  creatorId: string | null;
  voucherId: string | null;
  total: number;
  totalDiscount: number;
  orderFee: number;
  totalPerTicketFee: number;
  subTotal: number;
  totalCommission: number;
  isTransfer: boolean;
  isRefunded: boolean;
  refundedThrough: string | null;
  originalOrderId: string | null;
  category: string; // Could be an enum if there are defined categories
  orderNotes: string | null;
}

// Order Payload Interface for API Request
export interface OrderPayloadV2 {
  eventTicketId: string;
  quantity: number;
}

// Payment Payload for Payment Information
export interface PaymentPayloadV2 {
  provider: string;
  account: string;
  status: string;
  amount: number;
}

// CreateOrderV2 Interface for Order Creation Payload
export interface CreateOrderV2 {
  category: OrderCategory;
  referenceId?: string;
  eventTicketOrder?: EventTicket; // Nested DTO to create event ticket orders
  productOrder?: CreateProductOrderV2; // Nested DTO to create product orders
  dealOrder?: CreateDealOrderV2; // Nested DTO to create deal orders
}

// Product Order Payload for Creation
export interface CreateProductOrderV2 {
  productVariantId: string;
  quantity: number;
  price: number;
  deliveryMethod: DeliveryMethod;
}

// Deal Order Payload for Creation
export interface CreateDealOrderV2 {
  availabilityId: string;
  dealId: string;
  dealVariantId: string;
  quantity: number;
  totalPrice: number;
  variantDetails?: string;
  selectedDates?: string;
}

// Update Product Order Interface
export interface UpdateProductOrderV2 {
  productVariantOrderId: string;
  quantity: number;
  orderId: string;
}

// Delete Product Order Variant Interface
export interface DeleteProductOrderVariantV2 {
  productVariantOrderId: string;
  orderId: string;
}

// Get Order Response Interface for Fetching Orders
export interface GetOrderV2Response {
  id: string;
  orderNumber: number;
  createdAt: string;
  updatedAt: string;
  expiredAt: string;
  status: OrderStatus;
  type: string;
  isPrinted: boolean;
  isExpiredEmailSent: boolean;
  exchange: string;
  referenceId?: string | null;
  userId: string;
  creatorId?: string | null;
  voucherId?: string | null;
  total: number;
  totalDiscount: number;
  orderFee: number;
  totalPerTicketFee: number;
  subTotal: number;
  totalCommission: number;
  isTransfer: boolean;
  isRefunded: boolean;
  refundedThrough?: string | null;
  originalOrderId?: string | null;
  category: OrderCategory;
  orderNotes?: string | null;
  orderEventTickets: OrderEventTicket[];
  productOrder?: ProductOrder | null;
  dealOrders?: DealOrderV2[]; // Updated to an array of DealOrderV2
  voucher?: Voucher;
}

// Order Status Enum
export enum OrderStatus {
  CUSTOM = "CUSTOM",
  PENDING = "PENDING",
  FAILED = "FAILED",
  EXPIRED = "EXPIRED",
  COMPLETED = "COMPLETED",
}

// Order Category Enum
export enum OrderCategory {
  TICKET = "TICKET",
  PRODUCT = "PRODUCT",
  DEAL = "DEAL",
}

// Delivery Method Enum
export enum DeliveryMethod {
  PICK_UP = "PICK_UP",
  DELIVERY = "DELIVERY",
  ELECTRONIC = "ELECTRONIC",
  MOBILE = "MOBILE",
}
