import { DealVariantAvailability } from "../../../models/deal.model";
// import { useAddToCartMutation } from "../../../services/cart.api";
import { ShoppingBagIcon } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Skeleton } from "../../../components/ui/skeleton";

interface AddToCartButtonProps {
  dealVariantId: string;
  availability: DealVariantAvailability;
  isLoading: boolean;
}

const AddToCartButton: React.FC<AddToCartButtonProps> = ({
  dealVariantId,
  availability,
  isLoading,
}) => {
  // const [addToCart] = useAddToCartMutation();

  const handleAddToCart = async () => {
    try {
      if (availability.remaining < 1) {
        alert("Sorry, this item is currently out of stock.");
        return;
      }

      // Uncomment and implement the addToCart API call here
      // const response = await addToCart({
      //   userId,
      //   type: "DEAL",
      //   quantity: 1, // Default quantity is 1
      //   price: availability.price,
      //   dealVariantId,
      //   dealVariantAvailabilityId: availability.id,
      // }).unwrap();

      alert("Added to cart successfully!");
    } catch (error: any) {
      alert(error?.message || "Failed to add to cart.");
    }
  };

  return (
    <Button
      type="button"
      onClick={handleAddToCart}
      disabled={availability.remaining < 1 || isLoading}
      className="w-full text-white bg-primary"
    >
      <ShoppingBagIcon />
      {isLoading ? <Skeleton className="w-full h-6" /> : "Add to Cart"}
    </Button>
  );
};

export default AddToCartButton;
