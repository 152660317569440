import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import OrderContext from "../../../../pages/Order/context/OrderContext";
import productImage from "../../../../assets/images/product_image.jpg";

const Shop = () => {
  const { setStep } = useContext(OrderContext);
  const path = useLocation().pathname;
  React.useEffect(() => {
    setStep(2);
  }, [setStep]);
  return (
    <div>
      <Link to={`${path}/products`}>
        <div>
          <div
            className="h-48 overflow-hidden text-5xl font-semibold bg-center bg-no-repeat bg-cover rounded-xl"
            style={{ backgroundImage: `url(${productImage})` }}
          >
            <h1 className="relative flex items-center justify-center h-full text-center text-white transition-all duration-150 bg-black bg-opacity-80 hover:bg-opacity-0 ">
              <p className="relative">
                PRODUCT
                <span className="absolute px-2 py-1 text-sm font-semibold text-white rotate-45 bg-red-500 rounded-md animate-pulse -top-3 -right-6">
                  New
                </span>
              </p>
            </h1>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Shop;
