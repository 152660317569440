import { InfoCircledIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { Button } from "../../../components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";
import OtpContextProvider from "../../../pages/OtpEmail/context/OtpContextProvider";
import { useGetEventBuyerRequestQuery } from "../../../services/event.api";
import { AuthModal } from "../../Authentication";
import { authSelect } from "../../Authentication/authSlice";
import OfferWallBlocker from "./OfferWallBlocker";

export interface TicketGroup {
  id: string;
  name: string;
  price: number;
  quantity: number;
  isHotDeal: boolean;
  hasOffer?: boolean;
  offerId?: string;
}
interface EventTicketOfferProps {
  eventId: string;
}
const EventTicketOffer: React.FC<EventTicketOfferProps> = ({ eventId }) => {
  const { user } = useAppSelector(authSelect);

  const {
    data: eventBuyerRequest,
    isLoading,
    isError,
  } = useGetEventBuyerRequestQuery({
    id: eventId,
    skip: 1,
    take: 100,
    search: "",
    sortByDate: "asc",
    userId: user?.email || "", // Pass the logged-in user's ID
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<TicketGroup | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const otpId = searchParams.get("otpId");

  const ticketGroups: TicketGroup[] =
    eventBuyerRequest?.data.map((buyerRequest) => {
      return {
        id: buyerRequest.id,
        name: buyerRequest.section || "",
        price: buyerRequest.pricePerTicket || 0,
        quantity: buyerRequest.quantity || 0,
        isHotDeal: false,
        hasOffer: buyerRequest.hasUserOffered,
        offerId: buyerRequest?.userOffer?.id,
      };
    }) || [];

  const handleOfferClick = (ticket: TicketGroup) => {
    window.fbq("track", "Offer", {
      em: user?.email ? user?.email.toLowerCase() : "",
      ln: user?.lastName ? user?.lastName.toLowerCase() : "",
      fn: user?.firstName ? user?.firstName.toLowerCase() : "",
    });
    if (user === null) {
      setOpen(true);
    } else {
      setSelectedTicket(ticket);
      setIsModalOpen(true);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="overflow-hidden bg-white rounded-lg shadow-md">
        <div className="p-4">
          <TicketGroupList
            handleOfferClick={handleOfferClick}
            ticketGroups={ticketGroups}
          />
        </div>
      </div>
      <AuthModal open={open} setOpen={setOpen} />
      <OtpContextProvider otpId={otpId || ""}>
        <OfferWallBlocker
          isOpen={isModalOpen}
          handleOpen={setIsModalOpen}
          selectedTicket={selectedTicket}
          eventId={eventId}
        />
      </OtpContextProvider>
    </div>
  );
};

export default EventTicketOffer;

interface TicketGroupProps {
  ticketGroups: TicketGroup[];
  handleOfferClick: any;
}
const TicketGroupList: React.FC<TicketGroupProps> = ({
  ticketGroups,
  handleOfferClick,
}) => {
  return (
    <div>
      <div className="flex items-center justify-between pb-2 text-center border-b">
        <div className="grid w-full grid-cols-3 text-sm font-medium uppercase md: text:md">
          <p className="">Section</p>
          <p className="">Price Per Ticket</p>
          <p className="">Quantity</p>
        </div>
        <div className="flex gap-2">
          Action
          <Popover>
            <PopoverTrigger>
              <InfoCircledIcon />
            </PopoverTrigger>
            <PopoverContent>
              <h3 className="font-semibold text-gray-800">
                How It Works: Making an Offer and Counteroffer
              </h3>
              <p className="mt-1 text-xs text-gray-600">
                If you're a <strong>buyer</strong> and have a specific{" "}
                <strong>budget</strong> for a ticket, you can list it in our
                marketplace. For example, if you're looking for tickets with a
                budget of <strong>₱4,000</strong>, sellers who have tickets in
                the desired section can make offers matching or exceeding your
                budget.
              </p>

              <div className="mt-2">
                <p className="text-xs text-gray-600">
                  As a <strong>seller</strong>, when you see a buyer's request,
                  you can <strong>make an offer</strong> based on your ticket's
                  price. If the buyer's budget is <strong>₱4,000</strong>, but
                  you want to offer your ticket at <strong>₱5,500</strong>, you
                  can submit a <strong>counteroffer</strong>.
                </p>

                <div className="p-2 mt-2 bg-gray-100 rounded-lg">
                  <p className="text-xs text-gray-600">
                    The buyer will then receive your offer and can either{" "}
                    <strong>accept</strong> or <strong>reject</strong> it.
                  </p>
                  <ul className="pl-2 mt-1 text-xs text-gray-600 list-disc">
                    <li>
                      <strong>If the buyer accepts:</strong> The transaction
                      will proceed, and the buyer will complete the payment for
                      the agreed amount.
                    </li>
                    <li>
                      <strong>If the buyer rejects:</strong> The counteroffer
                      will be declined, and no transaction will occur.
                    </li>
                  </ul>
                </div>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
      {ticketGroups.map((ticket, index: any) => (
        <div
          key={index}
          className="flex items-center justify-between p-1 pb-2 text-center border-b"
        >
          <div className="grid w-full grid-cols-3 text-xs md:text-md">
            <p className="font-medium">{ticket.name}</p>
            <p className="text-gray-600 ">₱ {ticket.price} per ticket</p>
            <p className="text-gray-600 "> x {ticket.quantity}</p>
            {/* {ticket.isHotDeal && (
                    <span className="inline-flex items-center text-xs text-red-600">
                      <Flame className="w-4 h-4 mr-1" /> HOT DEALS
                    </span>
                  )} */}
          </div>
          {/* <Button disabled onClick={() => handleOfferClick(ticket)}>
            Offer (Coming Soon)
          </Button> */}

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  className="text-xs w-28"
                  disabled={ticket.hasOffer}
                  onClick={() => handleOfferClick(ticket)}
                >
                  {ticket.hasOffer ? "Pending" : "Offer"}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Do you have tickets? Send an offer!</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ))}
    </div>
  );
};
