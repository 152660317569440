import { CalendarIcon, Info, MapPin } from "lucide-react";
import { AspectRatio } from "../../../components/ui/aspect-ratio";
import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Skeleton } from "../../../components/ui/skeleton";
import {
  DealVariant,
  DealVariantAvailability,
} from "../../../models/deal.model";
import AddToCartButton from "./AddToCartButton";

interface DealSidebarProps {
  deal?: {
    price?: number;
    location?: {
      name: string;
      address: string;
    };
    businessHours?: {
      weekday: string;
      hours: string;
    }[];
  };
  isLoading: boolean;
  selectedVariant?: DealVariant;
  selectedAvailability?: DealVariantAvailability;
}

export const DealSidebar: React.FC<DealSidebarProps> = ({
  deal,
  isLoading,
  selectedVariant,
  selectedAvailability,
}) => {
  // Safely compute the price based on availability and deal data

  return (
    <div>
      {/* Price and Actions Card */}
      <Card className="hidden md:block">
        <CardHeader>
          {isLoading ? (
            <Skeleton className="w-24 h-8" />
          ) : (
            <CardTitle>₱{deal?.price?.toFixed(2)}</CardTitle>
          )}
          <CardDescription>Total Price</CardDescription>
        </CardHeader>
        <CardContent>
          <Button className="w-full mb-4" disabled={isLoading}>
            {isLoading ? <Skeleton className="w-full h-6" /> : "Book Now"}
          </Button>
          {selectedVariant && selectedAvailability ? (
            <AddToCartButton
              dealVariantId={selectedVariant.id}
              availability={selectedAvailability}
              isLoading={isLoading}
            />
          ) : (
            <Button className="w-full" disabled>
              {isLoading ? <Skeleton className="w-full h-6" /> : "Add to Cart"}
            </Button>
          )}
          <div className="mt-4 space-y-2">
            {isLoading ? (
              <>
                <Skeleton className="w-full h-6" />
                <Skeleton className="w-full h-6" />
              </>
            ) : (
              <>
                <div className="flex items-center">
                  <Info className="w-4 h-4 mr-2" />
                  <span>Instant confirmation</span>
                </div>
                <div className="flex items-center">
                  <CalendarIcon className="w-4 h-4 mr-2" />
                  <span>
                    Free cancellation 1 day(s) prior to experience date
                  </span>
                </div>
              </>
            )}
          </div>
        </CardContent>
      </Card>

      {/* Location Card */}
      <Card className="mt-4">
        <CardHeader>
          <CardTitle>Experience Location</CardTitle>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="space-y-2">
              <Skeleton className="w-full h-4" />
              <Skeleton className="w-3/4 h-4" />
              <Skeleton className="w-full h-32" />
            </div>
          ) : (
            <>
              <div className="flex items-start mb-2">
                <MapPin className="w-4 h-4 mt-1 mr-2" />
                <div>
                  <p className="font-semibold">
                    {deal?.location?.name || "Location Name"}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    {deal?.location?.address || "Location Address"}
                  </p>
                </div>
              </div>
              <AspectRatio ratio={16 / 9} className="mt-2 bg-muted">
                <img
                  src="/placeholder.svg?height=200&width=400&text=Map"
                  alt="Location Map"
                  className="object-cover rounded-md"
                />
              </AspectRatio>
              <div className="mt-4">
                <h4 className="mb-2 font-semibold">Business Hours</h4>
                <div className="grid grid-cols-2 gap-2 text-sm">
                  {deal?.businessHours?.length ? (
                    deal.businessHours.map((entry, index) => (
                      <div key={index}>
                        <div>{entry.weekday}</div>
                        <div>{entry.hours}</div>
                      </div>
                    ))
                  ) : (
                    <>
                      <div>Monday-Friday</div>
                      <div>08:00-18:00</div>
                      <div>Saturday-Sunday</div>
                      <div>08:00-18:00</div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default DealSidebar;
