import Placeholder from "../../assets/images/placeholder.jpg";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { ScrollArea, ScrollBar } from "../../components/ui/scroll-area";
import { Skeleton } from "../../components/ui/skeleton";
import { Category } from "../../models/category.model";
import { useGetCategoriesQuery } from "../../services/public.api";

const DealCategories = () => {
  const { data, isLoading } = useGetCategoriesQuery({
    type: "DEAL",
    hasParent: false,
  });

  if (!data) return null;

  return (
    <section className="md:py-4">
      <div className="container px-2 mx-auto md:px-4">
        <ScrollArea className="w-full whitespace-nowrap">
          <div className="flex p-2 space-x-2 w-max">
            {isLoading
              ? Array(8)
                  .fill(0)
                  .map((_, index) => <SkeletonCard key={index} />)
              : data?.data.map((category, index) => (
                  <CategoryCard key={index} category={category} />
                ))}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>
    </section>
  );
};

const CategoryCard = ({ category }: { category: Category }) => {
  const image = category.image
    ? process.env?.REACT_APP_IMAGE_BASE_URL + category.image.fileName
    : Placeholder;

  return (
    <Card className="flex flex-col items-center justify-center w-32 transition-all duration-300 ease-in-out cursor-pointer hover:scale-105">
      <CardContent className="flex flex-col items-center justify-center p-2">
        <div className="flex items-center justify-center w-20 h-20 mb-2 transition-transform duration-300 ease-in-out rounded-full bg-primary/10 hover:-translate-y-1">
          <img
            src={image}
            alt={category.name}
            className="object-contain w-20 h-20"
          />
        </div>
        <CardHeader className="p-0">
          <CardTitle className="flex items-center text-xs text-center text-wrap">
            {category.name}
          </CardTitle>
        </CardHeader>
      </CardContent>
    </Card>
  );
};

const SkeletonCard = () => (
  <Card className="flex flex-col items-center justify-center w-32 h-24">
    <CardContent className="flex flex-col items-center justify-center p-2">
      <Skeleton className="w-10 h-10 mb-2 rounded-full" />
      <Skeleton className="w-20 h-4" />
    </CardContent>
  </Card>
);

export default DealCategories;
