import { useState } from "react";
import { Button } from "src/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Label } from "src/components/ui/label";

// Define the schema for offer price validation
const offerSchema = z.object({
  offerPrice: z
    .number()
    .min(1, { message: "Offer price must be greater than 0." })
    .or(z.string().min(1, { message: "Offer price must be entered." })),
});

// Define the props for OfferForm
interface OfferFormProps {
  selectedTicket: { name: string } | null;
  onSubmitOffer: (offerPrice: number) => Promise<void>;
}

export function OfferForm({ selectedTicket, onSubmitOffer }: OfferFormProps) {
  const [loading, setLoading] = useState(false);

  const form = useForm<z.infer<typeof offerSchema>>({
    resolver: zodResolver(offerSchema),
    defaultValues: {
      offerPrice: "",
    },
  });

  // Function to handle form submission
  const handleSubmit = async (values: z.infer<typeof offerSchema>) => {
    setLoading(true); // Set loading state
    try {
      // Submit the offer
      await onSubmitOffer(Number(values.offerPrice));
    } catch (error) {
      console.error("Failed to submit offer:", error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="offerPrice"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  id="offer-price"
                  type="number"
                  placeholder="Enter your offer"
                  className="sm:w-96"
                  {...field}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-col gap-2 sm:flex-row justify-start items-start sm:items-center">
          <Button
            disabled={loading}
            type="submit"
            className={`${
              loading ? "cursor-not-allowed" : "hover:bg-blue-600"
            }  bg-blue-500 text-white font-bold py-2 px-4 rounded-md transition-colors duration-300 flex items-center`}
          >
            {loading ? "Submitting..." : "Submit Offer"}
          </Button>
        </div>
      </form>
    </Form>
  );
}
