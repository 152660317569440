import SuccessPage from "../../../components/Success";
import SignUpImage from "../../../assets/images/signup-success.png";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();
  const handleRerouteClick = () => {
    navigate("/signin");
  };
  return (
    <SuccessPage
      title="NICE!"
      subtitle="Your account has been created! You can now purchase tickets!"
      buttonTitle="Sign in"
      handleClick={handleRerouteClick}
      image={SignUpImage}
    />
  );
};

export default Success;
