import { Badge } from "../../../components/ui/badge";
import { Skeleton } from "../../../components/ui/skeleton";

interface DealHeaderProps {
  deal?: {
    name?: string;
  };
  isLoading: boolean;
}

export const DealHeader: React.FC<DealHeaderProps> = ({ deal, isLoading }) => (
  <>
    {isLoading ? (
      <Skeleton className="w-3/4 h-8 mb-4" />
    ) : (
      <h1 className="mb-4 text-2xl font-bold md:text-3xl">
        {deal?.name || "Deal Name"}
      </h1>
    )}
    <div className="flex items-center mb-4 space-x-2">
      {isLoading ? (
        <>
          <Skeleton className="w-20 h-6" />
          <Skeleton className="w-20 h-6" />
          <Skeleton className="w-32 h-6" />
        </>
      ) : (
        <>
          <Badge variant="secondary">Philippines</Badge>
          <Badge variant="secondary">Manila</Badge>
          <div className="flex items-center">
            <span className="text-yellow-400">★★★★★</span>
            <span className="ml-1 text-sm text-muted-foreground">
              (16 reviews)
            </span>
          </div>
        </>
      )}
    </div>
  </>
);

export default DealHeader;
