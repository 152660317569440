import React from "react";
import { UpcomingEvents } from "../../features";
import { useSearchParams } from "react-router-dom";
import SearchInput from "../../components/Search";

const SearchPage: React.FC = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="">
      <div className="px-2 mb-2 lg:px-0 lg:mb-4">
        <SearchInput placeholder={"Search Event, Performer, Genre"} />
      </div>
      <UpcomingEvents search={searchParams.get("search") || ""} />
    </div>
  );
};

export default SearchPage;
