import { ChevronRight, Home } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import { cn } from "../../lib/utils";

interface BreadcrumbNavProps {
  className?: string;
  items: Array<{ label: string; href?: string }>;
}

export function ResellBreadCrumbs({ className, items }: BreadcrumbNavProps) {
  return (
    <Breadcrumb className={cn("", className)}>
      <BreadcrumbList>
        {/* Home */}
        <BreadcrumbItem>
          <Link to="/" className="flex items-center">
            <Home className="w-4 h-4 mr-2" />
            Home
          </Link>
        </BreadcrumbItem>

        {items.map((item, index) => (
          <React.Fragment key={index}>
            <BreadcrumbSeparator>
              <ChevronRight className="w-4 h-4" />
            </BreadcrumbSeparator>
            <BreadcrumbItem>
              {item.href ? (
                <Link to={item.href}>{item.label}</Link>
              ) : (
                <BreadcrumbPage>{item.label}</BreadcrumbPage>
              )}
            </BreadcrumbItem>
          </React.Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
