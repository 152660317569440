import { useState } from "react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import CurrentOrders from "./CurrentOrders";
import PastOrders from "./PastOrders";

const ProfileOrders = () => {
  const [activeTab, setActiveTab] = useState("current");

  return (
    <main className="flex-1 p-4">
      <div className="">
        <h2 className="mb-2 text-2xl font-bold">Orders</h2>
        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="justify-start w-full mb-4">
            <TabsTrigger value="current">Current</TabsTrigger>
            <TabsTrigger value="past">Past</TabsTrigger>
          </TabsList>
          <TabsContent value="current">
            <CurrentOrders />
          </TabsContent>
          <TabsContent value="past">
            <PastOrders />
          </TabsContent>
        </Tabs>
      </div>
    </main>
  );
};

export default ProfileOrders;
