import { Check } from "lucide-react";
import React, { useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { cn } from "../../lib/utils";
import OrderContext from "./context/OrderContext";

interface Step {
  id: number;
  label: string;
  path: string;
}

const steps: Step[] = [
  { id: 1, label: "Select Ticket", path: "/event" },
  { id: 2, label: "Shop", path: "/shop" },
  { id: 3, label: "Checkout", path: "/checkout" },
];

const Stepper: React.FC<{
  currentStep: number;
  onStepClick: (step: Step) => void;
}> = ({ currentStep, onStepClick }) => {
  return (
    <nav aria-label="Order progress" className="w-full">
      <ol className="flex items-center w-full space-x-2 sm:space-x-4">
        {steps.map((step, index) => (
          <li
            key={step.id}
            className="flex flex-col items-center justify-center w-full"
          >
            <Button
              variant="outline"
              size="sm"
              className={cn(
                "h-10 w-10 px-0 rounded-full",
                currentStep >= step.id && "border-primary",
                currentStep > step.id && "bg-primary text-primary-foreground"
              )}
              onClick={() => onStepClick(step)}
            >
              <span className="sr-only">{`Step ${step.id}`}</span>
              {currentStep > step.id ? (
                <Check className="w-5 h-5" />
              ) : (
                <span className="w-5 h-5 text-sm font-medium">{step.id}</span>
              )}
            </Button>
            <span className="ml-2 text-xs sm:text-sm">{step.label}</span>
            {index < steps.length - 1 && (
              <div
                className={cn(
                  "flex-1 h-px ml-2 sm:ml-4",
                  currentStep > step.id ? "bg-primary" : "bg-border"
                )}
              />
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

const OrderStepNav: React.FC = () => {
  const { step, setStep } = useContext(OrderContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  const currentLocation = (path?: string) => {
    const isLocationIncludesShop = location.pathname.includes("shop");
    const splitLocation = location.pathname.split("/");

    if (isLocationIncludesShop) {
      splitLocation.splice(-1);
      return splitLocation.join("/");
    } else {
      splitLocation.push(path || "");
      return splitLocation.join("/");
    }
  };

  const handleStepClick = (clickedStep: Step) => {
    setStep(clickedStep.id);
    if (clickedStep.id === 1) {
      navigate(`/event/${id}`);
    } else if (clickedStep.id === 2) {
      navigate(currentLocation("shop"));
    } else {
      navigate(currentLocation());
    }
  };

  return (
    <div className="w-full max-w-3xl mx-auto my-8">
      <Stepper currentStep={step} onStepClick={handleStepClick} />
    </div>
  );
};

export default OrderStepNav;
