import { Link } from "react-router-dom";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import jobPositions from "./careers.json";

export default function CareersPage() {
  const fullTimePositions = jobPositions.filter(
    (job) => job.jobType === "full-time"
  );
  const internshipPositions = jobPositions.filter(
    (job) => job.jobType === "internship"
  );

  return (
    <div className="container px-4 py-8 mx-auto">
      <header className="mb-12 text-center">
        <h1 className="mb-4 text-4xl font-bold">
          Join Our Team at Ticketnation!
        </h1>
        <p className="mb-6 text-xl">
          Be part of a platform that brings unforgettable events and experiences
          to life.
        </p>
        <p className="max-w-2xl mx-auto text-lg">
          At Ticketnation, we're passionate about connecting people with amazing
          events. Our culture thrives on innovation, collaboration, and personal
          growth. Join us in shaping the future of event ticketing and
          technology!
        </p>
      </header>

      <section id="full-time" className="mb-12">
        <h2 className="mb-4 text-3xl font-semibold">Full-Time Opportunities</h2>
        <p className="mb-6 text-lg">
          Shape the future of ticketing and technology with us.
        </p>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {fullTimePositions.map((job) => (
            <Card key={job.id}>
              <CardHeader>
                <CardTitle>{job.title}</CardTitle>
                <CardDescription>Full-Time Position</CardDescription>
              </CardHeader>
              <CardContent>
                <Link to={`/careers/${job.id}`}>
                  <Button className="w-full">View Details</Button>
                </Link>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>

      <section id="internships" className="mb-12">
        <h2 className="mb-4 text-3xl font-semibold">
          Internship Opportunities
        </h2>
        <p className="mb-6 text-lg">
          Kickstart your career and learn with industry experts.
        </p>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {internshipPositions.map((job) => (
            <Card key={job.id}>
              <CardHeader>
                <CardTitle>{job.title}</CardTitle>
                <CardDescription>Internship Position</CardDescription>
              </CardHeader>
              <CardContent>
                <Link to={`/careers/${job.id}`}>
                  <Button className="w-full">View Details</Button>
                </Link>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>

      <section className="text-center">
        <h2 className="mb-6 text-3xl font-semibold">Ready to Join Us?</h2>
        <div className="space-y-4">
          <Button asChild size="lg">
            <Link to="#full-time">Explore Open Roles</Link>
          </Button>
          <div>
            <p className="mb-2">
              Don't see a role that fits? We'd still love to hear from you!
            </p>
            <Button variant="outline" asChild>
              <Link to="mailto:careers@ticketnation.ph">
                Submit Your Resume
              </Link>
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
}
