import { Button } from "../../../components/ui/button";
import { Skeleton } from "../../../components/ui/skeleton";

interface MobileBottomBarProps {
  isLoading: boolean;
  subtotal: number | null;
  canBook: boolean;
  handleSubmit: () => void;
}

export const MobileBottomBar: React.FC<MobileBottomBarProps> = ({
  isLoading,
  subtotal,
  canBook,
  handleSubmit,
}) => (
  <div className="fixed bottom-0 left-0 right-0 p-4 border-t bg-background md:hidden">
    <div className="flex items-center justify-between mb-2">
      <div>
        {isLoading ? (
          <Skeleton className="w-24 h-8" />
        ) : (
          <div className="flex items-center justify-between mt-4">
            <span className="font-semibold">Subtotal</span>
            <span className="text-lg font-bold">
              ₱{subtotal?.toFixed(2) || "0.00"}
            </span>
          </div>
        )}
        <div className="text-sm text-muted-foreground">per adult</div>
      </div>
      <Button
        onClick={handleSubmit}
        className="w-1/2"
        disabled={isLoading || !canBook}
      >
        {isLoading ? <Skeleton className="w-full h-6" /> : "Book Now"}
      </Button>
    </div>
  </div>
);

export default MobileBottomBar;
