import { LinkedInLogoIcon } from "@radix-ui/react-icons";
import { Facebook, Globe, Mail } from "lucide-react";
import { Link } from "react-router-dom";
import Carl from "../../assets/images/connect/carl-picture.png";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";

export default function ConnectPage() {
  return (
    <div className="text-white bg-blue-1100">
      <div className="max-w-6xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="mb-16 text-center">
          <h1 className="text-4xl font-bold sm:text-5xl md:text-6xl">
            Connect With Us
          </h1>
          <p className="mt-4 text-xl text-white/80">
            Partner with the Philippines' leading ticketing platform
          </p>
        </div>

        {/* Contact Information Card */}
        <Card className="text-white bg-white/10 border-white/20">
          <CardHeader>
            <CardTitle className="text-3xl">Contact Information</CardTitle>
            <CardDescription className="text-white/70">
              For inquiries and further discussions
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="space-y-4">
              <h2 className="text-2xl font-semibold">
                Ticketnation Philippines
              </h2>
              <div className="space-y-3">
                <Link
                  to="mailto:corporate@ticketnation.ph"
                  className="flex items-center gap-3 transition-colors text-white/90 hover:text-white"
                >
                  <Mail className="w-5 h-5" />
                  corporate@ticketnation.ph
                </Link>
                <Link
                  to="https://facebook.com/ticketnationphofficial"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-3 transition-colors text-white/90 hover:text-white"
                >
                  <Facebook className="w-5 h-5" />
                  ticketnationphofficial
                </Link>
                <Link
                  to="https://www.ticketnation.ph"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-3 transition-colors text-white/90 hover:text-white"
                >
                  <Globe className="w-5 h-5" />
                  www.ticketnation.ph
                </Link>
              </div>
            </div>

            {/* Founder Section */}
            <div className="pt-6 border-t border-white/20">
              <h3 className="mb-4 text-xl font-semibold">Meet Our Founder</h3>
              <div className="flex items-start gap-4">
                <div className="flex items-center justify-center w-20 h-20 overflow-hidden rounded-full bg-white/20">
                  <img src={Carl} alt="Carl-Picture" className="text-3xl " />
                </div>
                <div>
                  <h4 className="font-semibold">Carl Angelo Orale</h4>
                  <p className="text-sm text-white/70">CEO and Founder</p>
                  <Link
                    to="https://www.linkedin.com/in/carl-angelo-orale/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 mt-2 transition-colors text-white/90 hover:text-white"
                  >
                    <LinkedInLogoIcon className="w-4 h-4" />
                    View LinkedIn Profile
                  </Link>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
