import React from "react";
import { useParams } from "react-router-dom";
import { EventDetails } from "../../features/Event/SelectedEvent/EventDetails";
import EventPayment from "../../features/Event/SelectedEvent/EventPayment";

type UrlParams = {
  id: string;
  orderId: string;
};
const TicketSummaryPage = () => {
  const { id, orderId } = useParams<UrlParams>() as UrlParams;

  return (
    <div>
      <EventPayment orderId={orderId} eventId={id} />
    </div>
  );
};

export default TicketSummaryPage;
