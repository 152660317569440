import React from "react";

interface IContext {
  index: number;
  setIndex: (index: number) => void;
}
interface ITabs {
  children?: React.ReactNode;
}
export const TabContext = React.createContext<IContext>({
  index: 0,
  setIndex: () => {},
});
export const Tabs: React.FC<ITabs> = ({ children }) => {
  const [index, setIndex] = React.useState(0);

  return (
    <TabContext.Provider value={{ index, setIndex }}>
      {children}
    </TabContext.Provider>
  );
};

interface ITabList {
  grid?: number;
  id?: string;
  children?: React.ReactNode;
}

type ChildrenProps = {
  index: number;
}



export const TabList: React.FC<ITabList> = ({ children, grid = 5, id }) => {
  const childrenWithProps = React.Children.map(children, (child, i) => {
    if (React.isValidElement<ChildrenProps>(child)) {
      return React.cloneElement<ChildrenProps>(child, { index: i });
    }
    return child;
  });

  return (
    <div
      id={id}
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${grid}, minmax(0, 1fr))`,
      }}
    >
      {childrenWithProps}
    </div>
  );
};

interface ITab {
  index?: number;
  span?: number;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}
export const Tab: React.FC<ITab> = ({
  index: childIndex = 0,
  span = 1,
  children,
  style,
}) => {
  const { index, setIndex } = React.useContext(TabContext);
  return (
    <button
      className={`hover:scale-105 hover:shadow-md rounded-sm ${
        index === childIndex
          ? "bg-blue-1100 text-white"
          : "bg-white text-blue-1100"
      }`}
      style={{ gridColumn: `span ${span} / span ${span}`, ...style }}
      onClick={() => {
        setIndex(childIndex);
      }}
    >
      {children || "Link"}
    </button>
  );
};

interface ComponentProps {
  style?: React.CSSProperties;
  id?: string;
  children?: React.ReactNode;
}

export const TabPanels: React.FC<ComponentProps> = ({
  children,
  style,
  id,
}) => {
  const childrenWithProps = React.Children.map(children, (child, i) => {
    if (React.isValidElement<ChildrenProps>(child)) {
      return React.cloneElement<ChildrenProps>(child, { index: i });
    }
    return child;
  });

  return (
    <div id={id} style={style}>
      {childrenWithProps}
    </div>
  );
};
export const TabPanel: React.FC<ITab> = ({
  index: childIndex = 0,
  children,
  span,
  style,
}) => {
  const { index } = React.useContext(TabContext);
  return index === childIndex ? <div style={style}>{children}</div> : null;
};
