import { useState } from "react";
import QRCode from "react-qr-code";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { Order } from "../../../models/order.model";
import { Event } from "../../../models/orderEventTickets.model";
import { Ticket } from "../../../models/ticket.model";
interface TicketDialogProps {
  ticket: Ticket;
  event: Event;
  order: Order;
}
const TicketDialog: React.FC<TicketDialogProps> = ({
  ticket,
  event,
  order,
}) => {
  const [open, setOpen] = useState(false); // Manage open state

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen); // Update open state when dialog is toggled
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button onClick={() => handleOpenChange(true)} size="sm">
          View Ticket
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{event?.name}</DialogTitle>
          <DialogDescription>
            {/* Add a brief description of what the user should expect here */}
            Your ticket details and QR code are shown below. Present this code
            on the day of the event.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex justify-center">
            <QRCode value={ticket?.code || ""} size={200} />
          </div>
          <div className="text-center">
            <p className="font-bold">#{order.orderNumber}</p>
            <p>{new Date(event?.dateTime || "").toLocaleString()}</p>
            <p>{event?.venue.name}</p>
            <p>
              Section {ticket?.section}, Row {ticket?.row}, Seat {ticket?.seat}
            </p>
          </div>
          <div className="text-sm text-center text-gray-500">
            <p>PRESENT THIS CODE</p>
            <p>ON THE DAY OF THE EVENT</p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TicketDialog;
