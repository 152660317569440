// components/EmailForm.tsx
import { Button } from "src/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { Check } from "lucide-react";
import { useForm } from "react-hook-form";
import * as z from "zod";

// Define the schema for email validation
const emailSchema = z.object({
  email: z.string().email({
    message: "Please enter a valid email address.",
  }),
});

// Define the props for EmailForm
interface EmailFormProps {
  onSubmit: (email: string) => Promise<void>;
  loading: boolean;
  defaultEmail: string;
}

export function EmailForm({ onSubmit, loading, defaultEmail }: EmailFormProps) {
  const emailForm = useForm<z.infer<typeof emailSchema>>({
    resolver: zodResolver(emailSchema),
    defaultValues: {
      email: defaultEmail,
    },
  });

  const handleSubmit = async (values: z.infer<typeof emailSchema>) => {
    await onSubmit(values.email);
  };

  return (
    <Form {...emailForm}>
      <form
        onSubmit={emailForm.handleSubmit(handleSubmit)}
        className="space-y-4"
      >
        <FormField
          control={emailForm.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  disabled
                  type="text"
                  placeholder="Type your email here..."
                  {...field}
                  onChange={(e) => {
                    const lowerCaseValue = e.target.value.toLowerCase();
                    field.onChange(lowerCaseValue); // Update the field with the lowercase value
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-col gap-2 sm:flex-row justify-start items-start sm:items-center">
          <Button
            disabled={loading}
            type="submit"
            className={`${
              loading ? "cursor-not-allowed" : "hover:bg-blue-600"
            }  bg-blue-500 text-white font-bold py-2 px-4 rounded-md transition-colors duration-300 flex items-center`}
          >
            {loading ? (
              "Sending OTP..."
            ) : (
              <>
                Send OTP <Check className="ml-2 h-4 w-4" />
              </>
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
}
