// components/OtpForm.tsx
import { Button } from "src/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/ui/form";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "src/components/ui/input-otp";
import { zodResolver } from "@hookform/resolvers/zod";
import { Check } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

// Define the schema for OTP validation
const otpSchema = z.object({
  otp: z.string().length(6, {
    message: "OTP must be 6 digits.",
  }),
});

// Define the props for OtpForm
interface OtpFormProps {
  email: string;
  onSubmit: (otp: string) => Promise<void>;
  onResend: () => Promise<void>;
  timer: number;
  loading: boolean;
}

export function OtpForm({
  email,
  onSubmit,
  onResend,
  timer,
  loading,
}: OtpFormProps) {
  const otpForm = useForm<z.infer<typeof otpSchema>>({
    resolver: zodResolver(otpSchema),
    defaultValues: {
      otp: "",
    },
  });

  const handleSubmit = async (values: z.infer<typeof otpSchema>) => {
    await onSubmit(values.otp);
  };

  return (
    <Form {...otpForm}>
      <form onSubmit={otpForm.handleSubmit(handleSubmit)} className="space-y-4">
        {/* Display the email */}

        <FormField
          control={otpForm.control}
          name="otp"
          render={({ field }) => (
            <FormItem className="flex flex-col items-center justify-center">
              <FormLabel className="text-xl text-gray-600 font-semibold text-center">
                <span>OTP</span>
                <br />
                <span className="text-[8px] font-normal leading-tight max-w-[200px] text-center block mx-auto">
                  Double-check your inbox/spam and enter the OTP below to
                  confirm your email and unlock the next step!
                </span>
              </FormLabel>
              <FormControl className="flex justify-start w-full">
                <InputOTP maxLength={6} {...field} className="">
                  <InputOTPGroup>
                    <InputOTPSlot
                      className="sm:w-12 sm:h-12 sm:text-xl"
                      index={0}
                    />
                    <InputOTPSlot
                      className="sm:w-12 sm:h-12 sm:text-xl"
                      index={1}
                    />
                    <InputOTPSlot
                      className="sm:w-12 sm:h-12 sm:text-xl"
                      index={2}
                    />
                  </InputOTPGroup>
                  <InputOTPSeparator />
                  <InputOTPGroup>
                    <InputOTPSlot
                      className="sm:w-12 sm:h-12 sm:text-xl"
                      index={3}
                    />
                    <InputOTPSlot
                      className="sm:w-12 sm:h-12 sm:text-xl"
                      index={4}
                    />
                    <InputOTPSlot
                      className="sm:w-12 sm:h-12 sm:text-xl"
                      index={5}
                    />
                  </InputOTPGroup>
                </InputOTP>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex flex-col gap-2 items-center pt-4">
          <Button
            disabled={loading}
            type="submit"
            className={`${
              loading ? "cursor-not-allowed" : "hover:bg-blue-600"
            } w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-md transition-colors duration-300 flex items-center`}
          >
            {loading ? (
              "Verifying..."
            ) : (
              <React.Fragment>
                Verify OTP
                <Check className="ml-2 h-4 w-4" />
              </React.Fragment>
            )}
          </Button>

          <Button
            type="button"
            variant="link"
            disabled={timer > 0 || loading}
            onClick={onResend}
            className={`${loading ? "cursor-not-allowed" : ""} text-xs`}
          >
            {timer > 0
              ? `Need another OTP? Don’t worry—resend will be available in ${timer}s.⏳`
              : "Resend OTP"}
          </Button>
        </div>
      </form>
    </Form>
  );
}
