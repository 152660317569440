import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Error from "../../../components/Error";
import {
  CustomError,
  useResetPasswordMutation,
} from "../../../services/auth.api";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = React.useState("");
  const navigate = useNavigate();

  const [resetPassword, { data, isSuccess, isLoading, isError, error }] =
    useResetPasswordMutation();

  const customError = error as CustomError;

  React.useEffect(() => {
    if (isSuccess) {
      navigate(`/reset-password?email=${email}`);
    }
  }, [data, isSuccess]);

  const handlePasswordReset = () => {
    const userEmail = {
      email: email,
    };
    resetPassword(userEmail);
  };

  return (
    <div>
      <div className="my-8">
        <input
          type="text"
          className="py-2 px-4 lg:w-[345px] w-[300px] drop-shadow rounded-sm "
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="mt-8">
        <button
          onClick={() => handlePasswordReset()}
          className="w-[300px] p-2 font-bold text-white uppercase rounded-sm bg-blue-1100 lg:w-[345px]"
        >
          Continue
        </button>
      </div>
      <div className="mt-4">
        {isError && <p className="text-red-700">{customError.data.message}</p>}
      </div>
      <div>
        <Link to="/signin">
          <p className="text-lg underline">Back to Sign In</p>
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
