import React from 'react'
import {motion} from 'framer-motion'
import {iconMap, IconName} from "@/lib/IconMap";
import {useGetPublicUserLinkQuery} from "@/services/public.api";
import {useParams} from "react-router-dom";
import {Avatar, AvatarFallback, AvatarImage} from "@/components/ui/avatar";

// import { iconMap, IconName } from './IconMap'

interface SocialLink {
    platform: IconName
    url: string
    iconName: IconName
}

type SelectedLinkPageParams = {
    username: string;
};

const SelectedLinkPage: React.FC = () => {
    const {username} = useParams<SelectedLinkPageParams>() as SelectedLinkPageParams;

    const {data, isLoading} = useGetPublicUserLinkQuery(username)

    if (isLoading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>
    }

    if (!data) {
        return <div className="flex justify-center items-center h-screen">Error loading profile</div>
    }

    const profile = {
        name: data.fullName,
        title: data.company,
        position: data.position,
        avatarUrl: `${process.env.REACT_APP_IMAGE_BASE_URL}${data?.image?.fileName}`,
        links: data.links ? JSON.parse(data.links) as SocialLink[] : []
    }
    return (
        <div className="min-h-[600px] bg-gradient-to-b ">
            <div className="mx-auto max-w-md space-y-8 pt-8">
                <div className="text-center" id={"container-x"}>
                    <motion.div
                        initial={{scale: 0.5, opacity: 0}}
                        animate={{scale: 1, opacity: 1}}
                        transition={{duration: 0.5}}
                        className="mb-4 flex justify-center"
                    >
                        <Avatar className={`w-20 h-20`} id={'avatar-container'}>
                            <AvatarImage src={profile.avatarUrl} alt={profile.name}
                            />
                            <AvatarFallback
                                className="h-20 w-20 flex items-center justify-center">{profile?.name?.charAt(0)}</AvatarFallback>
                        </Avatar>
                    </motion.div>
                    <motion.div
                        initial={{y: 20, opacity: 0}}
                        animate={{y: 0, opacity: 1}}
                        transition={{duration: 0.5, delay: 0.2}}
                    >
                        <h1 className="text-2xl font-bold">{profile.name}</h1>
                        <p className="text-gray-600">{profile.title}</p>
                        <p className="text-gray-600">{profile.position}</p>
                    </motion.div>
                </div>
                <motion.div
                    initial={{y: 20, opacity: 0}}
                    animate={{y: 0, opacity: 1}}
                    transition={{duration: 0.5, delay: 0.4}}
                    className="grid gap-4"
                >
                    {profile.links.map((link, index) => {
                        const Icon = iconMap[link.platform] || iconMap.default;
                        let formattedUrl = link.url;

                        if (link.platform === "email") {
                            formattedUrl = `mailto:${link.url}`;
                        } else if (link.platform === "phone") {
                            formattedUrl = `tel:${link.url}`;
                        }

                        return (
                            <motion.div
                                key={link.platform}
                                initial={{x: -50, opacity: 0}}
                                animate={{x: 0, opacity: 1}}
                                transition={{duration: 0.3, delay: 0.1 * index}}
                            >
                                <a
                                    href={formattedUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="group relative flex h-14 w-full items-center overflow-hidden rounded-md border border-gray-300 bg-white px-4 transition-colors hover:border-blue-500 hover:bg-blue-50"
                                >
                    <span
                        className="absolute left-4 flex h-5 w-5 items-center justify-center text-gray-500 group-hover:text-blue-500">
                        <Icon size={20}/>
                    </span>
                                    <span
                                        className="ml-10 text-base font-medium text-gray-700 group-hover:text-blue-700">
                        {link.platform}
                    </span>
                                </a>
                            </motion.div>
                        );
                    })}
                </motion.div>
            </div>
        </div>
    )
}

export default SelectedLinkPage

