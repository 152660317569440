import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { RootState } from "../../../app/store";
import { Voucher } from "../../../models/voucher.model";

export interface SelectedEventPayload {
  id: string;
  name: string;
  price: number;
  quantity: number;
  multiplier: number;
  total: number;
  perTicketFee: number;
  eventName: string;
}

interface InitialState {
  payload: SelectedEventPayload[];
  selectedEventId: string;
  voucher?: Voucher;
  orderFee: number;
  currency?: string;
}

const initialState: InitialState = {
  payload: [],
  selectedEventId: "",
  voucher: undefined,
  orderFee: 0,
  currency: "PHP",
};

export const eventSlice = createSlice({
  name: "selectedEvent",
  initialState,
  reducers: {
    setSelectedPayload: (
      state: InitialState,
      action: PayloadAction<SelectedEventPayload[]>
    ) => {
      state.payload = action.payload;
    },
    setSelectedEvent: (state: InitialState, action: PayloadAction<string>) => {
      state.selectedEventId = action.payload;
    },
    setOrderFee: (state: InitialState, action: PayloadAction<number>) => {
      state.orderFee = action.payload;
    },
    setVoucher: (
      state: InitialState,
      action: PayloadAction<Voucher | undefined>
    ) => {
      state.voucher = action.payload;
    },
    setCurrency: (state: InitialState, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedPayload,
  setSelectedEvent,
  setOrderFee,
  setVoucher,
  setCurrency,
} = eventSlice.actions;
export const selectedEventSelect = (state: RootState) => state.selectedEvent;

export default eventSlice.reducer;
