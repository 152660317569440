import React from "react";
import { getImageURL } from "../../helpers/imagehelper";
import { Song } from "../../models/performer.model";
import { Modal } from "../Modal";
import YouTube from "react-youtube";

interface ComponentProps {
  songs: Song[];
}
export const Songs: React.FC<ComponentProps> = ({ songs }) => {
  const [link, setLink] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleListen = (link: string) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = link.match(regExp);

    setLink(match && match[7].length === 11 ? match[7] : "");
    setOpen(true);
  };
  return (
    <>
      <div className="p-2 mt-4 font-semibold text-white bg-blue-1100">
        SONGS
      </div>
      <div className="p-2 bg-white">
        {songs.map((s: Song, index) => {
          return (
            <div className="grid grid-cols-3 my-4" key={index}>
              <div className="flex items-center col-span-2 ">
                <div className="mr-4 text-xl ">{index + 1}.</div>
                <div>
                  <img
                    className="w-[80px] h-[80px] object-contain"
                    src={getImageURL(s.image?.fileName || "")}
                    alt="KagomeLogo"
                  />
                </div>
                <div className="flex-1 ml-4 text-xl ">{s.name}</div>
              </div>
              <div className="flex items-center justify-center">
                <button
                  className="w-32 p-2 font-semibold text-white rounded-sm lg:w-64 bg-blue-1100 border-blue-1100"
                  onClick={() => handleListen(s.url)}
                >
                  LISTEN
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {open && (
        <Modal open={open} setOpen={setOpen} size="xl">
          <div className="relative p-2 lg:h-[400px]">
            <YouTube
              iframeClassName="w-[-webkit-fill-available] h-[300px] lg:h-[380px]"
              className=""
              videoId={link}
              id="artist-youtube"
            />
          </div>
        </Modal>
      )}
    </>
  );
};
