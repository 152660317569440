import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import OrderContext from "./context/OrderContext";
import { Modal } from "../../components/Modal";
import { OrderExpiration } from "../../features/Event/SelectedEvent/OrderExpiration";

const OrderExpirationNav = () => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { order, isLoading, isSuccess } = useContext(OrderContext);

  function getTimeRemaining() {
    if (order) {
      const total =
        Date.parse(order?.expiredAt) - Date.parse(new Date().toString());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60) - 5;
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));

      return {
        total,
        days,
        hours,
        minutes,
        seconds,
      };
    }
    return {
      minutes: 0,
      seconds: 0,
    };
  }

  const remaining = getTimeRemaining();
  React.useEffect(() => {
    if (remaining.total && remaining?.total <= 0) {
      setOpen(true);
    }
  }, [remaining.total]);

  if (isLoading || !isSuccess || !order) return null;
  return (
    <div>
      <OrderExpiration
        sec={remaining.seconds}
        min={remaining.minutes}
        setOpen={setOpen}
      />
      <Modal
        open={open}
        setOpen={setOpen}
        title="Order is already expired."
        size="l"
        onClose={() => navigate("/")}
      >
        <div className="flex items-center justify-center h-32 p-4">
          <button
            className="px-4 py-2 font-semibold text-white rounded-sm drop-shadow bg-blue-1100"
            onClick={() => navigate("/")}
          >
            Return to home
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default OrderExpirationNav;
