// OrderContextProvider.tsx
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "../../../components/Modal";
import { Event } from "../../../models/event.model";
import { GetOrderResponse } from "../../../models/order.model";
import { useGetEventQuery } from "../../../services/event.api";
import { useGetOrderQuery } from "../../../services/order.api";
import OrderContext from "./OrderContext";

interface OrderContextProviderProps {
  orderId: string;
  eventId: string;
  children: React.ReactNode;
}

const OrderContextProvider: React.FC<OrderContextProviderProps> = ({
  orderId,
  eventId,
  children,
}) => {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const lastSlashIndex = path.lastIndexOf("/shop");
  const newOrderPath = path.substring(0, lastSlashIndex) + "";
  const isProduct = path.includes("products");
  const isShop = path.includes("shop");
  const {
    data: orderData,
    isSuccess: orderIsSuccess,
    isLoading: orderIsLoading,
    isError: orderIsError,
    error: orderError,
  } = useGetOrderQuery({ id: orderId });
  const {
    data: eventData,
    isSuccess: eventIsSuccess,
    isLoading: eventIsLoading,
    isError: eventIsError,
    error: eventError,
  } = useGetEventQuery(eventId);
  const [order, setOrder] = useState<GetOrderResponse | null>(null);
  const [event, setEvent] = useState<Event | null>(null);
  const [step, setStep] = useState(1);
  const [warningOpen, setWarningOpen] = useState(false);

  const isLoading = orderIsLoading || eventIsLoading;
  const isError = orderIsError || eventIsError;
  const error = orderError || eventError;
  const isSuccess = eventIsSuccess || orderIsSuccess;

  useEffect(() => {
    if (orderIsSuccess) {
      setOrder(orderData);
    }
  }, [orderIsSuccess, orderData]);

  useEffect(() => {
    if (eventIsSuccess) {
      setEvent(eventData);
    }
  }, [eventIsSuccess, eventData]);

  return (
    <OrderContext.Provider
      value={{
        order,
        event,
        isLoading,
        isError,
        error,
        isSuccess,
        step,
        setStep,
      }}
    >
      {children}
      <div className="flex justify-end mt-3 mb-2">
        {isProduct && (
          <button
            onClick={() => navigate(-1)}
            className="px-5 py-3 mr-2 text-xl font-semibold border rounded-md border-blue-1100 text-blue-1100"
          >
            Back
          </button>
        )}

        {isShop && (
          <button
            onClick={() => {
              if (orderData?.productOrder) {
                navigate(newOrderPath);
              } else {
                setWarningOpen(true);
              }
            }}
            className="px-5 py-3 text-xl font-semibold text-white border rounded-md bg-blue-1100"
          >
            Proceed
          </button>
        )}
      </div>
      <Modal setOpen={setWarningOpen} open={warningOpen}>
        <div className="p-5">
          <h1 className="text-xl font-semibold text-center">
            You don't have any add-ons on your event
          </h1>
          <p className="text-center">Are you sure you want to continue?</p>
          <div className="flex justify-center w-full gap-3 mt-5">
            <button
              onClick={() => {
                setWarningOpen(false);
              }}
              className="px-3 py-2 font-semibold border-2 shadow-lg rounded-xl text-blue-1100 border-blue-1100"
            >
              Back to Products
            </button>
            <button
              onClick={() => {
                setWarningOpen(false);
                navigate(newOrderPath);
              }}
              className="px-3 py-2 font-semibold text-white shadow-lg bg-blue-1100 rounded-xl"
            >
              Proceed Anyway
            </button>
          </div>
        </div>
      </Modal>
    </OrderContext.Provider>
  );
};

export default OrderContextProvider;
