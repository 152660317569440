import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../app/store";
import { CreateOfferDto, Offer } from "../models/offer.model";

interface Offers {
  data: Offer[];
  skip: number;
  take: number;
  count: number;
}

interface OffersQuery {
  skip?: number;
  take?: number;
  eventId?: string;
  sellerId?: string;
}
export interface CustomError {
  data: {
    message: [string];
  };
}

export const offerApi = createApi({
  reducerPath: "offerApi",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/offer", // Base URL for offer endpoints
    prepareHeaders: (headers, { getState, endpoint }) => {
      headers.set("X-Pinggy-No-Screen", "CustomUserAgent/1.0");
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Offers", "Offer"],
  endpoints: (builder) => ({
    getOffersBySeller: builder.query<Offers, OffersQuery>({
      query: ({ take, skip, sellerId }) => {
        return {
          url: `/seller/${sellerId}`,
          params: {
            skip,
            take,
          },
        };
      },
      providesTags: ["Offers"],
    }),
    getOffersByEvent: builder.query<Offers, OffersQuery>({
      query: ({ take, skip, eventId }) => {
        return {
          url: `/event/${eventId}`,
          params: {
            skip,
            take,
          },
        };
      },
      providesTags: ["Offers"],
    }),
    getOffer: builder.query<Offer, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Offer"],
    }),
    createOffer: builder.mutation<CreateOfferDto, Partial<Offer>>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Offers"],
    }),
    cancelOffer: builder.mutation<void, { id: string; sellerId: string }>({
      query: ({ id, sellerId }) => ({
        url: `/${id}/cancel`,
        method: "PATCH",
        body: { sellerId },
      }),
      invalidatesTags: ["Offers"],
    }),
    acceptOffer: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/accept`,
        method: "PATCH",
      }),
      invalidatesTags: ["Offers", "Offer"],
    }),
    rejectOffer: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/reject`,
        method: "PATCH",
      }),
      invalidatesTags: ["Offers", "Offer"],
    }),
    updateOffer: builder.mutation<Offer, { id: string; data: Partial<Offer> }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Offers", "Offer"],
    }),
    removeOffer: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Offers"],
    }),
  }),
});

export const {
  useGetOffersBySellerQuery,
  useGetOffersByEventQuery,
  useGetOfferQuery,
  useCreateOfferMutation,
  useCancelOfferMutation,
  useAcceptOfferMutation,
  useRejectOfferMutation,
  useUpdateOfferMutation,
  useRemoveOfferMutation,
} = offerApi;
