import React, { useState } from 'react';
import { useUploadProfilePhotoMutation } from '@/services/user.api';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { Label } from '@/components/ui/label';
import { User } from "@/models/user.model";
import { Loader2 } from 'lucide-react';
import {useToast} from "@/hooks/use-toast";

interface ProfilePhotoProps {
    user: User
}

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({ user }) => {
    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | null>(null);
    const [uploadProfilePhoto, { isLoading }] = useUploadProfilePhotoMutation();
    const { toast } = useToast();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleUpload = async () => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                await uploadProfilePhoto(formData).unwrap();
                toast({
                    title: "Success",
                    description: "Profile photo uploaded successfully!",
                    variant: "default",
                });
                setPreview(null);
                setFile(null);
            } catch (error) {
                toast({
                    title: "Error",
                    description: "Failed to upload photo. Please try again.",
                    variant: "destructive",
                });
            }
        }
    };

    return (
        <div className="space-y-6">
            <div className="flex items-center space-x-4">
                <Avatar className="w-24 h-24">
                    <AvatarImage src={preview || `${process.env.REACT_APP_IMAGE_BASE_URL}${user.image?.fileName}`} alt={user.firstName} />
                    <AvatarFallback>{user.firstName.charAt(0)}</AvatarFallback>
                </Avatar>
                <div className="space-y-2">
                    <h3 className="text-lg font-semibold">Profile Photo</h3>
                    <p className="text-sm text-gray-500">
                        Upload a new profile photo or change your existing one.
                    </p>
                </div>
            </div>
            <div className="space-y-4">
                <div>
                    <Label htmlFor="profile-photo" className="sr-only">Choose profile photo</Label>
                    <Input
                        id="profile-photo"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="cursor-pointer"
                    />
                </div>
                <Button
                    onClick={handleUpload}
                    disabled={isLoading || !file}
                    className="w-full"
                >
                    {isLoading ? (
                        <>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            Uploading...
                        </>
                    ) : 'Upload Photo'}
                </Button>
            </div>
        </div>
    );
};

export default ProfilePhoto;
