import React, { useState } from "react";
import Banner from "../../../assets/images/Banner.png";
import {
  Event,
  EventGroupPerformer,
  EventPerformer,
} from "../../../models/event.model";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Modal } from "../../../components/Modal";
import parse from "html-react-parser";
import EventVenueMap from "./EventVenueMap";
import Timezone from "dayjs/plugin/timezone";
import UTC from "dayjs/plugin/utc";

dayjs.extend(UTC);
dayjs.extend(Timezone);

interface ComponentProps {
  event: Event;
  isGroup?: boolean;
  dateDropDown?: JSX.Element;
}

interface EventPerformerProps {
  performers: EventPerformer[];
}
const EventPerformers: React.FC<EventPerformerProps> = ({ performers }) => {
  return (
    <>
      {performers.map(({ performer }: EventPerformer) => (
        <Link
          to={`/artist/${performer.id}/performer`}
          className="mx-1 text-blue-400 underline"
        >
          {performer.name}
        </Link>
      ))}
    </>
  );
};

interface EventGroupProps {
  groups: EventGroupPerformer[];
}

const EventGroups: React.FC<EventGroupProps> = ({ groups }) => {
  return (
    <>
      {groups.map(({ group }: EventGroupPerformer) => (
        <Link
          to={`/artist/${group.id}/group`}
          className="mx-1 text-blue-400 underline"
        >
          {group.name}
        </Link>
      ))}
    </>
  );
};

export const EventDetails: React.FC<ComponentProps> = ({
  event,
  dateDropDown,
}) => {
  const [open, setOpen] = useState(false);
  const dateEvent = dayjs(event.dateTime)
    .tz(event.timezone)
    .format("MMMM DD, YYYY - hh:mm A");
  const eventTitle = event.name;
  const eventImage = event.image
    ? process.env.REACT_APP_IMAGE_BASE_URL + event.image.fileName
    : Banner;

  return (
    <div>
      <div
        className={`h-[208px] lg:h-[468px] mb-2`}
        style={{
          background: `url(${eventImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
      <div className="px-2 lg:p-0">
        <div className="flex items-center justify-between">
          <div className="text-xl font-semibold lg:text-4xl">{eventTitle}</div>
          <button
            onClick={() => setOpen(true)}
            className="p-1 text-sm border rounded-sm md:text-xl drop-shadow text-blue-1100 border-blue-1100 w-28 lg:w-36 hover:bg-blue-1100 hover:text-white"
          >
            More Info
          </button>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="flex items-center col-span-1 md:col-span-2 ">
            <div className="text-sm font-semibold lg:text-lg">DATE:</div>
            <div className="ml-2 text-sm font-light lg:tex-xl ">
              {dateDropDown || dateEvent}
            </div>
          </div>
          <div className="flex items-center">
            <div className="text-sm font-semibold lg:text-lg">VENUE:</div>
            <div className="ml-2 text-sm font-light lg:tex-xl ">
              {event.venue.name}
            </div>
          </div>
          <div className="flex items-center">
            <div className="text-sm font-semibold lg:text-lg">ADDRESS:</div>
            <div className="ml-2 text-sm font-light lg:tex-xl ">{`${event.venue.address1}, ${event.venue.city}`}</div>
          </div>
          {event.VenueMap.length > 0 && (
            <EventVenueMap image={event.VenueMap[0].image} />
          )}
        </div>
      </div>
      <Modal
        title="Event Information"
        open={open}
        isEventInformation
        setOpen={setOpen}
        size="xl"
      >
        <div className="flex flex-col p-5">
          <div className="flex justify-between w-full">
            <div className="flex items-start mt-2">
              <div className="text-sm font-semibold lg:text-lg">Organizer:</div>
              <div className="flex flex-wrap w-full ml-2 font-light lg:pl-[75px] text-sm lg:text-base items-center">
                {event.organization.image && (
                  <img
                    className="object-contain object-center w-10 h-10 mr-2 border rounded-full shadow-md"
                    src={
                      process.env.REACT_APP_IMAGE_BASE_URL +
                      event.organization.image.fileName
                    }
                    alt="organizer_image"
                  />
                )}
                {event.organization.url ? (
                  <a
                    className="text-blue-400 underline"
                    href={event.organization.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {event.organization.name}
                  </a>
                ) : (
                  event.organization.name
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className="flex items-start mt-2">
              <div className="text-sm font-semibold lg:text-lg">LINEUP:</div>
              <div className="flex flex-wrap w-full ml-2 font-light pl-6 lg:pl-[75px] text-sm lg:text-base">
                <EventPerformers performers={event.performers} />
                <EventGroups groups={event.groups} />
              </div>
            </div>
          </div>
          <div className="grid grid-flow-col grid-cols-8 mb-4">
            <h3 className="col-span-2 mr-1 text-sm font-semibold lg:text-base">
              Description:
            </h3>
            <p className="col-span-6 pl-2 text-sm text-justify break-normal lg:text-base">
              {event.description}
            </p>
          </div>
          {event.venuePointers && (
            <div className="grid grid-flow-col grid-cols-8 mb-4">
              <h3 className="col-span-2 mr-1 text-sm font-semibold lg:text-base">
                Venue Pointers:
              </h3>
              <p className="col-span-6 text-sm text-justify break-normal lg:text-base">
                {parse(event.venuePointers.toString())}
              </p>
            </div>
          )}
          {event.promo && (
            <div className="grid grid-flow-col grid-cols-8 mb-4">
              <h3 className="col-span-2 mr-1 text-sm font-semibold lg:text-base">
                Promo(s):
              </h3>
              <p className="col-span-6 text-sm text-justify break-normal lg:text-base">
                {parse(event.promo.toString())}
              </p>
            </div>
          )}
          <button
            className="w-full px-2 py-1 text-red-500 border border-red-500 rounded-md hover:bg-red-500 hover:text-white"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};
