import React from "react";
import { Link } from "react-router-dom";
import EllipsisText from "react-ellipsis-text";
interface ArtistProps {
  name: string;
  description?: string;
  image: string;
  id: string;
  artistType?: string
}

export const ArtistCard: React.FC<ArtistProps> = ({
  name,
  description,
  image,
  id,
  artistType = "performer"
}) => {
  return (
    <div
      className="bg-white bg-center bg-no-repeat bg-cover rounded-sm drop-shadow w-full h-[500px] md:h-[400px] flex items-end p-2"
      key={id}
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div className="flex items-center justify-between w-full p-1">
        <div
          className="flex-1 pr-1 tracking-wider text-white capitalize drop-shadow lg:pr-2"
          style={{ textShadow: "1px 1px black" }}
        >
          <div className="text-base font-semibold lg:text-xl">{name}</div>
          <div className="font-light">
            <EllipsisText text={description} length={15}  />
          </div>
        </div>
        <Link to={`/artist/${id}/${artistType}`}>
          <div className="">
            <button className="w-20 p-1 font-semibold text-white uppercase border rounded-sm lg:w-24 drop-shadow bg-blue-1100 hover:border-blue-1100 hover:bg-white hover:text-blue-1100">
              info
            </button>
          </div>
        </Link>
      </div>
    </div>
  );
};
