import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import react-toastify CSS
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { User } from "@/models/user.model";
import { useUpdateUserMutation } from "@/services/user.api";
import ProfilePhoto from "@/pages/Profile/ProfileSettings/ProfilePhoto";
import ShareLinkButton from "@/pages/Profile/ProfileSettings/ProfileTab/ShareLinkButton";

// Define validation schema using Yup
const validationSchema = Yup.object({
  username: Yup.string().required("Username is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  mobileNumber: Yup.string()
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/g,
      "Mobile number is not valid"
    )
    .optional(),
});

interface PersonalInfoTabProps {
  user: User;
}

const PersonalInfoTab: React.FC<PersonalInfoTabProps> = ({ user }) => {
  const [updateUser, { isLoading }] = useUpdateUserMutation();

  // Initialize Formik with initial values and validation schema
  const formik = useFormik({
    initialValues: {
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      mobileNumber: user.mobileNumber,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // API call to update user
        const response = await updateUser({
          email: user.email,
          ...values,
        }).unwrap();

        // Show success toast notification
        toast.success("Profile updated successfully!");

        // Optionally, you can log the response
        console.log("Form submitted:", response);
      } catch (error) {
        // Handle error case with toast notification
        toast.error("Failed to update profile. Please try again.");
        console.error("Update failed:", error);
      }
    },
  });

  return (
    <Card>
      <CardHeader className={"p-4"}>
        <div className={"flex justify-between"}>
          <div>
            <CardTitle>Personal Information</CardTitle>
            <p>Update your personal details here.</p>
          </div>
          <ShareLinkButton username={user.username}/>
        </div>
        <ProfilePhoto user={user}/>
      </CardHeader>
      <form onSubmit={formik.handleSubmit}>
        <CardContent className="space-y-4">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div className="space-y-2">
              <Label htmlFor="email">Email (Not changeable)</Label>
              <Input id="email" value={user.email} disabled />
            </div>
            <div className="space-y-2">
              <Label htmlFor="username">Username</Label>
              <Input
                id="username"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.touched.username && formik.errors.username
                    ? "border-red-500"
                    : ""
                }
              />
              {formik.touched.username && formik.errors.username ? (
                <p className="text-red-500">{formik.errors.username}</p>
              ) : null}
            </div>
            <div className="space-y-2">
              <Label htmlFor="firstName">First Name</Label>
              <Input
                id="firstName"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.touched.firstName && formik.errors.firstName
                    ? "border-red-500"
                    : ""
                }
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <p className="text-red-500">{formik.errors.firstName}</p>
              ) : null}
            </div>
            <div className="space-y-2">
              <Label htmlFor="lastName">Last Name</Label>
              <Input
                id="lastName"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.touched.lastName && formik.errors.lastName
                    ? "border-red-500"
                    : ""
                }
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <p className="text-red-500">{formik.errors.lastName}</p>
              ) : null}
            </div>
            <div className="space-y-2">
              <Label htmlFor="mobileNumber">Mobile Number</Label>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                value={formik.values.mobileNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.touched.mobileNumber && formik.errors.mobileNumber
                    ? "border-red-500"
                    : ""
                }
              />
              {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                <p className="text-red-500">{formik.errors.mobileNumber}</p>
              ) : null}
            </div>
          </div>
        </CardContent>
        <CardFooter>
          <Button
            type="submit"
            className="w-full md:w-auto"
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save Changes"}
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
};

export default PersonalInfoTab;
