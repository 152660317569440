import React from "react";
import ShopSummary from "../ShopSummary";
import EventProduct from "./EventProduct";
import TicketnationProduct from "./TicketnationProduct";

const Product = () => {
  return (
    <>
      <ShopSummary />
      <div className="grid grid-cols-2 gap-2 p-2">
        <div className="col-span-2 py-2">
          <input
            type="text"
            placeholder="Search"
            className="w-full px-3 py-2 bg-gray-200 rounded-md"
          />
        </div>
        <div className="grid grid-cols-2 col-span-2 gap-1 md:grid-cols-1">
          <EventProduct />
          <TicketnationProduct />
        </div>
      </div>
    </>
  );
};

export default Product;
