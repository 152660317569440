import {
  eachDayOfInterval,
  endOfMonth,
  format,
  isBefore,
  isSameMonth,
  isToday,
  parseISO,
  startOfMonth,
} from "date-fns";
import { CalendarIcon, ChevronLeft, ChevronRight } from "lucide-react";
import { useState } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { Button } from "../../../components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { cn } from "../../../lib/utils";
import {
  AvailabilityType,
  DealVariantAvailability,
} from "../../../models/deal.model";

interface PackageOptionCalendarProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  availabilities: DealVariantAvailability[];
  onAvailabilityChange?: (
    availability: DealVariantAvailability | undefined
  ) => void;
  multiSelect?: boolean;
}

export const PackageOptionCalendar = <T extends FieldValues>({
  control,
  name,
  availabilities,
  onAvailabilityChange,
  multiSelect = false,
}: PackageOptionCalendarProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  // Normalize dates in availabilities to "yyyy-MM-dd" format, handling both "DATE" and "RANGE" types
  const availabilityLookup = availabilities.reduce((acc, availability) => {
    if (availability.availabilityType === AvailabilityType.RANGE) {
      const { startDate, endDate } = availability;
      if (startDate && endDate) {
        const rangeDays = eachDayOfInterval({
          start: parseISO(startDate),
          end: parseISO(endDate),
        });
        rangeDays.forEach((day) => {
          const dateStr = format(day, "yyyy-MM-dd");
          acc[dateStr] = availability;
        });
      }
    } else if (availability.date) {
      const dateStr = format(parseISO(availability.date), "yyyy-MM-dd");
      acc[dateStr] = availability;
    }
    return acc;
  }, {} as Record<string, DealVariantAvailability>);

  const handleDateChange = (date: string | null, field: any) => {
    if (!date) return;

    const selectedDates = field.value ? [...field.value] : [];

    if (multiSelect) {
      // Toggle selection for multi-select mode
      if (selectedDates.includes(date)) {
        field.onChange(selectedDates.filter((d) => d !== date));
      } else {
        field.onChange([...selectedDates, date]);
      }
    } else {
      // Single date selection mode
      field.onChange([date]);
    }

    const availability = date ? availabilityLookup[date] : null;
    onAvailabilityChange?.(availability || undefined);
  };

  const renderCalendar = (
    selectedDates: string[],
    onChange: (date: string | null) => void,
    field: any
  ) => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(currentMonth);
    const monthDays = eachDayOfInterval({ start: monthStart, end: monthEnd });

    const weeks: JSX.Element[] = [];
    let week: JSX.Element[] = [];

    // Fill empty days for the first week
    for (let i = 0; i < monthDays[0].getDay(); i++) {
      week.push(<td key={`empty-${i}`} className="p-2"></td>);
    }

    monthDays.forEach((day, index) => {
      const dateStr = format(day, "yyyy-MM-dd");
      const availability = availabilityLookup[dateStr];
      const isSelected = selectedDates.includes(dateStr);
      const isDisabled =
        isBefore(day, new Date()) ||
        !availability?.isPublished ||
        availability.remaining <= 0;

      week.push(
        <td key={dateStr} className="p-1">
          <button
            onClick={() => {
              onChange(dateStr);
              handleDateChange(dateStr, field);
            }}
            disabled={isDisabled}
            className={cn(
              "w-full h-16 p-1 rounded-md transition-colors",
              isDisabled
                ? "cursor-not-allowed opacity-50"
                : "hover:bg-gray-100",
              isSelected &&
                "bg-primary text-primary-foreground hover:bg-primary/90",
              isToday(day) && "border border-primary",
              !isSameMonth(day, currentMonth) && "opacity-50"
            )}
          >
            <div className="flex flex-col items-center justify-center h-full">
              <span
                className={cn(
                  "text-sm font-medium",
                  isSelected && "text-primary-foreground"
                )}
              >
                {format(day, "d")}
              </span>
              {availability && (
                <span
                  className={cn(
                    "text-xs",
                    availability.isPublished
                      ? "text-green-600"
                      : "text-red-500",
                    isSelected && "text-primary-foreground"
                  )}
                >
                  {availability.isPublished
                    ? `₱${availability.price.toFixed(2)}`
                    : "Sold out"}
                </span>
              )}
            </div>
          </button>
        </td>
      );

      if (week.length === 7) {
        weeks.push(<tr key={`week-${weeks.length}`}>{week}</tr>);
        week = [];
      } else if (index === monthDays.length - 1) {
        for (let i = week.length; i < 7; i++) {
          week.push(<td key={`empty-end-${i}`} className="p-2"></td>);
        }
        weeks.push(<tr key={`week-${weeks.length}`}>{week}</tr>);
      }
    });

    return (
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <Button
            type="button"
            variant="outline"
            size="icon"
            onClick={() =>
              setCurrentMonth(
                (prev) => new Date(prev.getFullYear(), prev.getMonth() - 1, 1)
              )
            }
          >
            <ChevronLeft className="w-4 h-4" />
          </Button>
          <h2 className="text-lg font-semibold">
            {format(currentMonth, "MMMM yyyy")}
          </h2>
          <Button
            type="button"
            variant="outline"
            size="icon"
            onClick={() =>
              setCurrentMonth(
                (prev) => new Date(prev.getFullYear(), prev.getMonth() + 1, 1)
              )
            }
          >
            <ChevronRight className="w-4 h-4" />
          </Button>
        </div>
        <table className="w-full border-collapse">
          <thead>
            <tr>
              {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                <th key={day} className="p-2 text-sm font-medium text-gray-600">
                  {day}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{weeks}</tbody>
        </table>
      </div>
    );
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Select Date{multiSelect ? "s" : ""}
          </label>
          <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger asChild>
              <Button
                type="button"
                variant="outline"
                className={cn(
                  "w-full justify-start text-left font-normal",
                  (!field.value || field.value.length === 0) &&
                    "text-muted-foreground"
                )}
              >
                <CalendarIcon className="w-4 h-4 mr-2" />
                {multiSelect && field.value && field.value.length > 0 ? (
                  <span>
                    {field.value
                      .map((date: string) => format(parseISO(date), "PPP"))
                      .join(", ")}
                  </span>
                ) : field.value && field.value.length > 0 ? (
                  format(parseISO(field.value[0]), "PPP")
                ) : (
                  <span>Select a date</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              {renderCalendar(
                Array.isArray(field.value) ? field.value : [],
                (date) => field.onChange(date ? [date] : []),
                field
              )}
            </PopoverContent>
          </Popover>
          {field.value && field.value.length > 0 && (
            <p className="mt-2 text-sm text-gray-500">
              Selected date{multiSelect ? "s" : ""}:{" "}
              {field.value
                .map((date: string) => format(parseISO(date), "PPP"))
                .join(", ")}
            </p>
          )}
        </div>
      )}
    />
  );
};

export default PackageOptionCalendar;
