// OrderContextProvider.tsx
import { useGetEmailOtpQuery } from "src/services/email-otp.api";
import { Loader2 } from "lucide-react";
import React, { useCallback } from "react";
import { OtpContextProviderProps } from "./OrderContextTypes";
import OtpContext from "./OtpContext";

const OtpContextProvider: React.FC<OtpContextProviderProps> = ({
  otpId,
  children,
}) => {
  const { data, error, isFetching, isLoading, refetch } = useGetEmailOtpQuery(
    otpId,
    {
      skip: !otpId,
      refetchOnFocus: false,
    }
  );

  const loading = isLoading || isFetching;

  const fetchOtpData = useCallback(() => {
    refetch();
  }, [refetch]);

  if (loading)
    return (
      <div className="fixed inset-0 bg-blue-50 flex flex-col items-center justify-center opacity-80 text-blue-600">
        {/* Loader Icon */}
        <div className="relative w-24 h-24 mb-8">
          <Loader2 className="w-24 h-24 animate-spin text-blue-600" />
        </div>
        {/* Loading Bar */}
        <div className="w-64 h-2 bg-blue-200 rounded-full mb-8 overflow-hidden">
          <div className="h-full bg-blue-500 animate-[loader_2s_ease-in-out_infinite]" />
        </div>
        {/* Text */}
        <h2 className="text-2xl font-bold mb-2 text-blue-700">
          Crafting Your Experience
        </h2>
        <p className="text-blue-500 text-center max-w-md">
          {`Hold tight! We're assembling pixels and polishing interfaces. 
    It's like watching a digital symphony come together.`}
        </p>
      </div>
    );
  // Provide the state and function to children components
  return (
    <OtpContext.Provider value={{ data, loading, error, fetchOtpData }}>
      {children}
    </OtpContext.Provider>
  );
};

export default OtpContextProvider;
