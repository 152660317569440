import React from "react";
import { EventTicket } from "../../models/event.model";

interface ComponentProps {
  initialValue?: number;
  eventTicket?: EventTicket;
  minValue?: number;
  maxValue?: number;
  onChange?: (value: number) => void;
}
export const NumberInput: React.FC<ComponentProps> = ({
  initialValue = 0,
  eventTicket,
  minValue = 0,
  maxValue = 8,
  onChange,
}) => {
  const [value, setValue] = React.useState<number>(initialValue);

  // React.useEffect(() => {
  //   if (onChange) onChange(value);
  // }, [value, onChange]);

  const handleAddValue = () => {
    if (value < maxValue) {
      const newValue = value + 1;
      setValue(newValue);
      handleChange(newValue);
    }
  };

  const handleSubtractValue = () => {
    if (value > minValue) {
      const newValue = value - 1;
      setValue(newValue);
      handleChange(newValue);
    }
  };

  const handleChange = (currentValue: number) => {
    if (onChange) onChange(currentValue);
  };

  return (
    <div>
      <div className="flex ">
        <button
          disabled={eventTicket && eventTicket.remaining === 0 ? true : false}
          className={`px-2 py-1 border-2 
          ${
            eventTicket && eventTicket.remaining === 0
              ? "text-gray-400 border-gray-400 bg-gray-200"
              : "text-blue-1100 border-blue-1100  "
          }`}
          onClick={handleSubtractValue}
        >
          -
        </button>
        <div
          className={`flex items-center w-10 border-t-2 border-b-2  ${
            eventTicket && eventTicket.remaining === 0
              ? "text-gray-400 border-gray-400 bg-gray-200"
              : "border-blue-1100"
          }`}
        >
          <div className="flex justify-center w-full">{value}</div>
        </div>
        <button
          disabled={eventTicket && eventTicket.remaining === 0 ? true : false}
          className={`px-2 py-1 border-2 
            ${
              eventTicket && eventTicket.remaining === 0
                ? "text-gray-400 border-gray-400 bg-gray-200"
                : "text-blue-1100 border-blue-1100  "
            }`}
          onClick={handleAddValue}
        >
          +
        </button>
      </div>
    </div>
  );
};
