import {
  ArrowRight,
  ClipboardList,
  DollarSign,
  ShieldCheck,
  Zap,
} from "lucide-react";
import { Helmet } from "react-helmet";
import bannerImage from "../../../assets/images/resell/banner-2.png";
import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { ResellBreadCrumbs } from "../ResellBreadcrumbs";

export default function SellerHub() {
  return (
    <>
      <Helmet>
        <title>Sell Your Tickets | TicketNation</title>
        <meta
          name="description"
          content="Sell your tickets quickly and easily on TicketNation. Our platform attracts thousands of buyers daily, maximizing your chances of a quick sale."
        />
        <meta
          name="keywords"
          content="sell tickets, ticket resell, ticket marketplace, TicketNation"
        />
      </Helmet>
      <div className="min-h-screen bg-gray-50">
        <ResellBreadCrumbs
          items={[{ label: "Resell", href: "/resell" }, { label: "Sellers" }]}
        />

        <header
          className="py-20 text-white bg-blue-1100"
          style={{
            backgroundImage: `url(${bannerImage})`,
            backgroundSize: "cover",
          }}
        >
          <div className="container px-4 mx-auto">
            <h1 className="mb-4 text-4xl font-bold">
              Sell Your Tickets Easily and Securely
            </h1>
            <p className="mb-8 text-xl">
              Have extra tickets? Let us help you reach thousands of potential
              buyers in just a few clicks. List your tickets today and connect
              with event-goers looking to buy!
            </p>
            <div className="flex flex-col gap-4 sm:flex-row">
              <Button
                size="lg"
                className="bg-white text-blue-1100 hover:bg-blue-50"
                onClick={() =>
                  (window.location.href = "https://resell.ticketnation.ph")
                }
              >
                Start Selling
                <ArrowRight className="w-4 h-4 ml-2" />
              </Button>
            </div>
          </div>
        </header>

        <main className="container px-4 py-16 mx-auto">
          <section className="mb-20">
            <h2 className="mb-8 text-3xl font-bold text-center">
              How Selling on TicketNation Works
            </h2>
            <div className="grid gap-8 md:grid-cols-2">
              {[
                {
                  title: "Create Your Listing",
                  icon: <ClipboardList className="w-8 h-8 mb-4" />,
                  description:
                    "Provide details about your event and ticket, set your price, and publish your listing to our resell marketplace.",
                },
                {
                  title: "Manage Your Listings",
                  icon: <Zap className="w-8 h-8 mb-4" />,
                  description:
                    "Track buyer offers, make adjustments, and control your listing at any time from your seller dashboard.",
                },
                {
                  title: "Get Offers",
                  icon: <DollarSign className="w-8 h-8 mb-4" />,
                  description:
                    "Buyers can make offers on your tickets, or they may buy directly at your listed price.",
                },
                {
                  title: "Complete the Sale",
                  icon: <ShieldCheck className="w-8 h-8 mb-4" />,
                  description:
                    "Once a buyer purchases your ticket, the transaction is securely handled, and you'll receive your payment.",
                },
              ].map((item, index) => (
                <Card key={index}>
                  <CardHeader>
                    <CardTitle className="flex flex-col items-center">
                      {item.icon}
                      {item.title}
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-center text-gray-600">
                      {item.description}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </section>

          <section className="mb-20">
            <h2 className="mb-8 text-3xl font-bold text-center">
              Why Sell with TicketNation
            </h2>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
              {[
                {
                  title: "No Hidden Fees",
                  description:
                    "Transparent commissions, so you know exactly what you'll earn.",
                },
                {
                  title: "Verified Buyers",
                  description:
                    "All buyers are vetted to ensure a safe and secure transaction.",
                },
                {
                  title: "Reach More Buyers",
                  description:
                    "Our platform attracts thousands of ticket buyers daily, maximizing your chances of a quick sale.",
                },
                {
                  title: "Easy Management",
                  description:
                    "Monitor all of your listings, view buyer inquiries, and handle transactions from a single dashboard.",
                },
              ].map((item, index) => (
                <Card key={index}>
                  <CardHeader>
                    <CardTitle>{item.title}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-gray-600">{item.description}</p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </section>

          <section className="text-center">
            <h2 className="mb-4 text-3xl font-bold">Ready to get started?</h2>
            <p className="mb-8 text-xl">
              It's fast, secure, and easy to sell your tickets on TicketNation.
            </p>
            <Button
              size="lg"
              className="text-white bg-blue-1100 hover:bg-blue-1100"
              onClick={() =>
                (window.location.href = "https://resell.ticketnation.ph")
              }
            >
              Start Selling Now
              <ArrowRight className="w-4 h-4 ml-2" />
            </Button>
          </section>
        </main>
      </div>
    </>
  );
}
