import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  DealVariant,
  DealVariantAvailability,
} from "../../../models/deal.model";
import {
  CreateOrderV2,
  CreateOrderV2Response,
  OrderCategory,
} from "../../../models/order-v2.model";
import { useCreateOrderV2Mutation } from "../../../services/order-v2.api";
import { useGetDealQuery } from "../../../services/public.api";
import { DealHeader } from "./DealHeader";
import { DealImageGallery } from "./DealImageGallery";
import { DealSidebar } from "./DealSidebar";
import DealTabs from "./DealTabs";
import { MobileBottomBar } from "./MobileBottomBar";
import { FormValues, PackageOptions } from "./PackageOptions";

type SelectedDealPageParams = {
  id: string;
};

const SelectedDealPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<SelectedDealPageParams>() as SelectedDealPageParams;
  const { data: deal, isLoading: isGetDealLoading } = useGetDealQuery(id);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [createOrder, { isLoading: isCreatingOrder }] =
    useCreateOrderV2Mutation();
  const isLoading = isCreatingOrder || isGetDealLoading;

  const { control, watch, setValue, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      selectedDates: [],
      quantities: {},
    },
  });

  const selectedDates = watch("selectedDates") || [];
  const quantities = watch("quantities");

  const [selectedVariant, setSelectedVariant] = useState<
    DealVariant | undefined
  >(undefined);
  const [selectedAvailability, setSelectedAvailability] = useState<
    DealVariantAvailability | undefined
  >(undefined);

  useEffect(() => {
    if (deal?.dealVariants) {
      const initialQuantities = deal.dealVariants.reduce((acc, variant) => {
        acc[variant.id] = 0;
        return acc;
      }, {} as { [key: string]: number });
      setValue("quantities", initialQuantities);

      const cheapestVariant = deal.dealVariants.reduce((prev, curr) => {
        const prevCheapest = prev.availabilities.reduce((p, c) =>
          p.price < c.price ? p : c
        );
        const currCheapest = curr.availabilities.reduce((p, c) =>
          p.price < c.price ? p : c
        );
        return prevCheapest.price < currCheapest.price ? prev : curr;
      });

      setSelectedVariant(cheapestVariant);
      setSelectedAvailability(
        cheapestVariant.availabilities.reduce((prev, curr) =>
          prev.price < curr.price ? prev : curr
        )
      );

      setValue("quantities", { [cheapestVariant.id]: 1 });
    }
  }, [deal, setValue]);

  const calculateSubtotal = () => {
    return (
      deal?.dealVariants?.reduce((total, variant) => {
        const quantity = quantities[variant.id] || 0;
        const availability = variant.availabilities.find(
          (avail) => avail === selectedAvailability
        );
        return (
          total + quantity * selectedDates.length * (availability?.price || 0)
        );
      }, 0) || 0
    );
  };

  const subtotal = calculateSubtotal();

  const onSubmit = async (data: FormValues) => {
    if (!selectedVariant) return;
    if (!selectedAvailability) return;

    const { availabilities, ...updatedVariantDetails } = selectedVariant;

    const formattedData: CreateOrderV2 = {
      category: OrderCategory.DEAL,
      dealOrder: {
        dealId: id,
        dealVariantId: selectedVariant.id,
        quantity: data.quantities[selectedVariant.id] * selectedDates.length, // Total quantity based on dates
        availabilityId: selectedAvailability.id,
        totalPrice: subtotal,
        variantDetails: JSON.stringify({
          ...updatedVariantDetails,
        }),
        selectedDates: JSON.stringify(data.selectedDates), // Pass selectedDates as array
      },
    };

    try {
      const response = await createOrder(formattedData);

      if ("data" in response) {
        const { id } = response.data as CreateOrderV2Response;
        navigate(`/checkout?orderId=${id}`);
      } else if ("error" in response) {
        console.error("Failed to create order:", response.error);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  if (!deal) return null;

  return (
    <div className="container px-4 py-8 pb-24 mx-auto md:pb-8">
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          <div className="md:col-span-2">
            <DealHeader deal={deal} isLoading={isLoading} />
            <DealImageGallery deal={deal} isLoading={isLoading} />
            <PackageOptions
              deal={deal}
              control={control}
              watch={watch}
              setValue={setValue}
              selectedVariant={selectedVariant}
              selectedAvailability={selectedAvailability}
              onSelectVariant={setSelectedVariant}
              onSelectAvailability={setSelectedAvailability}
            />
            <DealTabs />
          </div>
          <DealSidebar
            deal={{
              price: subtotal,
            }}
            selectedAvailability={selectedAvailability}
            selectedVariant={selectedVariant}
            isLoading={isLoading}
          />
        </div>
      </form>
      <MobileBottomBar
        isLoading={isLoading}
        subtotal={subtotal}
        canBook={
          selectedDates.length > 0
            ? Object.values(quantities).some((qty) => qty > 0)
            : false
        }
        handleSubmit={handleSubmit(onSubmit)}
      />
    </div>
  );
};

export default SelectedDealPage;
