import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";
import Facebook from "../../../assets/coming-soon/facebook.png";
import Instagram from "../../../assets/coming-soon/instagram.png";
import LinkedIn from "../../../assets/coming-soon/linked_in.png";
import Google from "../../../assets/images/google.png";
import IOS from "../../../assets/images/ios.png";

interface TitleProps {
  text: string;
}
const Title: React.FC<TitleProps> = ({ text }) => {
  return (
    <div className="mb-2 text-lg font-bold text-white uppercase ">{text}</div>
  );
};

function Footer() {
  return (
    <div className="flex flex-col justify-between p-4 md:p-8 bg-blue-1100">
      <div className="grid md:gap-2 md:grid-cols-3">
        <div className="flex flex-col mb-4 md:flex-row md:mb-0">
          <div className="mb-4 mr-6 md:mb-0">
            <Title text="TICKETNATION" />
          </div>
          <div className="flex flex-col justify-between w-full text-white lg:ml-10 lg:mr-6">
            <Title text="SHORTCUTS" />
            <NavLink
              className={({ isActive }) => (isActive ? "font-bold" : "")}
              to="/"
            >
              HOME
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "font-bold" : "")}
              to="/resell"
            >
              RESELL
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "font-bold" : "")}
              to="/pricing-calculator"
            >
              PRICING CALCULATOR
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "font-bold" : "")}
              to="/careers"
            >
              CAREERS
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "font-bold" : "")}
              to="/partner"
            >
              BE A PARTNER
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "font-bold" : "")}
              to="https://blog.ticketnation.ph"
            >
              BLOG
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "font-bold" : "")}
              to="/artist"
            >
              ARTIST
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "font-bold" : "")}
              to="/about-us"
            >
              ABOUT US
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "font-bold" : "")}
              to="/download"
            >
              DOWNLOAD APP
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "font-bold" : "")}
              to="/privacy"
            >
              PRIVACY
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "font-bold" : "")}
              to="/terms"
            >
              TERMS
            </NavLink>
          </div>
        </div>
        <div className="flex flex-col mb-4 md:mb-0">
          <Title text="CONTACTS" />
          <div className="flex mb-2 text-white">
            <FontAwesomeIcon icon={faLocationDot} className="h-6 mr-2" />
            <p>
              {`2nd Floor Zeta Building #25D, 191, `}
              <br />
              {`Salcedo St, Legaspi Village, `}
              <br />
              {`San Lorenzo, 1, 1229, Makati`}
            </p>
          </div>
          {/* <div className="flex mb-2 text-white">
            <FontAwesomeIcon icon={faPhone} className="h-6 mr-2" />
            <p>{`757-64341 | +639999425510`}</p>
          </div> */}
          <div className="flex text-white">
            <FontAwesomeIcon icon={faEnvelope} className="h-6 mr-2" />
            <p>{`corporate@ticketnation.ph`}</p>
          </div>
        </div>
        <div className="flex flex-col mb-4 md:mb-0">
          <Title text="DOWNLOAD THE APP NOW" />
          <div className="flex">
            <a
              className="mr-4"
              href="https://apps.apple.com/ph/app/ticketnation/id1629211502"
              target="_blank"
              rel="noreferer noreferrer"
            >
              <div className="">
                <img className="rounded-md" src={IOS} alt="ios" />
              </div>
            </a>
            <a
              className=""
              href="https://play.google.com/store/apps/details?id=com.altev.ticketnations"
              target="_blank"
              rel="noreferer noreferrer"
            >
              <div>
                <img className="rounded-md" src={Google} alt="google" />
              </div>
            </a>
          </div>
          <div className="mt-2">
            <Title text="FOLLOW US" />
          </div>
          <div className="flex">
            <a
              className="mx-1"
              href="https://www.facebook.com/ticketnationphofficial"
              target="_blank"
              rel="noreferer noreferrer"
            >
              <div className="py-3 pr-2">
                <img src={Facebook} alt="facebook" />
              </div>
            </a>
            <a
              className="mx-1"
              href="https://www.instagram.com/ticketnationphilippines/"
              target="_blank"
              rel="noreferer noreferrer"
            >
              <div className="py-3 pr-2">
                <img src={Instagram} alt="Instagram" />
              </div>
            </a>
            <a
              className="mx-1"
              href="https://www.linkedin.com/company/ticketnation-philippines"
              target="_blank"
              rel="noreferer noreferrer"
            >
              <div className="py-3">
                <img src={LinkedIn} alt="linkedin" />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-4 border-t-2 border-white">
        <div className="py-3 text-white">Copyright &copy; 2022</div>
      </div>
    </div>
  );
}

export default Footer;
