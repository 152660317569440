import React from 'react'
import { motion } from 'framer-motion'
import {  FaUserFriends, FaComments, FaHandshake } from 'react-icons/fa'

const LinkPage: React.FC = () => {
    return (
        <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">

            <main>
                <section className="py-20">
                    <div className="container mx-auto px-4 text-center">
                        <motion.h2
                            className="text-4xl md:text-5xl font-bold text-gray-800 mb-4"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            Connect with Fellow Event-Goers
                        </motion.h2>
                        <motion.p
                            className="text-xl text-gray-600 mb-8"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                        >
                            Ticketnation Links: Your free gateway to meaningful connections and unforgettable experiences
                        </motion.p>
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <a
                                href="#"
                                className="bg-purple-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-purple-700 transition duration-300"
                            >
                                Create Your Free Profile
                            </a>
                        </motion.div>
                    </div>
                </section>

                <section id="features" className="py-20 bg-gray-50">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">Why Use Ticketnation Links?</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                            {[
                                { icon: <FaUserFriends />, title: "Connect with Co-Attendees", description: "Build meaningful relationships with people who share your interests and event experiences." },
                                { icon: <FaComments />, title: "Enhanced Networking", description: "Facilitate conversations before, during, and after events to maximize your networking potential." },
                                { icon: <FaHandshake />, title: "Quality Relationships", description: "Foster deeper connections by sharing your social profiles and interests with fellow event-goers." }
                            ].map((feature, index) => (
                                <motion.div
                                    key={index}
                                    className="bg-white p-6 rounded-lg shadow-md"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                >
                                    <div className="text-purple-600 text-4xl mb-4">{feature.icon}</div>
                                    <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                                    <p className="text-gray-600">{feature.description}</p>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </section>

                <section id="how-it-works" className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">How It Works</h2>
                        <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-8">
                            {[
                                { step: 1, title: "Create Your Profile", description: "Sign up for free and customize your Ticketnation Links profile" },
                                { step: 2, title: "Add Your Social Links", description: "Connect your social media accounts and other relevant links" },
                                { step: 3, title: "Network at Events", description: "Share your profile with other attendees and start building relationships" }
                            ].map((step, index) => (
                                <motion.div
                                    key={index}
                                    className="flex flex-col items-center text-center"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: index * 0.2 }}
                                >
                                    <div className="w-16 h-16 bg-purple-600 rounded-full flex items-center justify-center text-white text-2xl font-bold mb-4">
                                        {step.step}
                                    </div>
                                    <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                                    <p className="text-gray-600">{step.description}</p>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </section>

                <section id="testimonials" className="py-20 bg-gray-50">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">What Our Users Say</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            {[
                                { name: "Maria Santos", role: "Event Organizer", quote: "Ticketnation Links has revolutionized how attendees connect at our events. It's now an essential part of our event planning process!" },
                                { name: "John Doe", role: "Frequent Event-Goer", quote: "I've made so many meaningful connections through Ticketnation Links. It's incredible how it enhances the entire event experience." }
                            ].map((testimonial, index) => (
                                <motion.div
                                    key={index}
                                    className="bg-white p-6 rounded-lg shadow-md"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                >
                                    <p className="text-gray-600 mb-4">"{testimonial.quote}"</p>
                                    <div className="font-semibold">{testimonial.name}</div>
                                    <div className="text-sm text-gray-500">{testimonial.role}</div>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="py-20">
                    <div className="container mx-auto px-4 text-center">
                        <h2 className="text-3xl font-bold text-gray-800 mb-8">Ready to Enhance Your Event Experience?</h2>
                        <a
                            href="#"
                            className="bg-purple-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-purple-700 transition duration-300"
                        >
                            Join Ticketnation Links for Free
                        </a>
                    </div>
                </section>
            </main>


        </div>
    )
}

export default LinkPage

