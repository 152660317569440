import DownloadPic from "../../assets/images/groupdownload.png";
import IosBlack from "../../assets/images/iosblack.png";
import GoogleBlack from "../../assets/images/googleblack.png";
import { Helmet } from "react-helmet";

const DownloadPage = () => {
  return (
    <div className="mt-8 mb-12">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ticketnation Philippines | Download</title>
        <link rel="canonical" href="https://ticketnation.ph/download" />
      </Helmet>
      <div className="flex items-center justify-center">
        <div className="">
          <img src={DownloadPic} alt="download" />
          <div className="flex items-center justify-center px-4 pb-8">
            <a
              className="mr-4"
              href="https://apps.apple.com/ph/app/ticketnation/id1629211502"
              target="_blank"
              rel="noreferer noreferrer"
            >
              <div className="mr-4 lg:mr-12">
                <img className="rounded-lg" src={IosBlack} alt="iosblack" />
              </div>
            </a>
            <a
              className=""
              href="https://play.google.com/store/apps/details?id=com.altev.ticketnations"
              target="_blank"
              rel="noreferer noreferrer"
            >
              <div>
                <img
                  className="rounded-lg"
                  src={GoogleBlack}
                  alt="googleblack"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;
