import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { useLogoutMutation } from "../../../services/auth.api";
import { logout } from "../authSlice";

export const Logout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [logoutMutation, { isSuccess }] = useLogoutMutation();
  React.useEffect(() => {
    if (isSuccess) {
      localStorage.removeItem("refreshToken");
      dispatch(logout());
      navigate("/");
    }
  }, [isSuccess, dispatch, navigate]);

  const handleLogout = () => {
    logoutMutation({});
  };
  return (
    <button
      className="w-24 p-1 text-white underline border-blue-900 rounded-sm"
      onClick={handleLogout}
    >
      LOGOUT
    </button>
  );
};
