import React from "react";
import BannerDefault from "../../../assets/images/artistbannerdefault.png";
import BannerArtistDefault from "../../../assets/images/defaultartistpic.jpg";

import GetToKnow from "../../../assets/images/gettoknow.png";
import Booked from "../../../assets/images/booked.png";
import MusicandEnjoy from "../../../assets/images/musicandenjoy.png";

import { useParams } from "react-router-dom";
import { useGetGroupQuery } from "../../../services/artist.api";
import Loading from "../../../components/Loading";
import Error from "../../../components/Error";
import { getImageURL } from "../../../helpers/imagehelper";
import { Genre } from "../../../models/performer.model";

import { Modal } from "../../../components/Modal";
import { ArtistInquiry } from "../../../features/Artist/ArtistInquiry";
import { Songs } from "../../../components";
import { Members } from "../../../components/Artist/Members";

type UpdateArtistParams = {
  id: string;
};

const GroupDetailsPage: React.FC = () => {
  const { id } = useParams<UpdateArtistParams>() as UpdateArtistParams;
  const [inquiryOpen, setInquiryOpen] = React.useState(false);
  const { data: artist, isLoading, isError } = useGetGroupQuery(id);
  if (isLoading) return <Loading />;

  if (!artist) {
    return <span>Artist Not Found</span>;
  }

  return (
    <div className="h-full mb-4">
      <div className="h-[208px] lg:h-[468px] mb-2">
        <div
          className=""
          style={{
            background: `url(${
              getImageURL(artist.banner?.fileName || "") || BannerDefault
            })`,
            height: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "black",
          }}
        />
      </div>

      {inquiryOpen && (
        <Modal
          open={inquiryOpen}
          setOpen={setInquiryOpen}
          size="xl"
          title="Inquire"
        >
          <ArtistInquiry setOpen={setInquiryOpen} performerId={id} />
        </Modal>
      )}

      {/* Artist Details */}

      <div className="px-2 lg:px-0">
        <div className="flex mb-2">
          <img
            className="w-[180px] h-[240px] object-cover rounded-sm lg:h-[400px] lg:w-[300px] shadow-md"
            src={
              getImageURL(artist.image?.fileName || "") || BannerArtistDefault
            }
            alt="loki"
          />
          <div className="flex flex-col justify-between flex-1 pl-2 lg:pl-4">
            <div>
              <h2 className="text-2xl font-semibold lg:text-4xl">
                {artist.name}
              </h2>
              {artist.genres.length > 0 && (
                <div className="flex">
                  {artist.genres.map((g: Genre, index: number) => {
                    return (
                      <div
                        key={index}
                        className="p-1 mx-1 text-xs text-center border rounded-sm border-blue-1100 text-blue-1100 lg:w-[120px]"
                      >
                        {g.name}
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="max-w-3xl my-1 text-base lg:my-2 lg:text-lg">
                {artist.description}
              </div>
            </div>
            {artist.isPartner && (
              <div className="">
                <button
                  onClick={() => setInquiryOpen(true)}
                  className="w-full md:w-[360px] p-2 font-semibold text-white  rounded-sm bg-blue-1100 border-blue-1100 lg:h-[64px]"
                >
                  INQUIRE NOW
                </button>
              </div>
            )}
          </div>
        </div>
        <Members members={artist.groupPerformers} />
      </div>

      {/*  IMAGES */}
      <div className="grid grid-cols-1 p-6 mt-6 bg-white justify-items-center lg:grid-cols-3">
        <div>
          <div>
            <img
              className="w-[200px] h-[240px] object-cover"
              src={GetToKnow}
              alt="GetToKnow"
            />
          </div>
          <div className="mt-2 text-xl font-semibold text-center">
            GET TO KNOW
          </div>
        </div>
        <div>
          <div>
            <img
              className="w-[200px] h-[240px] object-cover"
              src={Booked}
              alt="Booked"
            />
          </div>
          <div className="mt-2 text-xl font-semibold text-center"> BOOKED</div>
        </div>
        <div>
          <div>
            <img
              className="w-[200px] h-[240px] object-cover"
              src={MusicandEnjoy}
              alt="MusicandEnjoy"
            />
          </div>
          <div className="mt-2 text-xl font-semibold text-center">
            MUSIC AND ENJOY
          </div>
        </div>
      </div>

      {/* Playlist */}
      <Songs songs={artist.songs} />

      {/* Gallery */}
    </div>
  );
};

export default GroupDetailsPage;
