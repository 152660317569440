import { useState } from "react";
import { useGetOrdersQuery } from "../../../services/order.api";
import OrderList from "./OrderList";

const CurrentOrders = () => {
  const [skip, setSkip] = useState(0);
  const [take] = useState(80);

  // Fetch current orders
  const {
    data: userOrders,
    isLoading,
    isError,
  } = useGetOrdersQuery({
    skip,
    take,
    dateFilter: "current", // Pass the type as 'current'
    search: "",
  });

  return (
    <div>
      {isLoading && <p>Loading...</p>}
      {isError && <p>Error fetching current orders</p>}
      {userOrders?.data && <OrderList orders={userOrders.data} />}
    </div>
  );
};

export default CurrentOrders;
