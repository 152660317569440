import { Helmet } from "react-helmet";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "../../components/Tabs";

export const TermsPage = () => {
  return (
    <div className="pb-5 text-gray-500">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ticketnation Philippines | Terms</title>
        <link rel="canonical" href="https://ticketnation.ph/terms" />
      </Helmet>
      <Tabs>
        <TabList grid={2}>
          <Tab>
            <div className="p-1">
              <span className="text-2xl font-semibold lg:text-4xl">
                General
              </span>
            </div>
          </Tab>
          <Tab>
            <div className="p-1">
              <span className="text-2xl font-semibold lg:text-4xl">Resell</span>
            </div>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <GeneralTerms />
          </TabPanel>
          <TabPanel>
            <ResellTerms />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

const GeneralTerms = () => {
  return (
    <div>
      <div className="max-w-6xl px-5 mx-auto">
        <div className="py-6 text-4xl text-gray-600">
          Terms and Conditions for Ticketnation Philippines
        </div>

        <div className="pt-4 text-4xl text-gray-600">Introduction</div>
        <div className="text-gray-500 text-md">
          Welcome to Ticketnation Philippines (hereinafter referred to as "the
          Company" or "Ticketnation"), an online platform that offers customers
          the opportunity to purchase tickets for various events, such as
          concerts, theater performances, sports events, and other live shows.
          By using our website (
          <a href="https://www.ticketnation.ph/" className="text-blue-500">
            <u>www.ticketnation.ph</u>
          </a>
          ) or mobile application, you agree to be bound by these Terms and
          Conditions ("Terms").
        </div>
      </div>

      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600 ">Definitions</div>
        <div className="text-gray-500 text-md">
          <ul>
            <h2>In these Terms, the following definitions apply:</h2>
            <li>
              a. Personal Information When you register for an account or
              purchase tickets through Ticketnation, we may collect personal
              information, such as:
            </li>
            <li>
              b. "Event Organizer" refers to the individual, group, or
              organization responsible for organizing and managing an Event.
            </li>
            <li>
              c. "Ticket" refers to a digital or physical document or proof of
              admission to an Event.
            </li>
            <li>
              d. "User" or "you" refers to any person who uses the Ticketnation
              platform, whether as a visitor or a registered member.
            </li>
          </ul>
        </div>
      </div>

      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600 ">
          Registration and Account Security
        </div>
        <div className="text-gray-500 text-md">
          <h2>
            To purchase Tickets through Ticketnation, you may be required to
            create an account and provide accurate, complete, and up-to-date
            personal information. You are responsible for maintaining the
            confidentiality of your account login credentials and for all
            activities that occur under your account. You agree to immediately
            notify Ticketnation of any unauthorized use of your account or any
            other breach of security.
          </h2>
        </div>
      </div>

      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600 ">Ticket Purchases</div>
        <div className="text-gray-500 text-md">
          <ul>
            <li>
              a. All Ticket sales are final and non-refundable, except as
              required by law or in accordance with the Event Organizer's
              policies.
            </li>
            <li>
              b. Ticket prices, availability, and seating arrangements are
              determined solely by the Event Organizer and are subject to change
              without prior notice.
            </li>
            <li>
              c. Payment for Tickets must be made using the payment methods
              available on the Ticketnation platform. By submitting payment, you
              warrant that you are authorized to use the chosen payment method.
            </li>
            <li>
              d. Once your payment is confirmed, Ticketnation will issue an
              e-ticket to the email address you provided. It is your
              responsibility to check the accuracy of the e-ticket and notify
              Ticketnation of any discrepancies immediately.
            </li>
          </ul>
        </div>
      </div>

      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600 ">
          Event Admission and Conduct
        </div>
        <div className="text-gray-500 text-md">
          <ul>
            <li>
              a. To gain admission to an Event, you must present a valid
              e-ticket or physical Ticket, along with a valid photo ID.
            </li>
            <li>
              b. Event Organizers reserve the right to refuse entry or remove
              attendees from the venue if they violate any rules or regulations,
              or if their conduct is deemed inappropriate or disruptive.
            </li>
            <li>
              c. You acknowledge that you attend Events at your own risk and
              agree to indemnify and hold harmless Ticketnation and the Event
              Organizer for any personal injury, property damage, or other loss
              arising from your attendance.
            </li>
          </ul>
        </div>
      </div>

      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600 ">
          Event Changes and Cancellations
        </div>
        <div className="text-gray-500 text-md">
          <p>
            a. Event Organizers may change or cancel Events at their discretion.
            In such cases, Ticketnation will notify Users as soon as possible
            and provide information about refunds or exchanges, if applicable.
          </p>
          <p>
            b. Ticketnation is not responsible for any losses or expenses
            incurred by Users as a result of Event changes or cancellations,
            including but not limited to travel and accommodation costs.
          </p>
          <p>
            c. Please be aware that ticket fees and order processing fees are
            non-refundable. These fees play a crucial role in supporting the
            security and functionality of our platform, ensuring a seamless and
            secure ticketing experience for all users.
          </p>
        </div>
      </div>

      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600 ">Intellectual Property</div>
        <div className="text-gray-500 text-md">
          <h2>
            All content on the Ticketnation platform, including but not limited
            to text, graphics, logos, and images, is the property of
            Ticketnation or its content suppliers and is protected by copyright
            and other intellectual property laws. You may not reproduce,
            distribute, or use any content from the Ticketnation platform
            without the prior written consent of Ticketnation.
          </h2>
        </div>
      </div>

      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600 ">Limitation of Liability</div>
        <div className="text-gray-500 text-md">
          <h2>
            To the maximum extent permitted by law, Ticketnation shall not be
            liable for any direct, indirect, incidental, consequential, or
            punitive damages arising out of your use of the Ticketnation
            platform, your inability to use the platform, or any errors or
            omissions in the content provided.
          </h2>
        </div>
      </div>

      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600 ">Governing Law</div>
        <div className="text-gray-500 text-md">
          <h2>
            These terms shall be governed by and construed in accordance with
            the laws of the Philippines. Any disputes arising from these Terms
            or your use of the Ticketnation platform shall be subject to the
            exclusive jurisdiction of the courts of the Philippines.
          </h2>
        </div>
      </div>

      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600 ">Privacy</div>
        <div className="text-gray-500 text-md">
          <h2>
            Ticketnation respects your privacy and is committed to protecting
            your personal information. Please refer to our Privacy Policy (
            <a
              href="https://www.ticketnation.ph/privacy"
              className="text-blue-500"
            >
              <u>www.ticketnation.ph/privacy</u>
            </a>
            ) for information about how we collect, use, and disclose your
            personal information.
          </h2>
        </div>
      </div>

      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600 ">
          Changes to Terms and Conditions
        </div>
        <div className="text-gray-500 text-md">
          <h2>
            Ticketnation reserves the right to amend or update these Terms at
            any time, without prior notice. Any changes will be effective
            immediately upon posting on the Ticketnation platform. Your
            continued use of the platform after any changes constitutes your
            acceptance of the revised Terms.
          </h2>
        </div>
      </div>

      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600 ">Severability</div>
        <div className="text-gray-500 text-md">
          <h2>
            If any provision of these Terms is found to be invalid or
            unenforceable by a court of competent jurisdiction, the remaining
            provisions shall remain in full force and effect.
          </h2>
        </div>
      </div>

      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600 ">Waiver</div>
        <div className="text-gray-500 text-md">
          <h2>
            No failure or delay by Ticketnation in exercising any right, power,
            or remedy under these Terms shall operate as a waiver thereof, nor
            shall any single or partial exercise of any right, power, or remedy
            preclude any other or further exercise thereof or the exercise of
            any other right, power, or remedy.
          </h2>
        </div>
      </div>

      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600 ">Entire Agreement</div>
        <div className="text-gray-500 text-md">
          <h2>
            These Terms, together with the Privacy Policy, constitute the entire
            agreement between you and Ticketnation with respect to your use of
            the Ticketnation platform, and supersede all prior or
            contemporaneous understandings, agreements, representations, and
            warranties, both written and oral, with respect to your use of the
            platform.
          </h2>
        </div>
      </div>
    </div>
  );
};

const ResellTerms = () => {
  return (
    <div>
      {/* Introduction Section */}
      <div className="max-w-6xl px-5 mx-auto">
        <div className="py-6 text-4xl text-gray-600">
          Terms and Conditions for Ticketnation Resell
        </div>
        <div className="pt-4 text-4xl text-gray-600">Introduction</div>
        <div className="text-gray-500 text-md">
          Welcome to Ticketnation Resell (hereinafter referred to as "the
          Company" or "Ticketnation"), an online platform that allows users to
          buy and sell tickets for various events, including concerts, theater
          performances, sports events, and other live shows. By using our
          website (
          <a href="https://resell.ticketnation.ph" className="text-blue-500">
            <u>resell.ticketnation.ph</u>
          </a>
          ) or mobile application, you agree to be bound by these Terms and
          Conditions ("Terms").
        </div>
      </div>

      {/* Definitions Section */}
      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600">Definitions</div>
        <div className="text-gray-500 text-md">
          <ul>
            <h2>In these Terms, the following definitions apply:</h2>
            <li>
              a. "Event Organizer" refers to the individual, group, or
              organization responsible for organizing and managing an Event.
            </li>
            <li>
              b. "Ticket" refers to a digital or physical document or proof of
              admission to an Event.
            </li>
            <li>
              c. "User" or "you" refers to any person who uses the Ticketnation
              Resell platform, whether as a visitor, buyer, or seller.
            </li>
            <li>
              d. "Seller" refers to a registered user who lists a Ticket for
              sale on the Ticketnation Resell platform.
            </li>
            <li>
              e. "Buyer" refers to a registered user who purchases a Ticket from
              a Seller on the Ticketnation Resell platform.
            </li>
            <li>
              f. "Platform" refers to the Ticketnation Resell website and mobile
              application.
            </li>
          </ul>
        </div>
      </div>

      {/* Registration and Account Security Section */}
      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600">
          Registration and Account Security
        </div>
        <div className="text-gray-500 text-md">
          <h2>
            To use the Ticketnation Resell platform, you may be required to
            create an account and provide accurate, complete, and up-to-date
            personal information. You are responsible for maintaining the
            confidentiality of your account login credentials and for all
            activities that occur under your account. You agree to immediately
            notify Ticketnation Resell of any unauthorized use of your account
            or any other breach of security.
          </h2>
        </div>
      </div>

      {/* Listing Tickets for Sale Section */}
      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600">Listing Tickets for Sale</div>
        <div className="text-gray-500 text-md">
          <ul>
            <li>
              a. Sellers must provide accurate information about the Ticket,
              including but not limited to event details, seat location, and
              price.
            </li>
            <li>
              b. Sellers are responsible for ensuring the legitimacy of the
              Tickets they list for sale. Listing counterfeit or invalid Tickets
              is strictly prohibited.
            </li>
            <li>
              c. Ticketnation reserves the right to remove any listings that
              violate these Terms or are deemed fraudulent or suspicious.
            </li>
          </ul>
        </div>
      </div>

      {/* Purchasing Tickets Section */}
      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600">Purchasing Tickets</div>
        <div className="text-gray-500 text-md">
          <ul>
            <li>
              a. Buyers must verify the accuracy of the Tickets before
              completing a purchase. Ticketnation Resell is not responsible for
              any discrepancies in the ticket information provided by Sellers.
            </li>
            <li>
              b. Payment for Tickets must be made using the payment methods
              available on the Ticketnation Resell platform.
            </li>
            <li>
              c. Once payment is confirmed, the Buyer will receive an e-ticket
              or digital proof of purchase. The Buyer is responsible for
              reviewing and verifying the ticket details upon receipt.
            </li>
          </ul>
        </div>
      </div>

      {/* Refunds and Disputes Section */}
      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600">Refunds and Disputes</div>
        <div className="text-gray-500 text-md">
          <ul>
            <li>
              a. All sales on the Ticketnation Resell platform are final.
              Refunds are not provided unless the Buyer is unable to gain
              admission to the event due to issues with the validity of the
              Ticket, as verified by the event organizer.
            </li>
            <li>
              b. In cases where a Buyer is denied entry to an event, the Buyer
              must notify Ticketnation Resell within 24 hours of the event date
              to initiate a refund request.
            </li>
            <li>
              c. Ticketnation Resell will investigate all refund requests and
              may require documentation or proof of denial from the event
              organizer.
            </li>
            <li>
              d. If a refund request is approved, the Buyer will receive a full
              refund of the Ticket purchase price.
            </li>
          </ul>
        </div>
      </div>

      {/* Privacy and Data Security Section */}
      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600">Privacy and Data Security</div>
        <div className="text-gray-500 text-md">
          <h2>
            Ticketnation Resell values your privacy and is committed to
            protecting your personal information. Please refer to our Privacy
            Policy for information about how we collect, use, and disclose your
            personal information.
          </h2>
        </div>
      </div>

      {/* Limitation of Liability Section */}
      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600">Limitation of Liability</div>
        <div className="text-gray-500 text-md">
          <h2>
            To the maximum extent permitted by law, Ticketnation Resell shall
            not be liable for any direct, indirect, incidental, consequential,
            or punitive damages arising out of your use of the Ticketnation
            Resell platform, your inability to use the platform, or any errors
            or omissions in the content provided.
          </h2>
        </div>
      </div>

      {/* Governing Law Section */}
      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600">Governing Law</div>
        <div className="text-gray-500 text-md">
          <h2>
            These Terms shall be governed by and construed in accordance with
            the laws of the Philippines. Any disputes arising from these Terms
            or your use of the Ticketnation Resell platform shall be subject to
            the exclusive jurisdiction of the courts of the Philippines.
          </h2>
        </div>
      </div>

      {/* Changes to Terms and Conditions Section */}
      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600">
          Changes to Terms and Conditions
        </div>
        <div className="text-gray-500 text-md">
          <h2>
            Ticketnation Resell reserves the right to amend or update these
            Terms at any time, without prior notice. Any changes will be
            effective immediately upon posting on the Ticketnation Resell
            platform. Your continued use of the platform after any changes
            constitutes your acceptance of the revised Terms.
          </h2>
        </div>
      </div>

      {/* Entire Agreement Section */}
      <div className="max-w-6xl px-5 pt-6 mx-auto">
        <div className="text-4xl text-gray-600">Entire Agreement</div>
        <div className="text-gray-500 text-md">
          <h2>
            These Terms, together with the Privacy Policy, constitute the entire
            agreement between you and Ticketnation Resell with respect to your
            use of the Ticketnation Resell platform, and supersede all prior or
            contemporaneous understandings, agreements, representations, and
            warranties, both written and oral, with respect to your use of the
            platform.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default ResellTerms;
