import React from 'react'
import { Link } from 'react-router-dom'
import TicketNationLogo from '../../../assets/images/ticketnationlogo.png'
import Facebook from '../../../assets/coming-soon/facebook.png'
import Instagram from '../../../assets/coming-soon/instagram.png'
import LinkedIn from '../../../assets/coming-soon/linked_in.png'

function Privacy() {
  const [open, setOpen] = React.useState(false)

  const openNav = () => {
    setOpen(!open)
  }
  return (
    <div>
      <div className='bg-blue-1100'>
        <div className='mx-auto max-w-7xl'>
          <div className='flex items-center justify-between h-20 px-6 py-4'>
            <Link to='/'>
              <div className='text-white'>
                <img src={TicketNationLogo} alt='ticketnationlogo' />
              </div>
            </Link>

            <div className='hidden lg:block'>
              <div className='flex'>
                <Link to='/'>
                  <div className='px-5 font-medium text-white'>COMING SOON</div>
                </Link>
                <Link to='/what-we-do'>
                  <div className='px-5 font-medium text-white'>WHAT WE DO</div>
                </Link>
                <Link to='/about-us'>
                  <div className='px-5 font-medium text-white'>ABOUT US</div>
                </Link>
                <Link to='/privacy-policy'>
                  <div className='px-5 font-medium text-white'>
                    PRIVACY POLICY
                  </div>
                </Link>
              </div>
            </div>

            <div
              className='block text-2xl text-white lg:hidden'
              role='button'
              onClick={() => openNav()}
            >
              <i className='fa fa-bars'></i>
            </div>
          </div>

          {open ? (
            <div className='relative'>
              <div className='bg-blue-1100 w-full absolute transition-all duration-500 z-10'>
                <Link to='/'>
                  <div className='px-5 pt-1 font-medium text-white'>
                    COMING SOON
                  </div>
                </Link>
                <Link to='/what-we-do'>
                  <div className='px-5 pt-1 font-medium text-white'>
                    WHAT WE DO
                  </div>
                </Link>
                <Link to='/about-us'>
                  <div className='px-5 pt-1 font-medium text-white'>
                    ABOUT US
                  </div>
                </Link>
                <Link to='/privacy-policy'>
                  <div className='px-5 font-medium text-white'>
                    PRIVACY POLICY
                  </div>
                </Link>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      <div className='max-w-6xl py-5 mx-auto'>
        <div className='py-4 text-4xl text-gray-600'>Privacy Policy</div>
        <div className='py-2 text-gray-500 text-md'>
          Ticketnation has created this Privacy Policy in order to demonstrate
          our firm commitment to privacy. The following discloses how we
          collect, store and use information about customers and prospects, and
          how to correct or change such information. Ticketnation’s Privacy
          Policy is not intended to and does not create any contractual or other
          legal rights in or on behalf of any party.
        </div>
        <div className='py-2 text-4xl text-gray-600'>Personally Identifiable Information</div>
        <div className='py-2 text-gray-500 text-md'>
          Ticketnation does not collect personally identifiable information
          (e.g., name, address, e-mail address, age, or income level) about
          individuals except when such individuals specifically provide such
          information on a voluntary basis. For example, such personally
          identifiable information may be gathered from a contest or sweepstakes
          registration, the registration process for membership services and in
          connection with content submissions, community postings (e.g., chat
          and bulletin/message boards), suggestions, voting/polling activities,
          online shopping and other transactional areas. Ticketnation uses your
          IP address to help diagnose problems with our server, and to
          administer our web site. Your IP address is used to help identify you
          and your shopping cart and to gather broad demographic information.
        </div>
        <div className='py-2 text-gray-500 text-md'>
          Ticketnation does not sell, trade, or rent personal identification
          information and content uploaded to our site.
        </div>
        <div className='py-2 text-gray-500 text-md'>
          We will maintain appropriate safeguards to ensure the security,
          integrity and privacy of your personally identifiable information.
          Notwithstanding any failure to opt in or out of promotional
          information by the user, Ticketnation reserves the right to contact a
          subscriber regarding account status, changes to the service agreement
          and other matters relevant to the underlying service and/or the
          information collected.
        </div>
        <div className='py-2 text-gray-500 text-md'>
          Ticketnation reserves the right to perform statistical analyses of
          user behavior and characteristics in order to measure interest in and
          use of the various areas of the site and to inform advertisers of such
          information as well as the number of users that have been exposed to
          or clicked on their advertising banners. Ticketnation will provide
          only “aggregated” data – – meaning that the information from many
          users is grouped together in a way that does not disclose the personal
          information of any particular user – – from those analyses to third
          parties.
        </div>
        <div className='py-2 text-4xl text-gray-600'>Use of Cookies</div>
        <div className='py-2 text-gray-500 text-md'>
          Users should also be aware that non-personal information and data may
          be automatically collected through the standard operation of
          Ticketnation’s Internet servers or through the use of “cookies”.
          “Cookies” are small data files that web sites transfer to your
          computer’s hard drive for record-keeping purposes. Cookies allow us to
          serve you faster and with better quality, and make web surfing easier
          for you by saving your passwords, purchases and preferences while you
          are at our site so you don’t have to re-enter such information each
          time you visit our site. The use of cookies is now an industry
          standard, and you’ll find them used on almost every major web site to
          provide an enhanced online experience. Cookies are not programs that
          come onto a user’s system and damage files. Generally, cookies work by
          assigning a unique number to the user that has no meaning outside the
          assigning site. Cookies allow us to track usage patterns and to
          compile data that can help us improve our content and target
          advertising. Further, we use cookies to deliver content specific to
          your interests and to save your member ID. No other company has access
          to our cookies. Users should be aware that Ticketnation cannot control
          the use of cookies or the resulting information by advertisers of
          third parties hosting data for Ticketnation. These cookies are not
          subject to Ticketnation’s Privacy Policy. If a user does not want
          information collected through the use of cookies, there is a simple
          procedure in most browsers that allows the user to deny or accept the
          cookie feature. However, users should note that cookies may be
          necessary to provide the user with certain features (e.g., customized
          delivery of information) available on the Ticketnation site.
        </div>
        <div className='py-2 text-4xl text-gray-600'>Personal Information</div>
        <div className='py-2 text-gray-500 text-md'>
          Upon request, Ticketnation will use reasonable efforts to allow users
          to update/correct personal information previously submitted which the
          user states is erroneous to the extent such activities will not
          compromise privacy or security interests. Also, upon a user’s request,
          Ticketnation will use commercially reasonable efforts to functionally
          delete the user and his or her personal information from its database;
          however, it may be impossible to delete a user’s entry without some
          residual information because of backups and records of deletions.
        </div>
        <div className='py-2 text-4xl text-gray-600'>Acceptance of Terms</div>
        <div className='py-2 text-gray-500 text-md'>
          By using this site, you signify your assent to Ticketnation’s Privacy
          Policy. If you do not agree to this policy, please do not use our
          site. Ticketnation reviews this policy periodically and reserves the
          right, at its discretion, to modify or remove any portions of such
          policy at any time. If we decide to change Ticketnation’s Privacy
          Policy, we will post those changes on this page. Your continued use of
          the Ticketnation site following the posting of changes to these terms
          will mean you accept those changes.
        </div>
        <div className='py-2 text-4xl text-gray-600'>Links to Third Party Sites</div>
        <div className='py-2 text-gray-500 text-md'>
          Ticketnation chooses its advertisers and business partners carefully.
          However, Ticketnation is not responsible for the content or privacy
          practices of web sites operated by third parties that are linked to
          our site. These links are meant for the user’s convenience only. Links
          to third party sites do not constitute sponsorship, endorsement or
          approval by Ticketnation of the content, policies or practices of such
          third party sites. Once you have left Ticketnation’s site via such a
          link, you should check the applicable privacy policy of the third
          party site.
        </div>
        <div className='py-2 text-gray-500 text-md'>
          Ticketnation’s site may also be linked to web sites operated by
          companies affiliated with Ticketnation. Visitors to those sites should
          refer to their separate privacy policies and practices.
        </div>
        <div className='py-2 text-4xl text-gray-600'>Security Policy</div>
        <div className='py-2 text-gray-500 text-md'>
          This site has security measures in place to protect the loss, misuse
          and alteration of the information under our control. For example, each
          and every Ticketnation employee must abide by Ticketnation’s Privacy
          Policy. Only authorized Ticketnation employees are permitted to have
          access to your personal information and such access is limited by
          need. All Ticketnation employees are required to acknowledge that they
          understand and will comply with Ticketnation’s Privacy Policy.
          Employees who violate Ticketnation’s Privacy Policy are subject to
          disciplinary action, up to and including termination.
        </div>
        <div className='py-2 text-4xl text-gray-600'>Further Information</div>
        <div  className='pt-2 pb-4 text-gray-500 text-md'>
          If you have any questions about this Privacy Policy, the practice of
          this site, or your dealings with this web site, please feel free to
          contact us via e-mail at corporate@ticketnation.ph
        </div>
      </div>

      <div>
        <div className='flex flex-col justify-between h-64 px-8 pt-6 pb-2 bg-blue-1100'>
          <div className='flex-row lg:flex md:flex'>
            <div className='mr-6 text-lg font-bold text-white'>
              TICKET NATION
            </div>
            <div className=''>
              <div className='hidden text-lg font-bold text-white lg:block md:block'>
                SHORTCUTS
              </div>
              <div className='flex justify-between w-full mt-4 text-white'>
                <Link to='/'>
                  <div className='mr-10'>COMING SOON</div>
                </Link>
                <Link to='/what-we-do'>
                  <div className='mr-10'>WHAT WE DO</div>
                </Link>
                <Link to='/about-us'>
                  <div className='mr-10'>ABOUT US</div>
                </Link>
                <Link to='/privacy-policy'>
                  <div className='px-5 font-medium text-white'>
                    PRIVACY POLICY
                  </div>
                </Link>
                {/* <div className="mr-10">PROFILE</div> */}
              </div>
            </div>
          </div>
          <div className='flex justify-between border-t-2 border-white'>
            <div className='py-3 text-white'>Copyright &copy; 2022</div>
            <div className='flex'>
              <Link to=''>
                <div className='py-3 pr-2'>
                  <img src={Facebook} alt='facebook' />
                </div>
              </Link>
              <Link to=''>
                <div className='py-3 pr-2'>
                  <img src={Instagram} alt='Instagram' />
                </div>
              </Link>
              <Link to=''>
                <div className='py-3'>
                  <img src={LinkedIn} alt='linkedin' />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Privacy
