import { CreditCard, PlusCircle } from "lucide-react";
import React, { useState } from "react";

import { useLocation } from "react-router-dom";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { Separator } from "../../components/ui/separator";
import { Textarea } from "../../components/ui/textarea";
import { useGetOrderV2Query } from "../../services/order-v2.api";

interface Address {
  id: string;
  name: string;
  isDefault: boolean;
  address: {
    address1: string;
    address2?: string;
    barangay?: string;
    zipcode: string;
    city: string;
    state: string;
    country: string;
    latitude?: string;
    longitude?: string;
  };
}

interface User {
  id: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  userAddress: Address[];
}

interface Order {
  id: string;
  orderNumber: number;
  status: string;
  total: number;
  totalDiscount: number;
  orderFee: number;
  subTotal: number;
  orderNotes: string;
  voucher?: {
    name: string;
    code: string;
    discountPercentage: number;
    expiredAt: string;
  };
  productOrder?: {
    id: string;
    tax: number;
    productOrderVariant: Array<{
      quantity: number;
      productVariant: {
        name: string;
        price: number;
        originalPrice: number;
        size: string;
        color: string;
      };
    }>;
  };
  dealOrders?: Array<{
    quantity: number;
    totalPrice: number;
    deal: {
      name: string;
      startDate: string;
      endDate: string;
    };
  }>;
  orderEventTickets: Array<{
    quantity: number;
    price: number;
    total: number;
    totalTicketFee: number;
    eventTicket: {
      name: string;
      description: string;
      section: string;
      row: string;
      price: number;
      deliveryMethod: string;
      event: {
        name: string;
        venue: {
          name: string;
          city: string;
          country: string;
        };
        dateTime: string;
      };
    };
  }>;
}
interface CheckoutPageProps {}
const CheckoutPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("orderId") || "";
  const { data: order, isLoading } = useGetOrderV2Query({ id: orderId });
  const [user, setUser] = useState<User | null>({
    id: "ae90f3b5-987d-4f42-b8c4-f9839e384123",
    email: "user@example.com",
    username: "johndoe",
    firstName: "John",
    lastName: "Doe",
    mobileNumber: "09171234567",
    userAddress: [
      {
        id: "1a2b3c4d-5e6f-7g8h-9i0j-k1l2m3n4o5p6",
        name: "Home",
        isDefault: true,
        address: {
          address1: "456 Secondary Street",
          address2: "Apt 101",
          barangay: "Barangay San Juan",
          zipcode: "1100",
          city: "Quezon City",
          state: "Metro Manila",
          country: "Philippines",
          latitude: "14.676",
          longitude: "121.043",
        },
      },
      {
        id: "2b3c4d5e-6f7g-8h9i-0j1k-2l3m4n5o6p7q",
        name: "Office",
        isDefault: false,
        address: {
          address1: "789 Corporate Plaza",
          city: "Makati City",
          state: "Metro Manila",
          country: "Philippines",
          zipcode: "1226",
        },
      },
    ],
  });

  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [newAddress, setNewAddress] = useState<
    Omit<Address, "id" | "isDefault">
  >({
    name: "",
    address: {
      address1: "",
      address2: "",
      barangay: "",
      zipcode: "",
      city: "",
      state: "",
      country: "",
    },
  });
  const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false);
  const [orderNotes, setOrderNotes] = useState("");

  const handleAddNewAddress = (e: React.FormEvent) => {
    e.preventDefault();
    // Here you would typically make an API call to save the new address
    // For now, we'll just close the modal and reset the form
    setIsNewAddressModalOpen(false);
    setNewAddress({
      name: "",
      address: {
        address1: "",
        address2: "",
        barangay: "",
        zipcode: "",
        city: "",
        state: "",
        country: "",
      },
    });
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat("en-PH", {
      style: "currency",
      currency: "PHP",
    }).format(amount);
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("en-PH", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  if (!user || !order || isLoading) return <div>Loading...</div>;
  return (
    <div className="container grid gap-6 p-2 mx-auto md:grid-cols-2 lg:gap-12">
      <div className="space-y-6">
        <h1 className="mb-6 text-2xl font-semibold">Checkout</h1>

        <Card>
          <CardHeader>
            <CardTitle>Payment Method</CardTitle>
          </CardHeader>
          <CardContent>
            <RadioGroup defaultValue="credit" className="grid gap-4">
              <div className="flex items-center space-x-4">
                <RadioGroupItem value="credit" id="credit" />
                <Label
                  htmlFor="credit"
                  className="flex items-center justify-between flex-1 p-4 border rounded-lg"
                >
                  <div className="space-y-1">
                    <p>Credit / Debit Card</p>
                    <p className="text-sm text-muted-foreground">
                      Pay securely with your card
                    </p>
                  </div>
                  <CreditCard className="w-5 h-5 text-muted-foreground" />
                </Label>
              </div>
            </RadioGroup>

            <div className="mt-6 space-y-4">
              <div className="space-y-2">
                <Label htmlFor="card-number">Card Number</Label>
                <Input id="card-number" placeholder="0000 0000 0000 0000" />
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="expiry">Expiry Date</Label>
                  <Input id="expiry" placeholder="MM/YY" />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="cvc">CVC</Label>
                  <Input id="cvc" placeholder="CVC" />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Billing Address</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="address-select">Select Address</Label>
                <Select
                  value={selectedAddress}
                  onValueChange={setSelectedAddress}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select an address" />
                  </SelectTrigger>
                  <SelectContent>
                    {user.userAddress.map((address) => (
                      <SelectItem key={address.id} value={address.id}>
                        {address?.name} - {address.address.address1},{" "}
                        {address.address.city}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Dialog
                  open={isNewAddressModalOpen}
                  onOpenChange={setIsNewAddressModalOpen}
                >
                  <DialogTrigger asChild>
                    <Button variant="outline">
                      <PlusCircle className="w-4 h-4 mr-2" />
                      Add New Address
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                      <DialogTitle>Add New Address</DialogTitle>
                      <DialogDescription>
                        Enter the details for your new address.
                      </DialogDescription>
                    </DialogHeader>
                    <form onSubmit={handleAddNewAddress}>
                      <div className="grid gap-4 py-4">
                        <div className="grid gap-2">
                          <Label htmlFor="address-name">Address Name</Label>
                          <Input
                            id="address-name"
                            value={newAddress?.name}
                            onChange={(e) =>
                              setNewAddress({
                                ...newAddress,
                                name: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="grid gap-2">
                          <Label htmlFor="address1">Street Address</Label>
                          <Input
                            id="address1"
                            value={newAddress.address.address1}
                            onChange={(e) =>
                              setNewAddress({
                                ...newAddress,
                                address: {
                                  ...newAddress.address,
                                  address1: e.target.value,
                                },
                              })
                            }
                          />
                        </div>
                        <div className="grid gap-2">
                          <Label htmlFor="address2">Address Line 2</Label>
                          <Input
                            id="address2"
                            value={newAddress.address.address2}
                            onChange={(e) =>
                              setNewAddress({
                                ...newAddress,
                                address: {
                                  ...newAddress.address,
                                  address2: e.target.value,
                                },
                              })
                            }
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <div className="grid gap-2">
                            <Label htmlFor="barangay">Barangay</Label>
                            <Input
                              id="barangay"
                              value={newAddress.address.barangay}
                              onChange={(e) =>
                                setNewAddress({
                                  ...newAddress,
                                  address: {
                                    ...newAddress.address,
                                    barangay: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                          <div className="grid gap-2">
                            <Label htmlFor="zipcode">Zip Code</Label>
                            <Input
                              id="zipcode"
                              value={newAddress.address.zipcode}
                              onChange={(e) =>
                                setNewAddress({
                                  ...newAddress,
                                  address: {
                                    ...newAddress.address,
                                    zipcode: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="grid gap-2">
                          <Label htmlFor="city">City</Label>
                          <Input
                            id="city"
                            value={newAddress.address.city}
                            onChange={(e) =>
                              setNewAddress({
                                ...newAddress,
                                address: {
                                  ...newAddress.address,
                                  city: e.target.value,
                                },
                              })
                            }
                          />
                        </div>
                        <div className="grid gap-2">
                          <Label htmlFor="state">State/Province</Label>
                          <Input
                            id="state"
                            value={newAddress.address.state}
                            onChange={(e) =>
                              setNewAddress({
                                ...newAddress,
                                address: {
                                  ...newAddress.address,
                                  state: e.target.value,
                                },
                              })
                            }
                          />
                        </div>
                        <div className="grid gap-2">
                          <Label htmlFor="country">Country</Label>
                          <Input
                            id="country"
                            value={newAddress.address.country}
                            onChange={(e) =>
                              setNewAddress({
                                ...newAddress,
                                address: {
                                  ...newAddress.address,
                                  country: e.target.value,
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <DialogFooter>
                        <Button type="submit">Add Address</Button>
                      </DialogFooter>
                    </form>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Order Notes</CardTitle>
          </CardHeader>
          <CardContent>
            <Textarea
              placeholder="Add any special instructions or notes about your order here."
              value={orderNotes}
              onChange={(e) => setOrderNotes(e.target.value)}
            />
          </CardContent>
        </Card>
      </div>

      <div>
        <Card>
          <CardHeader>
            <CardTitle>Order Summary</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {order.orderEventTickets?.map((ticket, index) => (
                <div key={index} className="flex items-center gap-4">
                  <div className="flex-1">
                    <div className="font-medium">
                      {ticket.eventTicket?.name}
                    </div>
                    <div className="text-sm text-muted-foreground">
                      {ticket.eventTicket.description} - Section{" "}
                      {ticket.eventTicket.section}, Row {ticket.eventTicket.row}
                    </div>
                    <div className="text-sm text-muted-foreground">
                      {formatDate(
                        ticket.eventTicket.event.dateTime.toDateString()
                      )}{" "}
                      at {ticket.eventTicket.event.venue?.name}
                    </div>
                    <div className="text-sm">Quantity: {ticket.quantity}</div>
                  </div>
                  <div className="font-medium">
                    {formatCurrency(ticket.total)}
                  </div>
                </div>
              ))}
              {order.productOrder?.ProductOrderVariant.map((variant, index) => (
                <div key={index} className="flex items-center gap-4">
                  <div className="flex-1">
                    <div className="font-medium">
                      {variant.ProductVariant?.name}
                    </div>
                    <div className="text-sm text-muted-foreground">
                      Size: {variant.ProductVariant.size}, Color:{" "}
                      {variant.ProductVariant.color}
                    </div>
                    <div className="text-sm">Quantity: {variant.quantity}</div>
                  </div>
                  <div className="font-medium">
                    {formatCurrency(
                      variant.ProductVariant.price * variant.quantity
                    )}
                  </div>
                </div>
              ))}

              {order.dealOrders?.map((dealOrder, index) => (
                <div key={index} className="flex items-center gap-4">
                  <div className="flex-1">
                    <div className="font-medium">{dealOrder.deal.name}</div>
                    <div className="text-sm text-muted-foreground">
                      Valid: {formatDate(dealOrder.deal.startDate)} -{" "}
                      {formatDate(dealOrder.deal.endDate)}
                    </div>
                    <div className="text-sm">
                      Quantity: {dealOrder.quantity}
                    </div>
                  </div>
                  <div className="font-medium">
                    {formatCurrency(dealOrder.totalPrice)}
                  </div>
                </div>
              ))}
              <Separator />
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span>Subtotal</span>
                  <span>{formatCurrency(order.subTotal)}</span>
                </div>
                <div className="flex justify-between">
                  <span>Order Fee</span>
                  <span>{formatCurrency(order.orderFee)}</span>
                </div>
                {order.productOrder?.tax && (
                  <div className="flex justify-between">
                    <span>Tax</span>
                    <span>{formatCurrency(order.productOrder.tax)}</span>
                  </div>
                )}
                {order.voucher && (
                  <div className="flex justify-between text-green-600">
                    <span>Discount ({order.voucher.code})</span>
                    <span>-{formatCurrency(order.totalDiscount)}</span>
                  </div>
                )}
                <Separator />
                <div className="flex justify-between font-medium">
                  <span>Total</span>
                  <span>{formatCurrency(order.total)}</span>
                </div>
              </div>
              <Button className="w-full" size="lg">
                Place Order
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default CheckoutPage;
