import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SuccessPage from "../../../components/Success";
import PasswordResetSuccessLogo from "../../../assets/images/resetsuccesslogo.png";

const PasswordResetSuccess: React.FC = () => {
  const navigate = useNavigate();
  const handleRerouteClick = () => {
    navigate("/signin");
  };
  return (
    <SuccessPage
      title="NICE!"
      subtitle="Your password has been reset successfully!"
      subtitle2="You can login with your new password."
      buttonTitle="Sign in"
      handleClick={handleRerouteClick}
      image={PasswordResetSuccessLogo}
    />
  );
};

export default PasswordResetSuccess;
