import { ChevronDown, Download, Plus, Search } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";

const orderData = [
  {
    id: "#001451",
    date: "04/08/2020",
    eventName: "The Story of Danau Toba (Musical Drama)",
    customer: "Bella Simandjuntak",
    location: "London, UK",
    soldTicket: "1 Pcs",
    available: "56% left",
    refund: "No",
    totalRevenue: "$375.75",
  },
  {
    id: "#001452",
    date: "04/08/2020",
    eventName: "Kenduri Akustik Meetup 2020",
    customer: "Elizabeth Queen",
    location: "Medan, Indonesia",
    soldTicket: "4 Pcs",
    available: "63% left",
    refund: "No",
    totalRevenue: "$548.39",
  },
  {
    id: "#001453",
    date: "04/08/2020",
    eventName: "Great Big Fireworks on Ancol",
    customer: "David Beckham",
    location: "Jakarta, Indonesia",
    soldTicket: "2 Pcs",
    available: "22% left",
    refund: "Yes",
    totalRevenue: "$258.75",
  },
];

export default function PartnerOrders() {
  return (
    <div className="flex h-screen bg-gray-100">
      {/* Main content */}
      <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
        <div className="container px-6 py-8 mx-auto">
          <div className="mb-8">
            <Button className="text-white bg-red-500 hover:bg-red-600">
              <Plus className="w-4 h-4 mr-2" /> Create Order
            </Button>
          </div>

          <div className="overflow-hidden bg-white rounded-lg shadow-md">
            <div className="p-6 border-b border-gray-200">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold text-gray-800">
                  Order List
                </h2>
                <p className="text-sm text-gray-600">568 Total Orders</p>
              </div>
              <div className="flex mb-4 space-x-2">
                <Button
                  variant="outline"
                  className="text-white bg-red-500 border-red-500 hover:bg-red-600"
                >
                  All
                </Button>
                <Button variant="outline">Sold</Button>
                <Button variant="outline">Refunded</Button>
                <Button variant="outline">Canceled</Button>
              </div>
              <div className="flex items-center justify-between">
                <div className="relative">
                  <Search className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2" />
                  <Input
                    type="text"
                    placeholder="Search here..."
                    className="py-2 pl-10 pr-4 border rounded-md"
                  />
                </div>
                <div className="flex items-center">
                  <span className="mr-2 text-sm text-gray-600">Newest</span>
                  <ChevronDown className="w-4 h-4 text-gray-600" />
                </div>
                <Button variant="outline" className="flex items-center">
                  <Download className="w-4 h-4 mr-2" />
                  Generate Report
                </Button>
              </div>
            </div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Order ID</TableHead>
                  <TableHead>Date</TableHead>
                  <TableHead>Event Name</TableHead>
                  <TableHead>Customer</TableHead>
                  <TableHead>Location</TableHead>
                  <TableHead>Sold Ticket</TableHead>
                  <TableHead>Available</TableHead>
                  <TableHead>Refund</TableHead>
                  <TableHead>Total Revenue</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {orderData.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.id}</TableCell>
                    <TableCell>{order.date}</TableCell>
                    <TableCell>{order.eventName}</TableCell>
                    <TableCell>{order.customer}</TableCell>
                    <TableCell>{order.location}</TableCell>
                    <TableCell>{order.soldTicket}</TableCell>
                    <TableCell>{order.available}</TableCell>
                    <TableCell>{order.refund}</TableCell>
                    <TableCell>{order.totalRevenue}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </main>
    </div>
  );
}
