import { FC, useContext, useEffect, useState } from "react";
import { useMediaQuery as mediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Error from "../../components/Error";
import Loading from "../../components/Loading";
import { Modal } from "../../components/Modal";
import { useWindowSize } from "../../helpers/customHooks";
import { EWalletPaymentResponse } from "../../models/payment.model";
import {
  useInitEWalleyPaymentMutation,
  usePaypalInitMutation,
} from "../../services/payment.api";
import { setEWalletOption } from "./paymentSlice";
import { useAppSelector } from "../../app/hooks";
import { authSelect } from "../Authentication/authSlice";
import OrderContext from "../../pages/Order/context/OrderContext";

interface ComponentProps {
  orderId: string;
  acknowledgement?: boolean;
  amount: number;
  currency?: string;
  eventId?: string;
}

const useIsMobile = () => {
  const isMobile = mediaQuery({ maxWidth: 767 });
  return isMobile;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const EWallet: FC<ComponentProps> = ({
  orderId,
  amount,
  currency = "PHP",
  eventId,
  acknowledgement,
}) => {
  const searchParams = useQuery();
  const [payment, setPayment] = useState<string | null>(null);
  const { user } = useAppSelector(authSelect);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { event, order } = useContext(OrderContext);
  const [open, setOpen] = useState(false);
  const [width] = useWindowSize();
  const isMobile = useIsMobile();
  const [response, setResponse] = useState<EWalletPaymentResponse>();
  const [selectedEWalletPayment, setSelectedEWalletPayment] =
    useState<string>("");
  const [initEwalletPayment, { data, isSuccess, isLoading, isError }] =
    useInitEWalleyPaymentMutation();
  const [
    paypalInit,
    {
      data: paypalData,
      isLoading: paypalIsLoading,
      isSuccess: paypalIsSuccess,
    },
  ] = usePaypalInitMutation();

  useEffect(() => {
    setPayment(searchParams.get("payment"));
  }, [searchParams]);

  useEffect(() => {
    if (data && isSuccess) {
      // Determine the screen size and redirect accordingly
      const url = isMobile
        ? data.actions.mobile_web_checkout_url
        : data.actions.desktop_web_checkout_url;
      window.location.href = url;
    }
  }, [data, isSuccess, isMobile]);

  useEffect(() => {
    if (paypalData && paypalIsSuccess) {
      window.open(paypalData.link, "_self");
    }
  }, [paypalData, paypalIsLoading, paypalIsSuccess]);

  useEffect(() => {
    if (payment === "success") {
      window.fbq("track", "Purchase", {
        value: amount,
        currency: "PHP",
        em: user?.email ? user?.email.toLowerCase() : "",
        ln: user?.lastName ? user?.lastName.toLowerCase() : "",
        fn: user?.firstName ? user?.firstName.toLowerCase() : "",
      });
      navigate("success");
    }

    dispatch(setEWalletOption(selectedEWalletPayment));
  }, [
    response,
    navigate,
    selectedEWalletPayment,
    payment,
    dispatch,
    user,
    amount,
  ]);

  const handleOnClick = (eWallet: string) => {
    window.fbq("track", "InitiateCheckout", {
      em: user?.email ? user?.email.toLowerCase() : "",
      ln: user?.lastName ? user?.lastName.toLowerCase() : "",
      fn: user?.firstName ? user?.firstName.toLowerCase() : "",
    });

    setSelectedEWalletPayment(eWallet);

    if (eWallet === "PAYPAL") {
      paypalInit({
        amount,
        orderId: orderId.toString(),
        currency,
        eventSlug: eventId || "",
      });
    } else {
      initEwalletPayment({
        reference_id: orderId.toString(),
        currency: "PHP",
        amount,
        checkout_method: "ONE_TIME_PAYMENT",
        channel_code: eWallet,
        channel_properties: {
          failure_redirect_url: `${process.env.REACT_APP_BASE_URL}/event/${event?.slug}/order/${orderId}?payment=failed&payment_option=e-wallet`,
          success_redirect_url: `${process.env.REACT_APP_BASE_URL}/event/${event?.slug}/order/${orderId}?payment=success&payment_option=e-wallet`,
          cancel_redirect_url: `${process.env.REACT_APP_BASE_URL}/event/${event?.slug}/order/${orderId}?payment=cancel&payment_option=e-wallet`,
        },
      });
    }
  };

  if (isLoading || paypalIsLoading) return <Loading />;

  return (
    <div className="grid grid-cols-1 gap-5 p-0 mt-2 lg:mt-4 lg:p-2 lg:grid-cols-2">
      <div className="w-full col-span-2 my-6 grid md:grid-cols-2 gap-1">
        {acknowledgement ? (
          <>
            {currency === "PHP" && (
              <>
                <button
                  className="px-4 py-2 mx-0 text-white bg-blue-400 rounded-sm drop-shadow lg:mx-2"
                  onClick={() => handleOnClick("PH_GCASH")}
                >
                  Pay With GCASH
                </button>
                <button
                  className="px-4 py-2 mx-0 text-white bg-green-400 border rounded-sm drop-shadow lg:mx-2"
                  onClick={() => handleOnClick("PH_PAYMAYA")}
                >
                  Pay With PayMaya
                </button>
              </>
            )}

            <button
              className="px-4 py-2 mx-0 col-span-2 text-white bg-[#1F155F] border rounded-sm drop-shadow lg:mx-2"
              onClick={() => handleOnClick("PAYPAL")}
            >
              PayPal / Credit Card
            </button>
          </>
        ) : (
          <div className="text-red-500 ">
            Please acknowledge the terms and conditions to proceed.
          </div>
        )}
      </div>
    </div>
  );
};
