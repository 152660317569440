import React from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "../../components/Tabs";
import { ArtistList, GroupList } from "../../features/Artist";
import ArtistBanner from "../../features/Banner/ArtistBanner";
import Banner from "../../assets/images/artistbannerblue.png";
import { Helmet } from "react-helmet";

const ArtistPage: React.FC = () => {
  return (
    <div className="flex flex-col mt-1">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ticketnation Philippines | Artist</title>
        <link rel="canonical" href="https://ticketnation.ph/artist" />
      </Helmet>
      <Tabs>
        <TabList grid={2}>
          <Tab>
            <div className="p-1">
              <span className="text-2xl font-semibold lg:text-4xl">Solo</span>
            </div>
          </Tab>
          <Tab>
            <div className="p-1">
              <span className="text-2xl font-semibold lg:text-4xl">Group</span>
            </div>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <div className="pt-1">
              <ArtistBanner />
              <ArtistList />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="relative pt-1">
              <div
                className="h-[208px] lg:h-[468px]"
                style={{
                  background: `url(${Banner})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="flex items-center justify-center h-full ">
                  <div>
                    <div className="mt-16 text-xl font-semibold text-center text-white lg:text-2xl">
                      CHECK OUT YOUR FAVORITE GROUPS
                    </div>
                  </div>
                </div>
              </div>
              <GroupList />
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default ArtistPage;
