// OtpContext.tsx
import { createContext, useContext } from "react";
import { OtpContextType } from "./OrderContextTypes";

const OtpContext = createContext<OtpContextType | undefined>(undefined);

export default OtpContext;

export const useOtpContext = (): OtpContextType => {
  const context = useContext(OtpContext);
  if (!context) {
    throw new Error("useOtpContext must be used within an OtpProvider");
  }
  return context;
};
