import { Minus, Plus } from "lucide-react";
import { useEffect } from "react";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import {
  Deal,
  DealVariant,
  DealVariantAvailability,
} from "../../../models/deal.model";
import PackageOptionCalendar from "./PackageOptionCalendar";

export type FormValues = {
  selectedDates?: string[]; // Support multi-date selection
  quantities: { [key: string]: number };
};

interface PackageOptionsProps {
  deal?: Deal;
  control: Control<FormValues>;
  watch: UseFormWatch<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  selectedVariant?: DealVariant;
  selectedAvailability?: DealVariantAvailability;
  onSelectVariant: (variant: DealVariant) => void;
  onSelectAvailability: (
    availability: DealVariantAvailability | undefined
  ) => void;
}

export const PackageOptions: React.FC<PackageOptionsProps> = ({
  deal,
  control,
  watch,
  setValue,
  selectedVariant,
  selectedAvailability,
  onSelectVariant,
  onSelectAvailability,
}) => {
  const handleQuantityChange = (
    variantId: string,
    change: number,
    maxQuantity: number
  ) => {
    const quantities = watch("quantities");
    const currentQuantity = quantities[variantId] || 0;
    const newQuantity = Math.max(
      0,
      Math.min(maxQuantity, currentQuantity + change)
    );

    setValue("quantities", {
      ...quantities,
      [variantId]: newQuantity,
    });
  };

  useEffect(() => {
    if (selectedAvailability) {
      onSelectAvailability(undefined);
      setValue("selectedDates", undefined);
    }
  }, [selectedVariant, onSelectAvailability, setValue]);

  return (
    <Card className="mt-8">
      <CardHeader>
        <CardTitle>Package Options</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {deal?.dealVariants?.map((variant) => (
            <div key={variant.id}>
              <div
                className="flex items-center justify-between mb-2 cursor-pointer"
                onClick={() => onSelectVariant(variant)}
              >
                <span
                  className={`capitalize ${
                    selectedVariant?.id === variant.id ? "font-bold" : ""
                  }`}
                >
                  {variant.name}
                </span>

                {/* Quantity and Price Adjuster */}
                {selectedVariant?.id === variant.id && selectedAvailability && (
                  <div className="flex items-center mt-2 font-bold">
                    <span>{`Price: ₱${selectedAvailability.price}`}</span>
                    <div className="flex items-center ml-4">
                      <Button
                        type="button"
                        size="sm"
                        variant="outline"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleQuantityChange(
                            variant.id,
                            -1,
                            selectedAvailability.remaining
                          );
                        }}
                        disabled={(watch(`quantities.${variant.id}`) || 0) <= 0}
                      >
                        <Minus className="w-4 h-4" />
                      </Button>
                      <span className="mx-2">
                        {watch(`quantities.${variant.id}`) || 0}
                      </span>
                      <Button
                        type="button"
                        size="sm"
                        variant="outline"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleQuantityChange(
                            variant.id,
                            1,
                            selectedAvailability.remaining
                          );
                        }}
                        disabled={
                          (watch(`quantities.${variant.id}`) || 0) >=
                          selectedAvailability.remaining
                        }
                      >
                        <Plus className="w-4 h-4" />
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              {/* PackageOptionCalendar */}
              {selectedVariant?.id === variant.id && (
                <PackageOptionCalendar
                  control={control}
                  name="selectedDates"
                  onAvailabilityChange={onSelectAvailability}
                  availabilities={selectedVariant.availabilities}
                  multiSelect
                />
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default PackageOptions;
