import {
  ChevronLeft,
  Download,
  Edit,
  Search,
  Star,
  Ticket,
  Trash2,
} from "lucide-react";
import { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Avatar, AvatarFallback } from "../../../../components/ui/avatar";
import { Button } from "../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { Progress } from "../../../../components/ui/progress";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../../components/ui/tabs";

const salesData = [
  { name: "Jan", sales: 4000 },
  { name: "Feb", sales: 3000 },
  { name: "Mar", sales: 5000 },
  { name: "Apr", sales: 4500 },
  { name: "May", sales: 6000 },
  { name: "Jun", sales: 5500 },
];

interface TicketType {
  id: number;
  type: string;
  sold: number;
  available: number;
  price: number;
  description: string;
}

const initialTicketTypes: TicketType[] = [
  {
    id: 1,
    type: "VIP",
    sold: 150,
    available: 50,
    price: 200,
    description: "VIP access with exclusive perks",
  },
  {
    id: 2,
    type: "Regular",
    sold: 500,
    available: 300,
    price: 100,
    description: "Standard festival entry",
  },
  {
    id: 3,
    type: "Early Bird",
    sold: 300,
    available: 0,
    price: 80,
    description: "Discounted early purchase tickets",
  },
];

const customerFeedback = [
  {
    name: "John Doe",
    rating: 5,
    comment: "Amazing event! Can't wait for the next one.",
  },
  {
    name: "Jane Smith",
    rating: 4,
    comment: "Great organization, but the venue was a bit small.",
  },
];

const orders = [
  {
    id: "#001451",
    date: "04/08/2024",
    customer: "John Doe",
    event: "Summer Music Festival",
    tickets: 2,
    total: "$150.00",
    status: "Completed",
  },
  {
    id: "#001452",
    date: "04/09/2024",
    customer: "Jane Smith",
    event: "Tech Conference 2024",
    tickets: 1,
    total: "$75.00",
    status: "Pending",
  },
  {
    id: "#001453",
    date: "04/10/2024",
    customer: "Alice Johnson",
    event: "Food & Wine Expo",
    tickets: 3,
    total: "$225.00",
    status: "Completed",
  },
  {
    id: "#001454",
    date: "04/11/2024",
    customer: "Bob Brown",
    event: "Summer Music Festival",
    tickets: 4,
    total: "$300.00",
    status: "Cancelled",
  },
  {
    id: "#001455",
    date: "04/12/2024",
    customer: "Charlie Davis",
    event: "Tech Conference 2024",
    tickets: 2,
    total: "$150.00",
    status: "Completed",
  },
];

export default function PartnerEventDetails() {
  const [ticketTypes, setTicketTypes] =
    useState<TicketType[]>(initialTicketTypes);
  const [editingTicket, setEditingTicket] = useState<TicketType | null>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleEdit = (ticket: TicketType) => {
    setEditingTicket({ ...ticket });
  };

  const handleSave = () => {
    if (editingTicket) {
      setTicketTypes(
        ticketTypes.map((t) => (t.id === editingTicket.id ? editingTicket : t))
      );
      setEditingTicket(null);
    }
  };

  const handleDelete = (id: number) => {
    setTicketTypes(ticketTypes.filter((t) => t.id !== id));
  };

  return (
    <div className="container px-4 py-8 mx-auto">
      <div className="mb-6">
        <div className="flex items-center mb-2">
          <Button variant="ghost" className="mr-2">
            <ChevronLeft className="w-4 h-4 mr-2" />
            Back to Event List
          </Button>
          <nav className="text-sm breadcrumbs">
            <ul>
              <li>
                <a href="/events">Events</a>
              </li>
              <li>Summer Music Festival 2024</li>
            </ul>
          </nav>
        </div>
      </div>

      <Card className="mb-8">
        <CardContent className="p-6">
          <div className="flex items-start justify-between mb-6">
            <div>
              <h2 className="text-2xl font-semibold">
                Summer Music Festival 2024
              </h2>
              <p className="text-gray-600">
                July 15-17, 2024 | Central Park, New York City
              </p>
            </div>
            <div className="flex space-x-2">
              <Button variant="outline" className="flex items-center">
                <Edit className="w-4 h-4 mr-2" />
                Edit Event Details
              </Button>

              <Button variant="outline" className="flex items-center">
                <Ticket className="w-4 h-4 mr-2" />
                Manage Tickets
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
            <Card>
              <CardHeader className="flex flex-row items-center justify-between pb-2 space-y-0">
                <CardTitle className="text-sm font-medium">
                  Total Sales
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">$104,000</div>
                <p className="text-xs text-muted-foreground">
                  +20.1% from last month
                </p>
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="flex flex-row items-center justify-between pb-2 space-y-0">
                <CardTitle className="text-sm font-medium">
                  Tickets Sold
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">950 / 1300</div>
                <Progress value={73} className="mt-2" />
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="flex flex-row items-center justify-between pb-2 space-y-0">
                <CardTitle className="text-sm font-medium">
                  Average Rating
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">4.8 / 5</div>
                <div className="flex items-center mt-2">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <Star
                      key={star}
                      className={`h-4 w-4 ${
                        star <= 4
                          ? "text-yellow-400 fill-current"
                          : "text-gray-300"
                      }`}
                    />
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>
        </CardContent>
      </Card>

      <Tabs defaultValue="overview" className="space-y-4">
        <TabsList>
          <TabsTrigger value="overview">Overview</TabsTrigger>
          <TabsTrigger value="tickets">Tickets</TabsTrigger>
          <TabsTrigger value="orders">Orders</TabsTrigger>
          <TabsTrigger value="feedback">Feedback</TabsTrigger>
        </TabsList>
        <TabsContent value="overview">
          <Card>
            <CardHeader>
              <CardTitle>Sales Overview</CardTitle>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={salesData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="sales" fill="#FF6B6B" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="tickets">
          <div className="bg-white rounded-lg shadow-md ">
            <div className="p-6 border-b border-gray-200">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold">Tickets</h2>
                <p className="text-sm text-gray-600">
                  {ticketTypes.length} Total Tickets
                </p>
              </div>
              <div className="flex flex-col items-center justify-between gap-4 sm:flex-row">
                <div className="relative w-full sm:w-auto">
                  <Search className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2" />
                  <Input
                    type="text"
                    placeholder="Search tickets..."
                    className="w-full py-2 pl-10 pr-4"
                  />
                </div>
                <div className="flex items-center w-full gap-2 sm:w-auto">
                  <Select>
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Filter by status" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">All</SelectItem>
                      <SelectItem value="completed">Completed</SelectItem>
                      <SelectItem value="pending">Pending</SelectItem>
                      <SelectItem value="cancelled">Cancelled</SelectItem>
                    </SelectContent>
                  </Select>
                  <Button variant="outline" className="flex items-center">
                    <Download className="w-4 h-4 mr-2" />
                    Export
                  </Button>
                </div>
              </div>
            </div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Type</TableHead>
                  <TableHead>Sold</TableHead>
                  <TableHead>Available</TableHead>
                  <TableHead>Price</TableHead>
                  <TableHead>Revenue</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {ticketTypes.map((ticket) => (
                  <TableRow key={ticket.id}>
                    <TableCell>{ticket.type}</TableCell>
                    <TableCell>{ticket.sold}</TableCell>
                    <TableCell>{ticket.available}</TableCell>
                    <TableCell>${ticket.price}</TableCell>
                    <TableCell>
                      ${(ticket.sold * ticket.price).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <Dialog open={isOpen} onOpenChange={setOpen}>
                        <DialogTrigger asChild>
                          <Button
                            variant="outline"
                            size="sm"
                            className="mr-2"
                            onClick={() => {
                              handleEdit(ticket);
                              setOpen(true);
                            }}
                          >
                            <Edit className="w-4 h-4 mr-1" />
                            Edit
                          </Button>
                        </DialogTrigger>
                        <DialogContent>
                          <DialogHeader>
                            <DialogTitle>Edit Ticket Type</DialogTitle>
                          </DialogHeader>
                          {editingTicket && (
                            <div className="grid gap-4 py-4">
                              <div className="grid items-center grid-cols-4 gap-4">
                                <Label htmlFor="name" className="text-right">
                                  Name
                                </Label>
                                <Input
                                  id="name"
                                  value={editingTicket.type}
                                  onChange={(e) =>
                                    setEditingTicket({
                                      ...editingTicket,
                                      type: e.target.value,
                                    })
                                  }
                                  className="col-span-3"
                                />
                              </div>
                              <div className="grid items-center grid-cols-4 gap-4">
                                <Label htmlFor="price" className="text-right">
                                  Price
                                </Label>
                                <Input
                                  id="price"
                                  type="number"
                                  value={editingTicket.price}
                                  onChange={(e) =>
                                    setEditingTicket({
                                      ...editingTicket,
                                      price: Number(e.target.value),
                                    })
                                  }
                                  className="col-span-3"
                                />
                              </div>
                              <div className="grid items-center grid-cols-4 gap-4">
                                <Label
                                  htmlFor="available"
                                  className="text-right"
                                >
                                  Available
                                </Label>
                                <Input
                                  id="available"
                                  type="number"
                                  value={editingTicket.available}
                                  onChange={(e) =>
                                    setEditingTicket({
                                      ...editingTicket,
                                      available: Number(e.target.value),
                                    })
                                  }
                                  className="col-span-3"
                                />
                              </div>
                              <div className="grid items-center grid-cols-4 gap-4">
                                <Label
                                  htmlFor="description"
                                  className="text-right"
                                >
                                  Description
                                </Label>
                                <Input
                                  id="description"
                                  value={editingTicket.description}
                                  onChange={(e) =>
                                    setEditingTicket({
                                      ...editingTicket,
                                      description: e.target.value,
                                    })
                                  }
                                  className="col-span-3"
                                />
                              </div>
                            </div>
                          )}
                          <Button onClick={handleSave}>Save Changes</Button>
                        </DialogContent>
                      </Dialog>
                      <Button
                        variant="destructive"
                        size="sm"
                        onClick={() => handleDelete(ticket.id)}
                      >
                        <Trash2 className="w-4 h-4 mr-1" />
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </TabsContent>
        <TabsContent value="orders">
          <div className="overflow-hidden bg-white rounded-lg shadow-md">
            <div className="p-6 border-b border-gray-200">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold">Order List</h2>
                <p className="text-sm text-gray-600">
                  {orders.length} Total Orders
                </p>
              </div>
              <div className="flex flex-col items-center justify-between gap-4 sm:flex-row">
                <div className="relative w-full sm:w-auto">
                  <Search className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2" />
                  <Input
                    type="text"
                    placeholder="Search orders..."
                    className="w-full py-2 pl-10 pr-4"
                  />
                </div>
                <div className="flex items-center w-full gap-2 sm:w-auto">
                  <Select>
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Filter by status" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">All</SelectItem>
                      <SelectItem value="completed">Completed</SelectItem>
                      <SelectItem value="pending">Pending</SelectItem>
                      <SelectItem value="cancelled">Cancelled</SelectItem>
                    </SelectContent>
                  </Select>
                  <Button variant="outline" className="flex items-center">
                    <Download className="w-4 h-4 mr-2" />
                    Export
                  </Button>
                </div>
              </div>
            </div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Order ID</TableHead>
                  <TableHead>Date</TableHead>
                  <TableHead>Customer</TableHead>
                  <TableHead>Event</TableHead>
                  <TableHead>Tickets</TableHead>
                  <TableHead>Total</TableHead>
                  <TableHead>Status</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {orders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.id}</TableCell>
                    <TableCell>{order.date}</TableCell>
                    <TableCell>{order.customer}</TableCell>
                    <TableCell>{order.event}</TableCell>
                    <TableCell>{order.tickets}</TableCell>
                    <TableCell>{order.total}</TableCell>
                    <TableCell>
                      <span
                        className={`px-2 py-1 rounded-full text-xs font-semibold ${
                          order.status === "Completed"
                            ? "bg-green-100 text-green-800"
                            : order.status === "Pending"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-red-100 text-red-800"
                        }`}
                      >
                        {order.status}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </TabsContent>
        <TabsContent value="feedback">
          <Card>
            <CardHeader>
              <CardTitle>Customer Feedback</CardTitle>
            </CardHeader>
            <CardContent>
              {customerFeedback.map((feedback, index) => (
                <div
                  key={index}
                  className="pb-4 mb-4 border-b last:border-b-0 last:pb-0 last:mb-0"
                >
                  <div className="flex items-center mb-2">
                    <Avatar className="w-8 h-8 mr-2">
                      <AvatarFallback>{feedback.name[0]}</AvatarFallback>
                    </Avatar>
                    <div>
                      <p className="font-semibold">{feedback.name}</p>
                      <div className="flex items-center">
                        {[1, 2, 3, 4, 5].map((star) => (
                          <Star
                            key={star}
                            className={`h-4 w-4 ${
                              star <= feedback.rating
                                ? "text-yellow-400 fill-current"
                                : "text-gray-300"
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <p className="text-gray-600">{feedback.comment}</p>
                </div>
              ))}
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
}
