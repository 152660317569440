import React from "react";
import { Helmet } from "react-helmet";
import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";

type JobProps = {
  title: string;
  department: string;
  jobType: string;
  overview: string;
  responsibilities: string[];
  requirements: string[];
  perks: string[];
};

interface JobLandingProps {
  position: JobProps;
}

const JobLanding: React.FC<JobLandingProps> = ({
  position: {
    title,
    department,
    jobType,
    overview,
    responsibilities,
    requirements,
    perks,
  },
}) => {
  return (
    <>
      <Helmet>
        <title>{`${title} - TicketNation Careers`}</title>
        <meta
          name="description"
          content={`Join TicketNation as a ${title} and help create world-class experiences for our customers.`}
        />
      </Helmet>
      <div className="container px-4 py-8 mx-auto">
        <header className="mb-12 text-center">
          <h1 className="mb-4 text-4xl font-bold">{title}</h1>
          <p className="mb-2 text-xl">{department}</p>
          <p className="mb-4 text-lg">{jobType}</p>
          <p className="max-w-2xl mx-auto text-lg">
            Join us to create world-class experiences for our customers.
          </p>
        </header>

        <Card className="mb-8">
          <CardHeader>
            <CardTitle>Job Description</CardTitle>
          </CardHeader>
          <CardContent>
            <h2 className="mb-4 text-2xl font-semibold">Overview</h2>
            <p className="mb-6">{overview}</p>

            <h2 className="mb-4 text-2xl font-semibold">Responsibilities</h2>
            <ul className="pl-6 mb-6 list-disc">
              {responsibilities.map((responsibility, index) => (
                <li key={index} className="mb-2">
                  {responsibility}
                </li>
              ))}
            </ul>

            <h2 className="mb-4 text-2xl font-semibold">Requirements</h2>
            <ul className="pl-6 mb-6 list-disc">
              {requirements.map((requirement, index) => (
                <li key={index} className="mb-2">
                  {requirement}
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>

        <Card className="mb-8">
          <CardHeader>
            <CardTitle>Perks & Benefits</CardTitle>
          </CardHeader>
          <CardContent>
            <ul className="pl-6 list-disc">
              {perks.map((perk, index) => (
                <li key={index} className="mb-2">
                  {perk}
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>

        <div className="text-center">
          <h2 className="mb-6 text-3xl font-semibold">Ready to Apply?</h2>
          <div className="space-y-4">
            {/* <Button size="lg" asChild>
              <a href="#application-form">Apply Now</a>
            </Button> */}
            <div>
              <p className="mb-2">send your resume directly to:</p>
              <Button variant="outline" asChild>
                <a href="mailto:careers@ticketnation.ph">
                  careers@ticketnation.ph
                </a>
              </Button>
            </div>
          </div>
        </div>

        {/* Application Form - This would typically be a separate component */}
        {/* <Card className="mt-12" id="application-form">
          <CardHeader>
            <CardTitle>Application Form</CardTitle>
          </CardHeader>
          <CardContent>
            <form className="space-y-4">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="resume"
                  className="block text-sm font-medium text-gray-700"
                >
                  Resume
                </label>
                <input
                  type="file"
                  id="resume"
                  name="resume"
                  className="block w-full mt-1"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="coverLetter"
                  className="block text-sm font-medium text-gray-700"
                >
                  Cover Letter
                </label>
                <textarea
                  id="coverLetter"
                  name="coverLetter"
                  rows={4}
                  className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                ></textarea>
              </div>
              <Button type="submit" className="w-full">
                Submit Application
              </Button>
            </form>
          </CardContent>
        </Card> */}
      </div>
    </>
  );
};

export default JobLanding;
