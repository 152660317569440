import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { User } from "../../../models/user.model";

interface SecuritySettingsTabProps {
  user: User;
}
const SecuritySettingsTab: React.FC<SecuritySettingsTabProps> = ({ user }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Security Settings</CardTitle>
        <p>Manage your password and account security.</p>
      </CardHeader>
      <CardContent className="space-y-4">
        {user.passwordNotSet ? (
          <div className="space-y-2">
            <Label htmlFor="newPassword">Set New Password</Label>
            <Input id="newPassword" type="password" />
          </div>
        ) : (
          <>
            <div className="space-y-2">
              <Label htmlFor="currentPassword">Current Password</Label>
              <Input id="currentPassword" type="password" />
            </div>
            <div className="space-y-2">
              <Label htmlFor="newPassword">New Password</Label>
              <Input id="newPassword" type="password" />
            </div>
            <div className="space-y-2">
              <Label htmlFor="confirmPassword">Confirm New Password</Label>
              <Input id="confirmPassword" type="password" />
            </div>
          </>
        )}
      </CardContent>
      <CardFooter>
        <Button className="w-full md:w-auto">
          {user.passwordNotSet ? "Set Password" : "Change Password"}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default SecuritySettingsTab;
