import React from "react";
import { SuccessImageProps } from "../../interfaces/auth/Interface";

const Success: React.FC<SuccessImageProps> = ({
  title,
  subtitle,
  subtitle2,
  handleClick,
  buttonTitle,
  image,
}) => {
  return (
    <div className="flex items-center justify-center">
      <div className="w-full m-5 md:w-2/5 md:pt-10 md:m-0">
        <div className="text-center">
          <h2 className="text-4xl font-bold md:pb-5">{title}</h2>
          <p className="px-5 text-lg md:pb-5">{subtitle}</p>
          <p className="px-5 text-lg md:pb-5">{subtitle2}</p>
          <button
            onClick={handleClick}
            className="p-2 font-bold text-white uppercase rounded-sm bg-blue-1100 w-96"
          >
            {buttonTitle}
          </button>
        </div>
      </div>

      <div className="relative h-screen md:w-3/5 bg-blue-1100">
        <img
          className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          src={image}
          alt="signup-pic"
        />
      </div>
    </div>
  );
};
export default Success;
