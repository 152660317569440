import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Placeholder from "../../assets/images/placeholder.jpg";
import { Badge } from "../../components/ui/badge";
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Skeleton } from "../../components/ui/skeleton";
import { getLowestPriceDealVariant } from "../../lib/utils";
import { useGetDealsQuery } from "../../services/public.api";
const Deals = () => {
  const navigate = useNavigate();
  const [take, setTake] = useState(12);
  const { data, isLoading } = useGetDealsQuery({
    take,
  });

  // Fallback data for skeleton example or API response
  const deals = data?.data || [];

  return (
    <section className="py-4">
      <div className="container px-4 mx-auto">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
          {isLoading
            ? Array.from({ length: take }).map((_, index) => (
                <SkeletonCard key={index} />
              ))
            : deals.map((deal, index) => {
                const primaryImage = deal.dealImages?.find(
                  (image) => image.isPrimary
                );

                const image = primaryImage
                  ? process.env?.REACT_APP_IMAGE_BASE_URL +
                    primaryImage.image.fileName
                  : Placeholder;
                return (
                  <Card key={index}>
                    <img
                      src={image}
                      alt={deal.name}
                      className="object-cover w-full h-48"
                      onClick={() => navigate(`/deals/${deal.id}`)}
                    />
                    <CardHeader>
                      <CardTitle className="text-lg">{deal.name}</CardTitle>
                      <CardDescription>
                        {/* <div className="flex items-center">
                        <span className="text-yellow-400">★</span>
                        <span className="ml-1">{deal.rating}</span>
                        <span className="ml-2 text-muted-foreground">
                          ({deal.reviews} reviews)
                        </span>
                      </div> */}
                      </CardDescription>
                    </CardHeader>
                    <CardFooter className="flex items-center justify-between">
                      <span className="text-xl font-bold">
                        PHP{" "}
                        {getLowestPriceDealVariant(deal?.dealVariants || [])}
                      </span>
                      {deal.isFeatured && <Badge>Featured</Badge>}
                    </CardFooter>
                  </Card>
                );
              })}
        </div>
      </div>
    </section>
  );
};

// Skeleton Card for loading state
const SkeletonCard = () => (
  <Card>
    <Skeleton className="w-full h-48" />
    <CardHeader>
      <Skeleton className="w-3/4 h-6 mb-2" />
      <Skeleton className="w-1/2 h-4" />
    </CardHeader>
    <CardFooter className="flex items-center justify-between">
      <Skeleton className="w-20 h-6" />
      <Skeleton className="w-16 h-6" />
    </CardFooter>
  </Card>
);

export default Deals;
