import React from "react";
import NewTNLogo from "../../assets/images/NEW_TN_LOGO.png";

const Maintenance: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="w-full max-w-xs mx-auto lg:max-w-3xl">
        <img
          src={NewTNLogo}
          alt="New TN Logo"
          className="h-16 lg:h-auto lg:w-auto"
        />
        <h1 className="mt-5 text-3xl font-semibold lg:mt-10 lg:text-5xl">
          Ooops...
        </h1>
        <p className="mt-2 text-sm font-medium lg:mt-5 lg:text-2xl">
          Sorry for the inconvenience. <br /> Our website is currently down for
          maintenance.
        </p>
        <button
          className="px-10 py-3 lg:mt-20 mt-10 lg:text-2xl font-medium tracking-wider text-white bg-[#075395] rounded-md"
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default Maintenance;
