import React from "react";
import { Modal } from "../../../../../components/Modal";
import SelectedProduct from "./SelectedProduct";
import { Product } from "../../../../../models/product.model";

interface ComponentProps {
  product: Product;
}
const ProductCard: React.FC<ComponentProps> = ({ product }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <button className="w-full" onClick={() => setIsOpen(true)}>
        {product.image ? (
          <img
            className="object-cover w-full rounded-md shadow-md h-52"
            src={process.env.REACT_APP_IMAGE_BASE_URL + product.image.fileName}
            alt={product.name}
          />
        ) : (
          <div className="bg-gray-400 rounded-md h-52" />
        )}
        <h3 className="h-6 text-xs font-semibold md: text-md">{product.name}</h3>
      </button>
      <Modal
        title="Select Product"
        isEventInformation
        open={isOpen}
        setOpen={setIsOpen}
        fullHeight={true}
      >
        <SelectedProduct product={product} setIsOpen={setIsOpen} />
      </Modal>
    </>
  );
};

export default ProductCard;
