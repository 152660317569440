import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "src/components/ui/alert";
import { Badge } from "src/components/ui/badge";
import { CheckCircle, AlertTriangle, Construction } from "lucide-react";

export default function SellerApplication() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-200 flex items-center justify-center p-4">
      <Card className="w-full max-w-2xl">
        <CardHeader className="text-center">
          <CardTitle className="text-2xl font-bold">
            Seller Application
          </CardTitle>
          <CardDescription>
            Enhancing trust and security on our platform
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <Alert>
              <Construction className="h-4 w-4" />
              <AlertTitle>Application Process Under Construction</AlertTitle>
              <AlertDescription>
                We're currently building our seller application system to
                provide an even safer marketplace.
              </AlertDescription>
            </Alert>

            <div className="space-y-4">
              <h2 className="text-xl font-semibold">
                Current Seller Capabilities
              </h2>
              <ul className="list-disc pl-5 space-y-2">
                <li>List tickets for sale on our platform</li>
                <li>Make offers to potential buyers</li>
                <li>Engage in secure transactions</li>
              </ul>
            </div>

            <div className="space-y-4">
              <h2 className="text-xl font-semibold">
                Upcoming Seller Application Benefits
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Card>
                  <CardContent className="pt-6">
                    <CheckCircle className="h-8 w-8 text-green-500 mb-2" />
                    <h3 className="font-semibold">Verified Seller Badge</h3>
                    <p className="text-sm text-gray-600">
                      Stand out with a special badge on your listings
                    </p>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent className="pt-6">
                    <CheckCircle className="h-8 w-8 text-green-500 mb-2" />
                    <h3 className="font-semibold">Priority Support</h3>
                    <p className="text-sm text-gray-600">
                      Get faster assistance from our support team
                    </p>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent className="pt-6">
                    <CheckCircle className="h-8 w-8 text-green-500 mb-2" />
                    <h3 className="font-semibold">Higher Selling Limits</h3>
                    <p className="text-sm text-gray-600">
                      Unlock the ability to list more high-value tickets
                    </p>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent className="pt-6">
                    <CheckCircle className="h-8 w-8 text-green-500 mb-2" />
                    <h3 className="font-semibold">Faster Payouts</h3>
                    <p className="text-sm text-gray-600">
                      Enjoy quicker access to your earnings
                    </p>
                  </CardContent>
                </Card>
              </div>
            </div>

            <Alert variant="default">
              <AlertTriangle className="h-4 w-4" />
              <AlertTitle>Stay Tuned</AlertTitle>
              <AlertDescription>
                We'll notify all sellers when the application process is ready.
                Continue using the platform as usual in the meantime.
              </AlertDescription>
            </Alert>

            <div className="text-center">
              <Badge variant="outline" className="text-sm">
                Current Status: Unverified
              </Badge>
            </div>

            <p className="text-sm text-center text-gray-500">
              By using our platform, you agree to our Terms of Service and
              Privacy Policy.
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
