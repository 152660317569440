import { Field, Form, Formik } from "formik";
import React from "react";
import CustomInput from "../../components/CustomInput";
import * as yup from "yup";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import { useCreateArtistInquiryMutation } from "../../services/artist-inquiry.api";
import DatePicker from "../../components/DatePicker";

interface CreateArtistInquiryFormValues {
  name: string;
  email: string;
  contact: string;
  description?: string;
  targetDate: string;
  eventType: string;
  performerId: string;
}

interface ComponentProps {
  performerId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ArtistInquiry: React.FC<ComponentProps> = ({
  setOpen,
  performerId,
}) => {
  const [create, { isLoading, isError, isSuccess }] =
    useCreateArtistInquiryMutation();

  const createArtistInquiryValidationSchema = yup.object({
    name: yup.string().required("Name is required!"),
    email: yup.string().email().required("Email is required!"),
    contact: yup.string().required("Contact is required!"),
    targetDate: yup.string().required("Target date is required!"),
    eventType: yup.string().required("Event type name is required!"),
  });
  const createArtistInquiryInitialValues: CreateArtistInquiryFormValues = {
    name: "",
    email: "",
    description: "",
    contact: "",
    targetDate: "",
    eventType: "",
    performerId,
  };

  const handleSubmit = (values: CreateArtistInquiryFormValues) => {
    create(values)
      .unwrap()
      .then(() => {
        setOpen(false);
      })
      .catch(() => console.log("SOMETHING WENT WRONG"));
  };

  if (isLoading) return <Loading />;

  return (
    <Formik
      initialValues={createArtistInquiryInitialValues}
      validationSchema={createArtistInquiryValidationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <div className="flex flex-col items-center flex-grow p-4">
            <Field name="name" placeholder="Name" component={CustomInput} />
            <Field
              name="email"
              placeholder="Email Address"
              component={CustomInput}
            />
            <Field
              name="contact"
              component={CustomInput}
              placeholder="Mobile Number (09XXXXXXXXX)"
            />
            <Field
              name="eventType"
              component={CustomInput}
              placeholder="Event Type (Birthday, Concert, Others)"
            />
            <Field
              name="description"
              component={CustomInput}
              placeholder="Event Description (Optional)"
            />
            <DatePicker name="targetDate" />

            <div className="mt-4">
              <button
                type="submit"
                className="p-2 font-bold text-white rounded-sm bg-blue-1100 w-96"
              >
                INQUIRE
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
