import { Field, Form, Formik } from "formik";
import React from "react";
import CustomInput from "../../components/CustomInput";
import * as yup from "yup";
import { useCreateUserOrganizationMutation } from "../../services/user.api";
import Loading from "../../components/Loading";
import Error from "../../components/Error";

interface CreateOrganizationFormValues {
  organizationName: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
}

interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RegisterOrganization: React.FC<ComponentProps> = ({ setOpen }) => {
  const [createUserOrganization, { isLoading, isError, isSuccess }] =
    useCreateUserOrganizationMutation();

  React.useEffect(() => {
    if (isSuccess) setOpen(false);
  }, [isSuccess, setOpen]);

  const createOrganizationValidationSchema = yup.object({
    organizationName: yup.string().required("Organization name is required!"),
    email: yup.string().email().required("Email is required!"),
    password: yup.string().required("Password is required!"),
    firstName: yup.string().required("First name is required!"),
    lastName: yup.string().required("Last name is required!"),
    mobileNumber: yup.string().required("Mobile number is required!"),
  });
  const createOrganizationInitialValues: CreateOrganizationFormValues = {
    organizationName: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
  };

  const handleSubmit = (values: CreateOrganizationFormValues) => {
    createUserOrganization(values);
  };

  if (isLoading) return <Loading />;

  return (
    <Formik
      initialValues={createOrganizationInitialValues}
      validationSchema={createOrganizationValidationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <div className="flex flex-col items-center flex-grow p-4">
            <Field
              name="organizationName"
              placeholder="Organization Name"
              component={CustomInput}
            />
            <Field
              name="email"
              placeholder="Email Address"
              component={CustomInput}
            />

            <Field
              name="password"
              type="password"
              placeholder="Password"
              component={CustomInput}
            />

            <Field
              name="firstName"
              component={CustomInput}
              placeholder="First Name"
            />

            <Field
              name="lastName"
              component={CustomInput}
              placeholder="Last Name"
            />

            <Field
              name="mobileNumber"
              component={CustomInput}
              placeholder="Mobile Number (09XXXXXXXXX)"
            />

            <div className="mt-4">
              <button
                type="submit"
                className="p-2 font-bold text-white rounded-sm bg-blue-1100 w-96"
              >
                SIGN UP
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
