import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Phone from "../../assets/images/phone.png";
import Mail from "../../assets/images/mail.png";
import Address from "../../assets/images/address.png";
import Chat from "../../assets/images/chat.png";
import Fb from "../../assets/images/fb.png";
import Twitter from "../../assets/images/twitter.png";
import Instagram from "../../assets/images/instagram.png";
import { useCreateInquiryMutation } from "../../services/inquiry-service";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  interest: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
});

const ContactUs = () => {
  const [submit, { data, isLoading }] = useCreateInquiryMutation();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      interest: "",
      phone: "",
      message: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await submit(values).unwrap();
        // Display success message or handle it accordingly.
        toast.success("Inquiry submitted successfully!", {
          autoClose: 3000,
        });
        // Reset the form
        formik.resetForm();
      } catch (error) {
        console.log("Submission failed:", error);
      }
    },
  });

  return (
    <div className="mt-16">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ticketnation Philippines | Contact Us</title>
        <link rel="canonical" href="https://ticketnation.ph/about-us" />
      </Helmet>
      <div className="max-w-6xl mx-auto bg-white shadow-lg rounded-3xl">
        <div className="flex flex-col px-12 py-12 lg:flex-row">
          <div className="lg:w-1/2">
            <h1 className="text-4xl font-extrabold">Get in touch</h1>
            <p className="my-4 text-2xl font-semibold">Send a message</p>

            <form onSubmit={formik.handleSubmit}>
              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <div>
                  <p className="font-semibold">Name</p>
                  <input
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    className={`w-full border-b-2 border-gray-300 outline-none input focus:border-black ${
                      formik.touched.name && formik.errors.name
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-red-500">{formik.errors.name}</div>
                  ) : null}
                </div>
                <div>
                  <p className="font-semibold">Email Address</p>
                  <input
                    type="text"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className={`w-full border-b-2 border-gray-300 outline-none input focus:border-black ${
                      formik.touched.email && formik.errors.email
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-500">{formik.errors.email}</div>
                  ) : null}
                </div>
                <div>
                  <p className="font-semibold">Interested in</p>
                  <select
                    name="interest"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.interest}
                    className={`w-full border-b-2 border-gray-300 outline-none select focus:border-black ${
                      formik.touched.interest && formik.errors.interest
                        ? "border-red-500"
                        : ""
                    }`}
                  >
                    <option value="">Select</option>{" "}
                    {/* set default value to an empty string */}
                    <option>Event Selling</option>
                    <option>Customer Support</option>
                    <option>Inquiry</option>
                  </select>
                  {formik.touched.interest && formik.errors.interest ? (
                    <div className="text-red-500">{formik.errors.interest}</div>
                  ) : null}
                </div>
                <div>
                  <p className="font-semibold">Phone Number</p>
                  <input
                    type="text"
                    name="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    className={`w-full border-b-2 border-gray-300 outline-none input focus:border-black ${
                      formik.touched.phone && formik.errors.phone
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-red-500">{formik.errors.phone}</div>
                  ) : null}
                </div>
              </div>
              <div className="mt-8">
                <p className="font-semibold">Message</p>
                <textarea
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  className={`w-full h-48 border-b-2 border-gray-300 outline-none resize-none focus:border-black ${
                    formik.touched.message && formik.errors.message
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.message && formik.errors.message ? (
                  <div className="text-red-500">{formik.errors.message}</div>
                ) : null}
              </div>
              <button
                type="submit"
                className="w-full mt-4 rounded-md btn bg-blue-1100"
              >
                Send
              </button>
            </form>
          </div>

          <div className="flex flex-col items-center justify-center lg:w-1/2 ">
            {/* <h1 className="mt-24 text-xl font-bold">
              <div className="flex space-x-2">
                <div>
                  <img src={Phone} alt="phone" />
                </div>
                <div>Call Us</div>
              </div>
            </h1>
            <p className="font-bold text-blue-900">0999 942 5510</p> */}

            <h1 className="mt-16 text-xl font-bold">
              <div className="flex space-x-2">
                <div>
                  <img src={Mail} alt="Mail" />
                </div>
                <div>Email Us</div>
              </div>
            </h1>
            <p className="font-bold text-blue-900">corporate@ticketnation.ph</p>

            <h1 className="mt-16 text-xl font-bold">
              <div className="flex space-x-2">
                <div>
                  <img src={Address} alt="Address" />
                </div>
                <div>Address</div>
              </div>
            </h1>
            <p className="px-6 font-bold text-center text-blue-900">
              2ND FLOOR ZETA BUILDING #25D, ZETA BUILDING, 191, SALCEDO ST,
              LEGASPI VILLAGE, SAN LORENZO, Makati, Philippines
            </p>

            <h1 className="mt-6 text-xl font-bold">
              <div className="flex space-x-2">
                <div>
                  <img src={Chat} alt="Chat" />
                </div>
                <div>Live Chat</div>
              </div>
            </h1>
            <p className="font-bold text-blue-900 ">
              https://www.facebook.com/ticketnationphofficial
            </p>
          </div>
        </div>

        <div className="flex items-center justify-center pb-32 space-x-4">
          <div>
            <img src={Fb} alt="Fb" />
          </div>
          <div>
            <img src={Twitter} alt="Twitter" />
          </div>
          <div>
            <img src={Instagram} alt="Instagram" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
