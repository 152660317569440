export const handleXenditInitiation = () => {
  window.Xendit.setPublishableKey(
    process.env.REACT_APP_XENDIT_PUBLIC_KEY || ""
  );
};

type XenditHandleVerification = (
  type: string,
  value: string,
  value2?: string
) => boolean;

export const handleXenditVerification: XenditHandleVerification = (
  type,
  value,
  value2 = ""
) => {
  if (type === "card_number") {
    if (!window.Xendit.card.validateCardNumber(value)) {
      return false
    }
  } else if (type === "expiry_date") {
    if (!window.Xendit.card.validateExpiry(value, value2)) {
      return false
    }
  } else if (type === "cvn_number") {
    if (!window.Xendit.card.validateCvn(value)) {
      return false
    }
  }

  return true;
};
