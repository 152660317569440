import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface Attributes {
  amount: string;
  billing: null;
  created_at: number;
  currency: string;
  description: string;
  livemode: boolean;
  redirect: {
    checkout_url: string;
    success: string;
    failed: string;
  };
  statement_descriptor: null;
  status: string;
  type: string;
  updated_at: number;
}
export interface Payment {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface PaymentState {
  id: string;
  data: Object;
  eWalletOption: string;
}

const initialState: PaymentState = {
  data: {},
  id: "",
  eWalletOption: "",
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    redirectPayment: (state, action) => {
      state.data = action.payload.data.data;
      state.id = action.payload.data.data.id;
    },
    setEWalletOption: (state, action) => {
      state.eWalletOption = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { redirectPayment, setEWalletOption } = paymentSlice.actions;
export const paymentSelect = (state: RootState) => state.payment;

export default paymentSlice.reducer;
