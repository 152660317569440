import { useParams } from "react-router-dom";
import jobPositions from "../careers.json";
import JobLanding from "./JobLandingPage";

const SelectedCareerPage = () => {
  const { jobId } = useParams<{ jobId: string }>();

  const position = jobPositions.find((position) => position.id === jobId);
  if (!position) return null;
  return <JobLanding position={position} />;
};

export default SelectedCareerPage;
