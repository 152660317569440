import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import Loading from "../../../components/Loading";
import { Modal } from "../../../components/Modal";
import logger from "../../../lib/logger";
import { EventTicket } from "../../../models/event.model";
import { CreateOrder } from "../../../models/order.model";
import { Voucher } from "../../../models/voucher.model";
import {
  CustomError,
  useCreateOrderMutation,
} from "../../../services/order.api";
import { AuthModal } from "../../Authentication";
import { authSelect } from "../../Authentication/authSlice";
import { VoucherValidate } from "../../Voucher";
import { EventPaymentSummary } from "./EventPaymentSummary";
import {
  SelectedEventPayload,
  selectedEventSelect,
  setCurrency,
  setOrderFee,
  setSelectedPayload,
  setVoucher,
} from "./eventSlice";
import { EventTicketRow } from "./EventTicketRow";

interface ComponentProps {
  eventTickets: EventTicket[];
  id: string;
  orderFee: number;
  currency: string;
  dateDropDown: any;
}

export const EventTickets: React.FC<ComponentProps> = ({
  dateDropDown,
  eventTickets,
  id,
  orderFee,
  currency,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useAppSelector(authSelect);
  const { payload, voucher, selectedEventId } =
    useAppSelector(selectedEventSelect);
  const [open, setOpen] = React.useState(false);
  const [isErrorOpen, setIsErrorOpen] = React.useState(false);
  const [createOrder, { data, isSuccess, isLoading, isError, error }] =
    useCreateOrderMutation();

  React.useEffect(() => {
    dispatch(setOrderFee(orderFee));
    logger.info("Order fee updated", { orderFee });
  }, [orderFee, dispatch]);

  React.useEffect(() => {
    if (isSuccess && data) {
      logger.info("Order creation successful", { orderId: data.id });
      if (dateDropDown) {
        navigate(`/event/${id}/order/${data.id}/shop`);
      } else {
        navigate(`order/${data.id}/shop`);
      }
    }
    return () => {
      logger.info("Clearing selected payload");
      dispatch(setSelectedPayload([]));
    };
  }, [isSuccess, data, navigate, dispatch, dateDropDown, selectedEventId]);

  React.useEffect(() => {
    if (isError) {
      const customError = error as CustomError;
      logger.error("Order creation failed", {
        status: customError.status,
        message: customError.data.message,
      });
      if (customError.status === 400) {
        setIsErrorOpen(true);
      }
    }
  }, [isError, error]);

  React.useEffect(() => {
    const newPayload = payload.map((data) => {
      const discount = (voucher && voucher.discountPercentage / 100) || 0;
      const totalDiscountPrice = Math.floor(
        parseInt(data.price.toString()) * discount
      );
      const getTotalPrice = Math.floor(
        parseInt(data.price.toString()) - totalDiscountPrice
      );

      return {
        ...data,
        total: getTotalPrice * data.quantity,
      };
    });
    logger.info("Payload updated with discounts", { payload: newPayload });
    dispatch(setSelectedPayload(newPayload));
  }, [voucher, dispatch]);

  const handleChange = (value: any) => {
    const index = payload.findIndex(
      (payload: SelectedEventPayload) => payload.id === value.id
    );
    dispatch(setCurrency(currency));
    logger.info("Currency updated", { currency });

    if (index !== -1) {
      const newPayload = payload.map((data, i) => {
        if (index === i) {
          return value;
        }
        return data;
      });

      logger.info("Payload updated", { payload: newPayload });
      dispatch(setSelectedPayload(newPayload));
    } else {
      const updatedPayload = [...payload, value];
      logger.info("New item added to payload", { payload: updatedPayload });
      dispatch(setSelectedPayload(updatedPayload));
    }
  };

  const handleProceedCheckout = () => {
    logger.info("Proceeding to checkout", { user });
    if (user === null) {
      logger.warn("User not logged in, showing AuthModal");
      setOpen(true);
    } else {
      const orderDatas = payload
        .filter((o) => o.quantity > 0)
        .map((o) => {
          const orders = {
            eventTicketId: o.id,
            quantity: o.quantity,
          };

          return orders;
        });

      if (orderDatas.length > 0) {
        const data: CreateOrder = { orders: orderDatas, eventId: id };
        if (voucher) {
          data.code = voucher.code;
        }
        logger.info("Creating order", { orderData: data });
        createOrder(data);
      } else {
        logger.warn("No items in cart to proceed with checkout");
      }
    }
  };

  if (isLoading) {
    logger.info("Loading state active");
    return <Loading />;
  }

  let sort = [...eventTickets];
  return (
    <div className="px-2 lg:p-0">
      <div className="flex flex-col justify-center mb-2 lg:mb-6">
        <div className="w-full bg-white rounded-sm drop-shadow">
          <div className="grid grid-cols-2 py-2 font-semibold text-white bg-blue-1100 rounded-tl-md rounded-tr-md">
            <div className="flex justify-between col-span-2 px-2 lg:col-span-1">
              <span>TICKET GROUP</span>
              <span>QUANTITY</span>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="lg:border-r">
              {sort
                .sort((a, b) => {
                  return parseInt(a.price) - parseInt(b.price);
                })
                .map((eventTicket, index) => (
                  <EventTicketRow
                    currency={currency}
                    discountPercentage={voucher?.discountPercentage || 0}
                    key={index}
                    payload={payload.find((e) => e.id === eventTicket.id)}
                    eventTicket={eventTicket}
                    onChange={handleChange}
                  />
                ))}
            </div>
            <div className="px-2 py-0 lg:py-2">
              <div className="flex items-center py-1">
                <span className="text-md">
                  <b>(OPTIONAL).</b>
                </span>
                <span className="pl-1 text-sm ">
                  We'll keep you posted if there are any vouchers for events.
                </span>
              </div>
              <VoucherValidate
                eventId={id}
                setVoucher={(value: Voucher | undefined) =>
                  dispatch(setVoucher(value))
                }
              />
              <EventPaymentSummary />
            </div>
          </div>
        </div>

        <button
          className="p-2 mt-2 font-semibold text-white uppercase border rounded-sm drop-shadow bg-blue-1100 hover:border-blue-1100 hover:bg-white hover:text-blue-1100"
          onClick={() => handleProceedCheckout()}
        >
          Proceed to Checkout
        </button>
      </div>
      <AuthModal open={open} setOpen={setOpen} />
      {isError && (
        <Modal
          open={isErrorOpen}
          setOpen={setIsErrorOpen}
          title="Pending Order Limit Reached"
        >
          <div className="p-5">
            <p className="text-xl">{(error as CustomError).data.message}</p>
          </div>
        </Modal>
      )}
    </div>
  );
};
