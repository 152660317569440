import { ErrorMessage, Field, FieldProps } from "formik";
import React from "react";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface ComponentProps {
  name?: string;
  minDate?: Date;
}

export const DatePicker: React.FC<ComponentProps> = ({
  name = "dateTime",
  minDate,
}) => {
  return (
    <div className="mt-4 w-96">
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <Datepicker
            {...field}
            selected={field.value}
            onChange={(date: Date) => form.setFieldValue(field.name, date)}
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeSelect
            className={`${
              form.touched[field.name] && form.errors[field.name]
                ? " w-full h-10 p-2 text-sm drop-shadow ring ring-offset-0 ring-red-500 rounded focus:outline-none"
                : " w-full h-10 p-2 text-sm drop-shadow outline-none ring ring-offset-0 rounded ring-gray-100 focus:outline-none"
            }`}
            placeholderText="Date and Time"
            popperClassName="z-100"
            minDate={minDate}
          />
        )}
      </Field>
      <ErrorMessage name={name}>
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default DatePicker;
