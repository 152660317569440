import { Field, Form, Formik } from "formik";
import { Link } from "react-router-dom";
import CustomInput from "../../../components/CustomInput";
import { signinInitialValues } from "../../../formik/auth/InitialValues";
import { signinValidationSchema } from "../../../formik/auth/ValidationSchema";
import { SignInFormValues } from "../../../interfaces/auth/Interface";
import { CustomError } from "../../../services/auth.api";
import SignInFacebook from "./SignInFacebook";
import SignInGoogle from "./SignInGoogle";

const SignIn: React.FC<SignInFormValues> = ({
  isError,
  error,
  signInhandleSubmit,
  isModal,
  setSignIn,
}) => {
  return (
    <Formik
      initialValues={signinInitialValues}
      validationSchema={signinValidationSchema}
      onSubmit={signInhandleSubmit}
    >
      {() => (
        <Form>
          <div>
            {isModal && setSignIn ? (
              <div className="flex gap-1 mb-2 text-sm text-center lg:mb-4">
                <span>New to Ticketnation?</span>
                <span
                  className="font-bold cursor-pointer text-blue-1100"
                  onClick={() => setSignIn(false)}
                >
                  Sign Up
                </span>
              </div>
            ) : (
              <div className="mb-2 text-3xl font-bold text-center lg:mb-6">
                SIGN IN
              </div>
            )}

            <Field
              name="email"
              placeholder="Email Address"
              component={CustomInput}
            />

            <Field
              name="password"
              type="password"
              placeholder="Password"
              component={CustomInput}
            />
            <div className="text-center text-red-500">
              {isError && (error as CustomError).data.message}
            </div>
            <Link to="/forgot-password">
              <div className="flex justify-center mt-4">Forgot Password?</div>
            </Link>
            <div className="mt-4">
              <button
                type="submit"
                className="p-2 font-bold text-white uppercase rounded-sm bg-blue-1100 w-96"
              >
                Sign in
              </button>
            </div>
            {!isModal ? (
              <div className="flex justify-center mt-4">
                <div className="mr-2">Don't have an account yet?</div>
                <Link to="/signup">
                  <div className="underline">Sign Up</div>
                </Link>
              </div>
            ) : null}
            <div className="grid grid-cols-1 gap-2 py-2 mt-4 border-t border-1">
              <p className="col-span-1 text-center">or sign in with</p>
              <SignInGoogle />
              {/* TODO: remove if Facebook login is fixed */}
              <SignInFacebook />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignIn;
