import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import Loading from "../../components/Loading";
import { setUser } from "../../features/Authentication/authSlice";
import { useManualRefreshMutation } from "../../services/auth.api";

export const AuthCallbackPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  let [searchParams] = useSearchParams();
  const refrechToken = searchParams.get("refresh");
  const [refresh, { data, isSuccess, isLoading, isError }] =
    useManualRefreshMutation();
  React.useEffect(() => {
    refresh(refrechToken);
  }, [refresh, refrechToken]);

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      dispatch(
        setUser({
          refreshToken: data.refreshToken,
          accessToken: data.accessToken,
        })
      );
      localStorage.setItem("refreshToken", data.refreshToken);
    }
  }, [isSuccess, isLoading, data, dispatch]);

  if (isLoading) {
    return <Loading />;
  }

  if(isSuccess){
    navigate('/')
  }
  return null
};
