import React, { useContext, useEffect } from "react";
import BilleaseLogo from "../../assets/images/billease-logo.png";
import {
  CustomError,
  usePaylaterPaymentMutation,
} from "../../services/payment.api";
import Loading from "../../components/Loading";
import { useAppSelector } from "../../app/hooks";
import { authSelect } from "../Authentication/authSlice";
import MediaQuery from "react-responsive";
import OrderContext from "../../pages/Order/context/OrderContext";

interface ComponentProps {
  orderId: string;
  amount: number;
}

const useIsMobile = () => {
  const isMobile = MediaQuery({ maxWidth: 767 });
  return isMobile;
};

const Paylater: React.FC<ComponentProps> = ({ orderId, amount }) => {
  const { user } = useAppSelector(authSelect);
  const isMobile = useIsMobile();
  const { event } = useContext(OrderContext);
  const [paylaterPayment, { isError, isLoading, data, error, isSuccess }] =
    usePaylaterPaymentMutation();

  const customError = error as CustomError;

  useEffect(() => {
    if (data && data?.status === "PENDING" && isSuccess) {
      const url = isMobile ? data?.mobileUrl : data?.desktopUrl;
      window.location.href = url;
    }
  }, [data, isSuccess, isMobile]);

  if (isLoading) {
    return <Loading />;
  }

  const handleClick = (channelCode: "PH_BILLEASE") => {
    window.fbq("track", "InitiateCheckout", {
      em: user?.email ? user?.email.toLowerCase() : "",
      ln: user?.lastName ? user?.lastName.toLowerCase() : "",
      fn: user?.firstName ? user?.firstName.toLowerCase() : "",
    });

    const successRedirectUrl = `${process.env.REACT_APP_BASE_URL}/event/${event?.slug}/order/${orderId}/?payment=success&payment_option=paylater`;
    const failureRedirectUrl = `${process.env.REACT_APP_BASE_URL}/event/${event?.slug}/order/${orderId}/?payment=failed&payment_option=paylater`;

    paylaterPayment({
      amount: amount,
      channelCode,
      orderId: orderId,
      failure_redirect_url: failureRedirectUrl,
      success_redirect_url: successRedirectUrl,
    });
  };

  return (
    <div>
      {isError && (
        <div className="my-5 text-red-500">{customError.data.message}</div>
      )}
      <div className="flex justify-center gap-3 pt-4">
        <button
          onClick={() => handleClick("PH_BILLEASE")}
          className="px-3 py-1 border rounded-md"
        >
          <div
            className="px-5 py-3 bg-center bg-no-repeat bg-contain "
            style={{ backgroundImage: `url(${BilleaseLogo})` }}
          >
            <div className="w-8 h-6" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Paylater;
