import Placeholder from "../../../assets/images/placeholder.jpg";
import { AspectRatio } from "../../../components/ui/aspect-ratio";
import { Skeleton } from "../../../components/ui/skeleton";

interface DealImage {
  isPrimary: boolean;
  image: {
    fileName: string;
  };
}

interface DealImageGalleryProps {
  deal?: {
    name?: string;
    dealImages?: DealImage[];
  };
  isLoading: boolean;
}

export const DealImageGallery: React.FC<DealImageGalleryProps> = ({
  deal,
  isLoading,
}) => {
  const primaryImage = deal?.dealImages?.find((image) => image.isPrimary);
  const image = primaryImage
    ? process.env?.REACT_APP_IMAGE_BASE_URL + primaryImage.image.fileName
    : Placeholder;
  const remainingImages = deal?.dealImages?.filter((image) => !image.isPrimary);

  return (
    <>
      <AspectRatio ratio={16 / 9} className="bg-muted">
        {isLoading ? (
          <Skeleton className="w-full h-full" />
        ) : (
          <img
            src={image}
            alt={deal?.name || "Deal Image"}
            className="object-contain w-full h-full rounded-md"
          />
        )}
      </AspectRatio>
      <div className="grid grid-cols-5 gap-2 mt-2">
        {isLoading
          ? [...Array(5)].map((_, i) => (
              <Skeleton key={i} className="w-full h-20" />
            ))
          : remainingImages?.map((img, i) => (
              <AspectRatio key={i} ratio={1}>
                <img
                  src={`${process.env?.REACT_APP_IMAGE_BASE_URL}/${img.image.fileName}`}
                  alt={`Thumbnail ${i + 1}`}
                  className="object-cover rounded-md"
                />
              </AspectRatio>
            ))}
      </div>
    </>
  );
};

export default DealImageGallery;
