import React from "react";
import SocialForm from "./SocialForm";
import {
    useGetSocialProfileQuery,
    useCreateSocialProfileMutation,
    useUpdateSocialProfileMutation,
} from "@/services/user.api";
import {SocialProfileLink} from "@/models/social-profile.model";

const SocialProfilePage: React.FC = () => {
    const { data: socialProfile, isLoading } = useGetSocialProfileQuery();
    const [createSocialProfile, {
        isLoading: isCreating
    }] = useCreateSocialProfileMutation();
    const [updateSocialProfile, {
        isLoading: isUpdating
    }] = useUpdateSocialProfileMutation();

    const defaultValues = {
        fullName: socialProfile?.fullName || "",
        company: socialProfile?.company || "",
        position: socialProfile?.position || "",
        links: socialProfile?.links ? JSON.parse(socialProfile?.links) as SocialProfileLink[] : []
    };

    const handleSubmit = async (data: any) => {
        if (socialProfile) {
            await updateSocialProfile(data);
        } else {
            await createSocialProfile(data);
        }
    };



    if (isLoading || isCreating || isUpdating ){
        return <div>Loading...</div>
    }

    return (
        <div className="mt-8">
            <SocialForm defaultValues={defaultValues} onSubmit={handleSubmit} />
        </div>
    );
};

export default SocialProfilePage;



