import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { ScrollArea, ScrollBar } from "../../../components/ui/scroll-area";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";

export const DealTabs = () => (
  <Tabs defaultValue="product-info" className="mt-8">
    <ScrollArea className="w-full border rounded-md whitespace-nowrap">
      <TabsList className="inline-flex h-auto p-1 w-max">
        <TabsTrigger value="product-info" className="px-4 py-2">
          Product Info
        </TabsTrigger>
        <TabsTrigger value="how-to-use" className="px-4 py-2">
          How to Use
        </TabsTrigger>
        <TabsTrigger value="cancellation" className="px-4 py-2">
          Cancellation
        </TabsTrigger>
        <TabsTrigger value="reviews" className="px-4 py-2">
          Reviews
        </TabsTrigger>
      </TabsList>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
    <TabsContent value="product-info">
      <Card>
        <CardHeader>
          <CardTitle>What You Can Expect</CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="pl-5 space-y-2 list-disc">
            <li>
              Experience the dinosaur era with life-size animatronic dinosaurs.
            </li>
            <li>
              Enjoy dino-stories, meet a Tyrannosaurus Rex, and other species.
            </li>
            <li>
              Have fun at the first-ever animatronic dinosaur theme park in the
              Philippines!
            </li>
          </ul>
        </CardContent>
      </Card>
    </TabsContent>
    <TabsContent value="how-to-use">
      <Card>
        <CardHeader>
          <CardTitle>How to Redeem Your Voucher</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Please present your QR code on-site</p>
          <p className="mt-4 font-semibold">Voucher Redemption Period</p>
          <p>
            The voucher must be redeemed on the selected date within operating
            hours.
          </p>
        </CardContent>
      </Card>
    </TabsContent>
    <TabsContent value="cancellation">
      <Card>
        <CardHeader>
          <CardTitle>Cancellation Policy</CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="pl-5 space-y-2 list-disc">
            <li>
              Cancellations 1 day(s) before the selected date are allowed.
            </li>
            <li>
              Cancellations within 0-1 days are not permitted, and refunds won’t
              be granted.
            </li>
          </ul>
        </CardContent>
      </Card>
    </TabsContent>
    <TabsContent value="reviews">
      <Card>
        <CardHeader>
          <CardTitle>Customer Reviews</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center mb-4">
            <span className="mr-2 text-3xl font-bold">5.0</span>
            <span className="text-yellow-400">★★★★★</span>
            <span className="ml-2 text-sm text-muted-foreground">
              (16 reviews)
            </span>
          </div>
        </CardContent>
      </Card>
    </TabsContent>
  </Tabs>
);

export default DealTabs;
