import React, { useEffect, useState } from "react";

import Business from "./Business";
import OurProducts from "./OurProducts";
import ContactUs from "./ContactUs";
import WhyTicketnation from "./WhyTicketnation";
import { useLocation, useNavigate } from "react-router";
import Error404Page from "../Error404";

const MarketingContents = () => {
  const [tablist, setTablist] = useState("Why Ticketnation");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const whyTicketnation = "Why Ticketnation";
    const business = "Business";
    const ourProducts = "Our Products";
    const contactUS = "Contact Us";
    const currentLoc = location.search.substring(5);
    const replacedCurrentLoc = currentLoc.replace(/%20/g, " ");

    if (whyTicketnation === replacedCurrentLoc) {
      setTablist(replacedCurrentLoc);
    } else if (business === replacedCurrentLoc) {
      setTablist(replacedCurrentLoc);
    } else if (ourProducts === replacedCurrentLoc) {
      setTablist(replacedCurrentLoc);
    } else if (contactUS === replacedCurrentLoc) {
      setTablist(replacedCurrentLoc);
    } else {
      return;
    }
  }, [navigate, location]);

  const handleTab = (tab: string) => {
    setTablist(tab);
    navigate(`/about-us?tab=${tab}`);
  };

  return (
    <>
      <div className="pb-20 bg-gray-100">
        <div className="flex items-center justify-center px-2 pt-6 space-x-2 text-xs font-semibold lg:px-0 lg:space-x-8 lg:text-md ">
          <button
            className={`pb-2 ${
              tablist === "Why Ticketnation" ? "text-gray-500 border-b-2 " : ""
            }`}
            onClick={() => handleTab("Why Ticketnation")}
          >
            Why Ticketnation?
          </button>
          <button
            className={`pb-2 ${
              tablist === "Business" ? "text-gray-500 border-b-2" : ""
            }`}
            onClick={() => handleTab("Business")}
          >
            Business
          </button>
          <button
            className={` pb-2 ${
              tablist === "Our Products" ? "text-gray-500 border-b-2" : ""
            }`}
            onClick={() => handleTab("Our Products")}
          >
            Our Products
          </button>
          <button
            className={` pb-2 ${
              tablist === "Contact Us" ? "text-gray-500 border-b-2" : ""
            }`}
            onClick={() => handleTab("Contact Us")}
          >
            Contact Us
          </button>
        </div>
        {tablist === "Why Ticketnation" ? (
          <WhyTicketnation />
        ) : tablist === "Business" ? (
          <Business />
        ) : tablist === "Our Products" ? (
          <OurProducts />
        ) : tablist === "Contact Us" ? (
          <ContactUs />
        ) : (
          <Error404Page />
        )}
      </div>
    </>
  );
};

export default MarketingContents;
