import { Button } from "@/components/ui/button";
import React, { useState } from "react";
import { FiShare } from "react-icons/fi";

const ShareLinkButton: React.FC<{ username: string }> = ({ username }) => {
    const [copied, setCopied] = useState(false);

    const handleShare = () => {
        const url = `${process.env.REACT_APP_BASE_URL}/links/${username}`;
        navigator.clipboard.writeText(url).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        });
    };
    return (

            <Button onClick={handleShare} className="mt-2 flex items-center space-x-2">
                <FiShare />
                <span>{copied ? "Link Copied!" : "Share Profile Link"}</span>
            </Button>
    );
};

export default ShareLinkButton;