import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { useGetBuyRequestsQuery } from "../../../services/buyer-request.api";

type BuyRequest = {
  id: string;
  eventName: string;
  eventDate: string;
  eventTime: string;
  venueName: string;
  section: string;
  row: string;
  quantity: number;
  buyerBudget: number | null;
  status: string;
  createdAt: string;
  offers: Offer[];
};

type Offer = {
  id: string;
  priceOffered: number;
  quantity: number;
  createdAt: string;
  status: "PENDING" | "ACCEPTED" | "REJECTED";
  seller: {
    firstName: string;
    lastName: string;
  };
};

const mockBuyRequests: BuyRequest[] = [
  {
    id: "1",
    eventName: "Summer Music Festival",
    eventDate: "2024-07-15",
    eventTime: "18:00",
    venueName: "Central Park",
    section: "A",
    row: "10",
    quantity: 2,
    buyerBudget: 200,
    status: "pending",
    createdAt: "2024-05-01T10:00:00Z",
    offers: [
      {
        id: "offer1",
        priceOffered: 180,
        quantity: 2,
        createdAt: "2024-05-02T14:30:00Z",
        status: "PENDING",
        seller: { firstName: "John", lastName: "Doe" },
      },
      {
        id: "offer2",
        priceOffered: 190,
        quantity: 2,
        createdAt: "2024-05-03T09:15:00Z",
        status: "PENDING",
        seller: { firstName: "Jane", lastName: "Smith" },
      },
    ],
  },
  {
    id: "2",
    eventName: "Rock Concert",
    eventDate: "2024-08-20",
    eventTime: "20:00",
    venueName: "Madison Square Garden",
    section: "B",
    row: "5",
    quantity: 4,
    buyerBudget: 400,
    status: "pending",
    createdAt: "2024-06-01T11:30:00Z",
    offers: [
      {
        id: "offer3",
        priceOffered: 380,
        quantity: 4,
        createdAt: "2024-06-02T16:45:00Z",
        status: "PENDING",
        seller: { firstName: "Alice", lastName: "Johnson" },
      },
    ],
  },
];

export default function BuyRequests() {
  const { data: buyRequests, isLoading } = useGetBuyRequestsQuery({
    skip: 0,
    take: 10,
  });

  const [searchParams] = useSearchParams();
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null);

  useEffect(() => {
    const activeId = searchParams.get("activeRequest");
    if (activeId) {
      setActiveAccordion(activeId);
    } else if (buyRequests && buyRequests?.data?.length > 0) {
      setActiveAccordion(buyRequests.data[0].id);
    }
  }, [searchParams, buyRequests]);

  const handleAcceptOffer = (buyRequestId: string, offerId: string) => {};

  const handleRejectOffer = (buyRequestId: string, offerId: string) => {};

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container p-2 mx-auto sm:p-4">
      <h1 className="mb-4 text-xl font-bold sm:text-2xl">Buy Requests</h1>
      {buyRequests && buyRequests?.data?.length > 0 && activeAccordion ? (
        <Accordion
          type="single"
          value={activeAccordion}
          onValueChange={setActiveAccordion}
          className="space-y-2"
        >
          {buyRequests?.data.map((request) => (
            <AccordionItem
              key={request.id}
              value={request.id}
              className="border rounded-lg"
            >
              <AccordionTrigger className="px-4 py-2 hover:no-underline hover:bg-gray-100">
                <div className="flex items-center justify-between w-full">
                  <span className="font-medium">
                    {request.eventName} -{" "}
                    {format(new Date(request.eventDate), "MMM d, yyyy")}
                  </span>
                  <Badge
                    variant={
                      request.status === "pending" ? "outline" : "default"
                    }
                  >
                    {request.status}
                  </Badge>
                </div>
              </AccordionTrigger>
              <AccordionContent className="px-4 py-2">
                <div className="grid gap-2 mb-4 sm:grid-cols-2">
                  <div>
                    <p>
                      <strong>Event:</strong> {request.eventName}
                    </p>
                    <p>
                      <strong>Date & Time:</strong>{" "}
                      {format(new Date(request.eventDate), "MMM d, yyyy")} at{" "}
                      {request.eventTime}
                    </p>
                    <p>
                      <strong>Venue:</strong> {request.venueName}
                    </p>
                    <p>
                      <strong>Section:</strong> {request.section},{" "}
                      <strong>Row:</strong> {request.row}
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>Quantity:</strong> {request.quantity}
                    </p>
                    <p>
                      <strong>Budget:</strong> ${request.buyerBudget}
                    </p>
                    <p>
                      <strong>Created:</strong>{" "}
                      {format(new Date(request.createdAt), "MMM d, yyyy HH:mm")}
                    </p>
                  </div>
                </div>
                <h3 className="mb-2 text-lg font-semibold">Offers</h3>
                <div className="overflow-x-auto">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="w-[150px]">Seller</TableHead>
                        <TableHead>Price</TableHead>
                        <TableHead>Qty</TableHead>
                        <TableHead className="hidden sm:table-cell">
                          Date
                        </TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {request.offers &&
                        request?.offers.map((offer) => (
                          <TableRow key={offer.id}>
                            <TableCell className="font-medium">
                              {offer?.seller.firstName} {offer.seller.lastName}
                            </TableCell>
                            <TableCell>${offer.priceOffered}</TableCell>
                            <TableCell>{offer.quantity}</TableCell>
                            <TableCell className="hidden sm:table-cell">
                              {format(
                                new Date(offer.createdAt),
                                "MMM d, HH:mm"
                              )}
                            </TableCell>
                            <TableCell>
                              <Badge
                                variant={
                                  offer.status === "PENDING"
                                    ? "outline"
                                    : offer.status === "ACCEPTED"
                                    ? "default"
                                    : "secondary"
                                }
                              >
                                {offer.status}
                              </Badge>
                            </TableCell>
                            <TableCell>
                              {offer.status === "PENDING" && (
                                <div className="flex flex-col space-y-1 sm:flex-row sm:space-x-1 sm:space-y-0">
                                  <Button
                                    className=""
                                    size="sm"
                                    onClick={() =>
                                      handleAcceptOffer(request.id, offer.id)
                                    }
                                  >
                                    Accept
                                  </Button>
                                  <Button
                                    className=""
                                    size="sm"
                                    variant="outline"
                                    onClick={() =>
                                      handleRejectOffer(request.id, offer.id)
                                    }
                                  >
                                    Reject
                                  </Button>
                                </div>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <div className="text-center text-gray-500">No Buy Requests</div>
      )}
    </div>
  );
}
