import React, { useCallback } from "react";
import { createSearchParams, Link, useNavigate } from "react-router-dom";

interface SearchProps {
  placeholder: string;
}

const ArtistSearch: React.FC<SearchProps> = ({ placeholder }) => {
  const [searchValue, setSearchValue] = React.useState("");
  const navigate = useNavigate();

  const handleSearchSubmit = useCallback(() => {
    if (searchValue) {
      navigate({
        pathname: "search",
        search: `?${createSearchParams({
          search: searchValue,
        })}`,
      });
    }
  }, [searchValue, navigate]);

  React.useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();

        // 👇️ call submit function here
        handleSearchSubmit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [handleSearchSubmit]);

  return (
    <div className="flex px-2 my-2 drop-shadow lg:px-0" id="event-search">
      <input
        type="text"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={placeholder}
        className="relative flex-1 p-2 h-14"
      />

      {searchValue && (
        <button
          className="absolute right-16 top-4"
          onClick={() => setSearchValue("")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
          </svg>
        </button>
      )}

      <button
        onClick={() => handleSearchSubmit()}
        className="px-4 py-2 border rounded-tr-sm rounded-br-sm bg-blue-1100"
      >
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 20L15.514 15.506L20 20ZM18 9.5C18 11.7543 17.1045 13.9163 15.5104 15.5104C13.9163 17.1045 11.7543 18 9.5 18C7.24566 18 5.08365 17.1045 3.48959 15.5104C1.89553 13.9163 1 11.7543 1 9.5C1 7.24566 1.89553 5.08365 3.48959 3.48959C5.08365 1.89553 7.24566 1 9.5 1C11.7543 1 13.9163 1.89553 15.5104 3.48959C17.1045 5.08365 18 7.24566 18 9.5V9.5Z"
            stroke="#FCFCFC"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </button>
    </div>
  );
};

export default ArtistSearch;
