import React from "react";
import TNLogo from "../../assets/images/tnlogo.png";
import TNLogo2 from "../../assets/images/tnlogo2.png";
import Handshake from "../../assets/images/handshake.png";
import License from "../../assets/images/license.png";
import Fastxn from "../../assets/images/fastxn.png";
import Plugin from "../../assets/images/plugin.png";
import Inbox from "../../assets/images/inbox.png";
import Printer from "../../assets/images/printer.png";
import Materials from "../../assets/images/materials.png";
import Users from "../../assets/images/users.png";
import { Helmet } from "react-helmet";

const WhyTicketnation = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ticketnation Philippines | Why Ticketnation</title>
        <link rel="canonical" href="https://ticketnation.ph/about-us" />
      </Helmet>
      <div className="mt-12 lg:max-w-4xl lg:mx-auto">
        <div className="flex-row lg:flex">
          <div className="flex items-center justify-center lg:justify-start lg:w-1/2 lg:pl-16">
            <div>
              <p className="text-3xl font-extrabold text-center lg:text-left lg:text-4xl customText">
                Why
              </p>
              <p className="lg:text-5xl text-4xl font-extrabold leading-[56px] tracking-tighter  text-blue-1100 shadow-xl">
                Ticketnation?
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center lg:w-1/2 lg:pl-16">
            <img src={TNLogo} alt="tnlogo" />
          </div>
        </div>
      </div>
      <div className="lg:mx-auto mt-36 lg:max-w-7xl font-inter">
        <div className="flex-row lg:flex">
          <div className="px-4 lg:w-1/2 lg:px-0">
            <div className="flex items-center justify-center">
              <img src={Handshake} alt="handshake" />
            </div>
            <p className="mt-16 mb-2 text-2xl font-semibold text-center lg:text-3xl">
              Trusted Partnerships
            </p>
            <p className="text-center">We got you covered, partnered with</p>
            <p className="text-center">event organizers and productions.</p>
          </div>
          <div className="px-4 lg:w-1/2 lg:px-0">
            <div className="flex items-center justify-center mt-6 lg:mt-0">
              <img src={Fastxn} alt="fastxn" />
            </div>
            <p className="mt-[37px] mb-2 lg:text-3xl text-2xl font-semibold text-center ">
              Fast Transactions
            </p>
            <p className="text-center ">
              They make the for event goers to book or buy their
            </p>
            <p className="text-center">
              ticket online. They make the checkout process fast and easy.
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center px-4 mt-20 lg:px-0">
          <div className="flex items-center justify-center">
            <img src={License} alt="License" />
          </div>
          <p className="mt-16 mb-2 text-2xl font-semibold text-center lg:text-3xl">
            Licenses and Contracts
          </p>
          <p className="max-w-xl text-center ">
            For us to compete with the big fishes out there, we need to be
            ready. We need to build the trust of people, as a startup these are
            top priority. Before coming into Operations we processed everything,
            for us to move with ease and to build integrity to the business.
          </p>
        </div>

        {/* Our Story */}
        <div className="px-16 py-12 mx-6 mt-32 bg-white shadow-xl rounded-3xl">
          <div className="flex items-center justify-center">
            <div className="">
              <div className="text-4xl font-bold text-center">Our Story</div>
              <div className="text-2xl font-semibold text-center">
                Who we are?
              </div>
              <div className="max-w-3xl mt-12 font-semibold text-center">
                Innovating and help the events industry to streamline the
                process of ticketing. Making the process of validating of
                tickets, making it easy for event goers to book or buy their
                ticket online. Optimizing the Sales Report or Inventory of Event
                Productions. Creating tailor fit solutions to our partners and
                customers that will make their process much easier.
              </div>
            </div>
          </div>
          <div className="flex-row mt-20 lg:flex">
            <div className="flex flex-col items-center justify-center lg:w-1/2">
              <div className="text-2xl font-semibold">Mission</div>
              <div className="max-w-sm mt-4 font-semibold text-center">
                Ticket Nation's mission is to streamline and provide the easiest
                process from the smallest to the biggest events or concerts
                there is.
              </div>
            </div>
            <div className="flex flex-col items-center justify-center mt-4 lg:w-1/2 lg:mt-0">
              <div className="text-2xl font-semibold">Vision</div>
              <div className="max-w-md mt-4 font-semibold text-center">
                Vision Driven with the expanding of technology an events of the
                world. A fast-paced world where tickets and events are needed.
                We enable the best process there is.
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mt-16">
            <img src={TNLogo2} alt="tnlogo2" />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center px-12 mt-16">
          <div className="text-3xl font-bold text-center lg:text-left">
            We provide awesome Services
          </div>

          <div className="grid grid-cols-1 gap-6 mt-20 lg:grid-cols-2">
            <div className="flex space-x-4">
              <div className="flex items-center">
                <img className="w-24 h-12" src={Plugin} alt="Plugin" />
              </div>
              <div className="space-y-2">
                <div className="font-semibold">Website Plugin</div>
                <div>
                  We have a development team that can help out integrate the
                  ticket nation process on your website. We provide the services
                  of ticket nation but tailored fit for your needs.
                </div>
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="flex items-center">
                <img className="w-24 h-12" src={Users} alt="Users" />
              </div>
              <div className="space-y-2">
                <div className="font-semibold">Artist</div>
                <div>
                  We have a dedicated page on Ticketnation solely for Artists
                  and their craft. This is to promote and let the nation know
                  that there are music that are gems that they can try.
                </div>
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="flex items-center">
                <img className="w-24 h-12" src={Materials} alt="Materials" />
              </div>
              <div className="space-y-2">
                <div className="font-semibold">Public Materials</div>
                <div>
                  We have a dedicated Graphic Designer that can help out if you
                  need additional materials for your event. From poster making,
                  ticket customization, and many more.
                </div>
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="flex items-center">
                <img className="w-24 h-12" src={Inbox} alt="Inbox" />
              </div>
              <div className="space-y-2">
                <div className="font-semibold">Forum</div>
                <div>
                  We have a website for discussions for the Event Producers,
                  Organizers and Productions to discuss and incline the Events,
                  Artist Pricing and Venues.
                </div>
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="flex items-center">
                <img className="w-16 h-12" src={Printer} alt="Printer" />
              </div>
              <div className="space-y-2">
                <div className="font-semibold">Printing</div>
                <div>
                  If you request to have physical copies of tickets or need PVC
                  type of ticket access. We can also provide those.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyTicketnation;
