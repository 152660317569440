import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Dropdown from "../../components/Dropdown";
import Loading from "../../components/Loading";
import { SelectedEvent } from "../../features";
import { useGetEventGroupQuery } from "../../services/event.api";
import Timezone from "dayjs/plugin/timezone";
import UTC from "dayjs/plugin/utc";

dayjs.extend(UTC);
dayjs.extend(Timezone);

type UpdateEventParams = {
  id: string;
};
export const SelectedEventGroupPage = () => {
  const { id } = useParams<UpdateEventParams>() as UpdateEventParams;
  const { data: eventGroup, isLoading, isError } = useGetEventGroupQuery(id);
  const [eventId, setEventId] = useState(
    eventGroup?.eventGroupEvents[0].event.slug || ""
  );

  useEffect(() => {
    if (eventGroup && !eventId) {
      setEventId(eventGroup?.eventGroupEvents[0].event.slug);
    }
  }, [eventGroup, eventId]);
  if (isLoading) return <Loading />;

  const defaultValue = eventGroup?.eventGroupEvents.find(
    ({ event }) => event.slug === eventId
  );

  return (
    <div>
      {eventId && (
        <SelectedEvent
          id={eventId}
          dateDropDown={
            <Dropdown
              isNew
              newFeatureMessage="Select Date"
              onChange={(value) => setEventId(value)}
              iconPosition="right"
              icon={<FontAwesomeIcon icon={faAngleDown} />}
              placeholder={dayjs(defaultValue?.event.dateTime)
                .tz(dayjs.tz.guess())
                .format("MMMM DD, YYYY - hh:mm A")}
              defaultValue={defaultValue?.event.slug}
              options={
                eventGroup?.eventGroupEvents
                  .filter(
                    ({ event }) =>
                      event.slug !== eventId && dayjs().isBefore(event.dateTime)
                  )
                  .map(({ event }) => ({
                    label: dayjs(event.dateTime)
                      .tz(dayjs.tz.guess())
                      .format("MMMM DD, YYYY - hh:mm A"),
                    value: event.slug,
                  })) || []
              }
            />
            // <select onChange={(e) => setEventId(e.target.value)}>
            //   {eventGroup?.eventGroupEvents.map(({ event }) => (
            //     <option value={event.slug}>
            //       {dayjs(event.dateTime).format("MMMM DD, YYYY - hh:mm A")}
            //     </option>
            //   ))}
            // </select>
          }
        />
      )}
    </div>
  );
};
