import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import authReducer from "../features/Authentication/authSlice";
import eventReducer from "../features/Event/SelectedEvent/eventSlice";
import paymentReducer from "../features/Payment/paymentSlice";
import { artistApi } from "../services/artist.api";
import { authApi } from "../services/auth.api";
import { bannerApi } from "../services/banner.api";
import { buyerRequestApi } from "../services/buyer-request.api";
import { emailOtpApi } from "../services/email-otp.api";
import { healthApi } from "../services/health.api";
import { orderApiV2 } from "../services/order-v2.api";
import { orderApi } from "../services/order.api";
import { paymentApi } from "../services/payment.api";
import { publicApi } from "../services/public.api";
import { eventApi } from "./../services/event.api";
import { userApi } from "./../services/user.api";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    selectedEvent: eventReducer,
    [authApi.reducerPath]: authApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    payment: paymentReducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [artistApi.reducerPath]: artistApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [bannerApi.reducerPath]: bannerApi.reducer,
    [healthApi.reducerPath]: healthApi.reducer,
    [emailOtpApi.reducerPath]: emailOtpApi.reducer,
    [buyerRequestApi.reducerPath]: buyerRequestApi.reducer,
    [publicApi.reducerPath]: publicApi.reducer,
    [orderApiV2.reducerPath]: orderApiV2.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      orderApi.middleware,
      paymentApi.middleware,
      eventApi.middleware,
      artistApi.middleware,
      userApi.middleware,
      bannerApi.middleware,
      healthApi.middleware,
      emailOtpApi.middleware,
      buyerRequestApi.middleware,
      publicApi.middleware,
      orderApiV2.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
