import { format } from "date-fns";
import { Minus, Plus, Tag, Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Separator } from "../../components/ui/separator";
import { useToast } from "../../hooks/use-toast";

interface CartItem {
  id: string;
  type: "EVENT_TICKET" | "PRODUCT" | "DEAL";
  quantity: number;
  price: number;
  totalPrice: number;
  isSelected: boolean;
  eventTicket?: {
    id: string;
    name: string;
    description: string;
    section: string;
    row: string;
    price: number;
    event: {
      id: string;
      name: string;
      venue: {
        name: string;
        city: string;
        country: string;
      };
      dateTime: string;
    };
  };
  productVariant?: {
    id: string;
    name: string;
    price: number;
    originalPrice: number;
    size: string;
    color: string;
  };
  dealVariant?: {
    id: string;
    name: string;
    price: number;
    discountValue: number;
    availability: number;
    deal: {
      name: string;
      startDate: string;
      endDate: string;
    };
  };
}

interface Cart {
  id: string;
  total: number;
  subTotal: number;
  discount: number;
  voucher?: {
    id: string;
    name: string;
    code: string;
    discountPercentage: number;
    expiredAt: string;
  };
  cartItems: CartItem[];
}

const CartPage: React.FC = () => {
  const navigate = useNavigate();
  const [cart, setCart] = useState<Cart | null>(null);
  const [voucherCode, setVoucherCode] = useState("");
  const { toast } = useToast();

  useEffect(() => {
    // Simulating API call to fetch cart data
    const fetchCart = async () => {
      // Replace this with actual API call
      setCart({
        id: "c4f3b5e7-987d-4a6b-8f5c-f9839e382b12",
        total: 3000,
        subTotal: 2800,
        discount: 200,
        voucher: {
          id: "b3f0ed6c-55d9-4f92-b41b-5e0d9a90b39a",
          name: "New Year Discount",
          code: "NEWYEAR2024",
          discountPercentage: 10,
          expiredAt: "2024-12-31T23:59:59.000Z",
        },
        cartItems: [
          {
            id: "3d49a6bf-1234-4f6e-bf43-6f1e8f123a78",
            type: "EVENT_TICKET",
            quantity: 2,
            price: 1500,
            totalPrice: 3000,
            isSelected: true,
            eventTicket: {
              id: "4e5f6a7b-2d3c-4b1a-8f9e-7g6h8i0j9k1l",
              name: "Concert Ticket",
              description: "Front Row Seat",
              section: "401",
              row: "A",
              price: 1500,
              event: {
                id: "abcd1234-5678-9efg-0123-4567hijkl890",
                name: "Live Concert",
                venue: {
                  name: "Arena Manila",
                  city: "Manila",
                  country: "Philippines",
                },
                dateTime: "2024-11-15T20:00:00.000Z",
              },
            },
          },
          {
            id: "9d8a7b6c-4f5e-3d1a-2b3c-4d5e6f7g8h9i",
            type: "PRODUCT",
            quantity: 1,
            price: 1000,
            totalPrice: 1000,
            isSelected: true,
            productVariant: {
              id: "1234abcd-5678-efgh-9012-3456ijklmnop",
              name: "VIP Access",
              price: 1000,
              originalPrice: 1200,
              size: "Standard",
              color: "Gold",
            },
          },
          {
            id: "6b1d7c4e-5d7e-47a3-a3f1-2a1d6e8f4b8c",
            type: "DEAL",
            quantity: 1,
            price: 1000,
            totalPrice: 1000,
            isSelected: true,
            dealVariant: {
              id: "7a9d4e6f-5b2e-4b4c-b2a1-d5e4b7a9c4f3",
              name: "Spa Day Discount",
              price: 1000,
              discountValue: 200,
              availability: 100,
              deal: {
                name: "Spa Relaxation Package",
                startDate: "2024-11-01T00:00:00.000Z",
                endDate: "2024-11-30T23:59:59.000Z",
              },
            },
          },
        ],
      });
    };
    fetchCart();
  }, []);

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat("en-PH", {
      style: "currency",
      currency: "PHP",
    }).format(amount);
  };

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), "MMM d, yyyy h:mm a");
  };

  const handleQuantityChange = (itemId: string, change: number) => {
    if (!cart) return;

    const updatedItems = cart.cartItems.map((item) => {
      if (item.id === itemId) {
        const newQuantity = Math.max(1, item.quantity + change);
        return {
          ...item,
          quantity: newQuantity,
          totalPrice: item.price * newQuantity,
        };
      }
      return item;
    });

    const newSubTotal = updatedItems.reduce(
      (sum, item) => sum + item.totalPrice,
      0
    );
    const newTotal = newSubTotal - cart.discount;

    setCart({
      ...cart,
      cartItems: updatedItems,
      subTotal: newSubTotal,
      total: newTotal,
    });
  };

  const handleRemoveItem = (itemId: string) => {
    if (!cart) return;

    const updatedItems = cart.cartItems.filter((item) => item.id !== itemId);
    const newSubTotal = updatedItems.reduce(
      (sum, item) => sum + item.totalPrice,
      0
    );
    const newTotal = newSubTotal - cart.discount;

    setCart({
      ...cart,
      cartItems: updatedItems,
      subTotal: newSubTotal,
      total: newTotal,
    });
  };

  const handleApplyVoucher = () => {
    toast({
      title: "Voucher applied",
      description: `Voucher code ${voucherCode} has been applied to your cart.`,
    });
    setVoucherCode("");
  };

  if (!cart) return <div>Loading...</div>;

  return (
    <div className="p-2 md:p-4">
      <h1 className="mb-6 text-2xl font-bold">Your Cart</h1>
      <div className="grid gap-6 md:grid-cols-3">
        <div className="md:col-span-2">
          <div className="space-y-4">
            {cart.cartItems.map((item) => (
              <Card key={item.id} className="overflow-hidden">
                <CardContent className="p-2">
                  <div className="flex flex-col gap-2 sm:flex-row sm:items-center">
                    <div className="flex-1 space-y-1">
                      {item.type === "EVENT_TICKET" && item.eventTicket && (
                        <>
                          <h3 className="font-semibold">
                            {item.eventTicket.name}
                          </h3>
                          <p className="text-sm text-muted-foreground">
                            {item.eventTicket.description}
                          </p>
                          <p className="text-xs">
                            {formatDate(item.eventTicket.event.dateTime)} at{" "}
                            {item.eventTicket.event.venue.name}
                          </p>
                          <Badge variant="secondary" className="mt-1">
                            Section {item.eventTicket.section}, Row{" "}
                            {item.eventTicket.row}
                          </Badge>
                        </>
                      )}
                      {item.type === "PRODUCT" && item.productVariant && (
                        <>
                          <h3 className="font-semibold">
                            {item.productVariant.name}
                          </h3>
                          <p className="text-sm">
                            Size: {item.productVariant.size}, Color:{" "}
                            {item.productVariant.color}
                          </p>
                        </>
                      )}
                      {item.type === "DEAL" && item.dealVariant && (
                        <>
                          <h3 className="font-semibold">
                            {item.dealVariant.name}
                          </h3>
                          <p className="text-xs">
                            Valid: {formatDate(item.dealVariant.deal.startDate)}{" "}
                            - {formatDate(item.dealVariant.deal.endDate)}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="flex items-center justify-between sm:flex-col sm:items-end">
                      <div className="flex items-center gap-2">
                        <Button
                          size="icon"
                          variant="outline"
                          className="w-8 h-8"
                          onClick={() => handleQuantityChange(item.id, -1)}
                        >
                          <Minus className="w-3 h-3" />
                        </Button>
                        <span className="w-8 text-center">{item.quantity}</span>
                        <Button
                          size="icon"
                          variant="outline"
                          className="w-8 h-8"
                          onClick={() => handleQuantityChange(item.id, 1)}
                        >
                          <Plus className="w-3 h-3" />
                        </Button>
                      </div>
                      <div className="text-right">
                        <div className="font-semibold">
                          {formatCurrency(item.totalPrice)}
                        </div>
                        <Button
                          size="icon"
                          variant="ghost"
                          className="w-8 h-8"
                          onClick={() => handleRemoveItem(item.id)}
                        >
                          <Trash2 className="w-4 h-4" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
        <div className="md:sticky md:top-8 md:h-fit">
          <Card>
            <CardHeader>
              <CardTitle>Order Summary</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span>Subtotal</span>
                  <span>{formatCurrency(cart.subTotal)}</span>
                </div>
                {cart.discount > 0 && (
                  <div className="flex justify-between text-green-600">
                    <span>Discount</span>
                    <span>-{formatCurrency(cart.discount)}</span>
                  </div>
                )}
                <Separator />
                <div className="flex justify-between font-semibold">
                  <span>Total</span>
                  <span>{formatCurrency(cart.total)}</span>
                </div>
              </div>
            </CardContent>
            <CardFooter className="flex flex-col gap-4">
              <div className="flex w-full gap-2">
                <Input
                  placeholder="Enter voucher code"
                  value={voucherCode}
                  onChange={(e) => setVoucherCode(e.target.value)}
                />
                <Button onClick={handleApplyVoucher}>
                  <Tag className="w-4 h-4 mr-2" />
                  Apply
                </Button>
              </div>
              <Button onClick={() => navigate("/checkout")} className="w-full">
                Proceed to Checkout
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
