import React from "react";
import ProductCard from "./ProductCard";
import { useGetProductsQuery } from "../../../../../services/event.api";
import { Product } from "../../../../../models/product.model";

const TicketnationProduct = () => {
  const [skip, setSkip] = React.useState(0);
  const [take, setTake] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const { data } = useGetProductsQuery({
    skip,
    take,
    search,
    isSystemOwned: true,
  });

  if (!data || data?.count === 0) return null;
  return (
    <div className="flex-1">
      <h2 className="font-semibold text-md lg:text-2xl">Ticketnation Product</h2>
      <div className="grid gap-2">
        {data &&
          data.data.map((product: Product) => {
            return <ProductCard key={product.id} product={product} />;
          })}
      </div>
    </div>
  );
};

export default TicketnationProduct;
