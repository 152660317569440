import React from "react";
import { useNavigate } from "react-router-dom";
import Error404 from "../../../assets/images/error404.png";

const ErrorPayment: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex items-center justify-center h-screen ">
        <div>
          <div className="px-10 py-5 mb-6 bg-blue-1100 rounded-xl">
            <img src={Error404} alt="errorLogo" />
          </div>
          <div className="flex items-center justify-center">
            <button
              onClick={() => navigate("/")}
              className="px-20 py-3 mt-20 text-2xl font-semibold bg-blue-1100 rounded-xl text-gray-50"
            >
              Return to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPayment;
