import React from "react";
import ResetPassLogo from "../../../assets/images/resetpasslogo.png";
import ResetPassword from "../../../features/Authentication/ForgotPassword/ResetPassword";

const ResetPasswordPage = () => {
  return (
    <div className="flex items-center justify-center ">
      <div className="w-full m-5 md:w-2/5 md:pt-10 md:m-0">
        <div className="text-center">
          <div className="w-full">
            <div className="px-5 text-4xl font-bold uppercase lg:px-0">
              Reset Password
            </div>
          </div>
          <ResetPassword />
        </div>
      </div>

      <div className="relative h-screen md:w-3/5 bg-blue-1100">
        <img
          className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          src={ResetPassLogo}
          alt="ResetPassLogo"
        />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
