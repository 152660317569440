import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAppSelector } from "../../app/hooks";
import { Modal } from "../../components/Modal";
import {
  handleXenditInitiation,
  handleXenditVerification,
} from "../../helpers/xendit";
import logger from "../../lib/logger";
import {
  CustomError,
  useCreateCreditCardPaymentMutation,
} from "../../services/payment.api";
import { authSelect } from "../Authentication/authSlice";

interface FormValues {
  name_on_card: string;
  card_number: string;
  card_exp_month: string;
  card_exp_year: string;
  card_cvn: string;
  is_multiple_use: boolean;
  should_authenticate: boolean;
}

interface ComponentProps {
  orderId: string;
  amount: number;
  acknowledgement?: boolean;
}

export const CreditCard: React.FC<ComponentProps> = ({
  orderId,
  amount,
  acknowledgement,
}) => {
  const { user } = useAppSelector(authSelect);
  const navigate = useNavigate();
  const [cardToken, setCardToken] = useState("");
  const [tokenStatus, setTokenStatus] = useState("");
  const [cvn, setCvn] = useState("");
  const [open, setOpen] = useState(false);
  const [createCreditCardPayment, { isSuccess, isError, error }] =
    useCreateCreditCardPaymentMutation();

  // Initialize Xendit
  React.useEffect(() => {
    logger.info("Initializing Xendit for credit card payment");
    handleXenditInitiation();
  }, []);

  // Handle payment success
  React.useEffect(() => {
    if (isSuccess) {
      logger.info("Payment successful", {
        amount,
        orderId,
        user: user?.email || "Guest",
      });
      window.fbq("track", "Purchase", {
        value: amount,
        currency: "PHP",
        em: user?.email ? user?.email.toLowerCase() : "",
        ln: user?.lastName ? user?.lastName.toLowerCase() : "",
        fn: user?.firstName ? user?.firstName.toLowerCase() : "",
      });
      navigate("success");
    }
  }, [isSuccess, navigate, amount, orderId, user]);

  // Handle token status
  React.useEffect(() => {
    if (tokenStatus === "Verified") {
      setOpen(false);
      logger.info("Card token verified, initiating payment", {
        token: cardToken,
        orderId,
      });
      createCreditCardPayment({
        orderId,
        tokenId: cardToken,
        cardCVN: cvn,
      });
    }
  }, [tokenStatus, orderId, cvn, cardToken, createCreditCardPayment]);

  const initialValues: FormValues = {
    name_on_card: "",
    card_number: "",
    card_exp_month: "",
    card_exp_year: "",
    card_cvn: "",
    is_multiple_use: false,
    should_authenticate: true,
  };

  const validationSchema = Yup.object().shape({
    card_number: Yup.string().required("Card Number is required."),
    card_exp_year: Yup.string()
      .min(4)
      .max(4)
      .required("Card expiry year doesn't follow format: YYYY"),
    card_exp_month: Yup.string()
      .min(2)
      .max(2)
      .required("Card expiry month doesn't follow format: MM"),
  });

  // Handle Xendit response
  function xenditResponseHandler(err: string, creditCardCharge: any) {
    if (err) {
      logger.error("Xendit error during token creation", { error: err });
      return;
    }

    if (creditCardCharge.status === "VERIFIED") {
      const token = creditCardCharge.id;
      logger.info("Xendit card token verified", { token });
      setCardToken(token);
      setTokenStatus("Verified");
    } else if (creditCardCharge.status === "IN_REVIEW") {
      const authenticationUrl = creditCardCharge.payer_authentication_url;
      logger.info("Card under review, 3DS authentication required", {
        authenticationUrl,
      });
      setCardToken(authenticationUrl);
      setTokenStatus("In Review");
      setOpen(true);
    } else if (creditCardCharge.status === "FAILED") {
      logger.error("Xendit card token creation failed", {
        reason: creditCardCharge.failure_reason,
      });
      setCardToken(creditCardCharge.failure_reason);
      setTokenStatus("Failed");
    }
  }

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    logger.info("Initiating checkout", { user: user?.email || "Guest" });
    window.fbq("track", "InitiateCheckout", {
      em: user?.email ? user?.email.toLowerCase() : "",
      ln: user?.lastName ? user?.lastName.toLowerCase() : "",
      fn: user?.firstName ? user?.firstName.toLowerCase() : "",
    });

    const isCardValidValid = handleXenditVerification(
      "card_number",
      values.card_number
    );
    const isCardExpiryValid = handleXenditVerification(
      "expiry_date",
      values.card_exp_month,
      values.card_exp_year
    );
    const isCardCvnValid = handleXenditVerification(
      "cvn_number",
      values.card_cvn
    );

    if (isCardValidValid && isCardExpiryValid && isCardCvnValid) {
      logger.info("Card details validated", { cardDetails: values });
      setCvn(values.card_cvn);
      window.Xendit.card.createToken(
        {
          amount,
          ...values,
        },
        xenditResponseHandler
      );
    } else {
      logger.warn("Card validation failed", { values });
    }
  };

  if (!acknowledgement) {
    logger.warn("Acknowledgement not provided for terms and conditions");
    return (
      <div className="text-red-500 ">
        Please acknowledge the terms and conditions to proceed.
      </div>
    );
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, values }) => {
          return (
            <Form>
              {isError &&
                (error as CustomError).data.message.length > 0 &&
                (error as CustomError).data.message.map((mess) => {
                  logger.error("Error from createCreditCardPayment API", {
                    error: mess,
                  });
                  return <p className="text-red-700">{mess}</p>;
                })}
              {errors && (
                <div>
                  <p className="text-red-700">{errors.card_number}</p>
                  <p className="text-red-700">{errors.card_exp_month}</p>
                  <p className="text-red-700">{errors.card_exp_year}</p>
                </div>
              )}
              <div className="w-full mt-4">
                <Field name="name_on_card">
                  {({ field }: FieldProps) => (
                    <input
                      type="text"
                      placeholder="Name on Card"
                      className="w-full px-4 py-2 border-black drop-shadow border-1"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="w-full mt-4">
                <Field name="card_number">
                  {({ field, form: { setFieldValue } }: FieldProps) => (
                    <input
                      type="text"
                      placeholder="Card Number"
                      className="w-full px-4 py-2 border-black drop-shadow input-number-no-appearance border-1"
                      {...field}
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        const regex =
                          /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                        if (!value || regex.test(value.toString())) {
                          setFieldValue("card_number", value);
                        }
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className="grid grid-cols-2 gap-6">
                <div className="grid w-full grid-cols-2 gap-2 mt-4">
                  <Field name="card_exp_month">
                    {({ field }: FieldProps) => (
                      <input
                        type="text"
                        placeholder="Expiry Month - MM"
                        className="w-full px-4 py-2 border-black drop-shadow border-1"
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="card_exp_year">
                    {({ field }: FieldProps) => (
                      <input
                        type="text"
                        placeholder="Expiry Year - YYYY"
                        className="w-full px-4 py-2 border-black drop-shadow border-1"
                        {...field}
                      />
                    )}
                  </Field>
                </div>
                <div className="w-full mt-4">
                  <Field name="card_cvn">
                    {({ field }: FieldProps) => (
                      <input
                        type="text"
                        placeholder="CVN"
                        className="w-full px-4 py-2 border-black drop-shadow border-1"
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="w-full my-6">
                <button
                  type="submit"
                  className={`w-full p-2 font-semibold text-white uppercase bg-blue-1100`}
                >
                  CONFIRM PAYMENT
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Modal open={open} setOpen={setOpen} size="l">
        <iframe
          title="3DS Authentication"
          src={cardToken}
          height={500}
          width="100%"
        />
      </Modal>
    </>
  );
};
