import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SignUpImage from "../../assets/images/signup-pic.png";
import TicketNationLogo from "../../assets/images/TN.png";
import Error from "../../components/Error";
import Loading from "../../components/Loading";
import SignUp from "../../features/Authentication/SignUp";
import { SignupFormValues } from "../../interfaces/auth/Interface";
import { useSignupMutation } from "../../services/auth.api";

const SignUpPage = () => {
  const navigate = useNavigate();
  const [signup, { error, isError, isSuccess, isLoading }] =
    useSignupMutation();

  React.useEffect(() => {
    if (isSuccess) {
      navigate("/signup/success");
    }
  }, [isSuccess, navigate]);

  const handleSubmit = (values: SignupFormValues) => {
    signup(values);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex items-center justify-center">
      <div className="w-full md:w-2/5 lg:pt-10">
        <div className="flex flex-col items-center justify-center">
          <div className="flex justify-center my-12">
            <Link to="/">
              <img src={TicketNationLogo} alt="ticketnation" />
            </Link>
          </div>

          <SignUp
            signupHandleSubmit={handleSubmit}
            isError={isError}
            error={error}
          />
        </div>
      </div>

      <div className="relative h-screen md:w-3/5 bg-blue-1100">
        <img
          className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          src={SignUpImage}
          alt="signup"
        />
      </div>
    </div>
  );
};

export default SignUpPage;
