import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import {
  CreateOrderV2,
  CreateOrderV2Response,
  GetOrderV2Response,
} from "../models/order-v2.model";
import { RootState } from "./../app/store";

export interface CustomError {
  data: {
    message: string;
  };
  status: number;
}

interface GetOrder {
  id: string;
}

export const orderApiV2 = createApi({
  reducerPath: "orderApiV2",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/users/v2/orders",
    prepareHeaders: (headers, { getState, endpoint }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["OrderV2"],
  endpoints: (builder) => ({
    createOrderV2: builder.mutation<CreateOrderV2Response, CreateOrderV2>({
      query: (body) => {
        return {
          url: "",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["OrderV2"],
    }),

    getOrderV2: builder.query<GetOrderV2Response, GetOrder>({
      query: ({ id }) => {
        return {
          url: `${id}`,
        };
      },
      providesTags: ["OrderV2"],
    }),
  }),
});

export const { useCreateOrderV2Mutation, useGetOrderV2Query } = orderApiV2;
