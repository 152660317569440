import { CalendarCheck, LayoutDashboard, ListOrdered } from "lucide-react";
import { NavLink, Outlet } from "react-router-dom";

export default function PartnerDashboardPage() {
  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Sidebar */}
      <aside className="w-64 bg-white">
        <div className="p-4">
          <h1 className="text-2xl font-bold ">Partner</h1>
        </div>
        <nav className="mt-6">
          <NavLink
            to="/partner-dashboard"
            end
            className={({ isActive }) =>
              `flex items-center px-4 py-2 ${
                isActive
                  ? "text-gray-700 bg-gray-100"
                  : "text-gray-600 hover:bg-gray-100"
              }`
            }
          >
            <LayoutDashboard className="w-5 h-5 mr-2" />
            Dashboard
          </NavLink>
          <NavLink
            to="/partner-dashboard/events"
            className={({ isActive }) =>
              `flex items-center px-4 py-2 mt-2 ${
                isActive
                  ? "text-gray-700 bg-gray-100"
                  : "text-gray-600 hover:bg-gray-100"
              }`
            }
          >
            <CalendarCheck className="w-5 h-5 mr-2" />
            Events
          </NavLink>
          <NavLink
            to="/partner-dashboard/orders"
            className={({ isActive }) =>
              `flex items-center px-4 py-2 mt-2 ${
                isActive
                  ? "text-gray-700 bg-gray-100"
                  : "text-gray-600 hover:bg-gray-100"
              }`
            }
          >
            <ListOrdered className="w-5 h-5 mr-2" />
            Orders
          </NavLink>
        </nav>
      </aside>

      {/* Main content */}
      <main className="flex-1">
        <Outlet />
      </main>
    </div>
  );
}
