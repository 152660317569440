import React from "react";
import {useForm, useFieldArray} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import * as z from "zod";
import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "@/components/ui/form";
import {Plus, Trash2} from "lucide-react";
import {
    FaFacebook,
    FaInstagram,
    FaLinkedin,
    FaTwitter,
    FaYoutube,
    FaEnvelope,
    FaPhone,
    FaGlobe,
    FaLink
} from "react-icons/fa";
import {Select, SelectTrigger, SelectItem, SelectValue, SelectContent} from "@/components/ui/select";

export const iconMap = {
    facebook: FaFacebook,
    instagram: FaInstagram,
    linkedin: FaLinkedin,
    twitter: FaTwitter,
    youtube: FaYoutube,
    email: FaEnvelope,
    phone: FaPhone,
    website: FaGlobe,
    default: FaLink
};

export type IconName = keyof typeof iconMap;

const socialLinkSchema = z.object({
    id: z.string(),
    platform: z.enum(["website", "facebook", "twitter", "instagram", "linkedin", "youtube", "email", "phone"]),
    url: z.string(),
});

const formSchema = z.object({
    fullName: z.string().min(1, "Full name is required"),
    company: z.string().optional(),
    position: z.string().optional(),
    links: z.array(socialLinkSchema)
});

type FormData = z.infer<typeof formSchema>;

interface SocialFormProps {
    defaultValues: FormData;
    onSubmit: (data: FormData) => void;
}

const SocialForm: React.FC<SocialFormProps> = ({defaultValues, onSubmit,}) => {
    const form = useForm<FormData>({
        resolver: zodResolver(formSchema),
        defaultValues
    });
    console.log("defaultValues", defaultValues);

    const {fields, append, remove} = useFieldArray({
        control: form.control,
        name: "links"
    });

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                <FormField
                    control={form.control}
                    name="fullName"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Full Name</FormLabel>
                            <FormControl>
                                <Input {...field} />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="company"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Company</FormLabel>
                            <FormControl>
                                <Input {...field} />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="position"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Position</FormLabel>
                            <FormControl>
                                <Input {...field} />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <div>
                    <h3 className="text-lg font-semibold mb-2">Social Links</h3>
                    <p className="text-sm text-muted-foreground mb-4">
                        Add your social media profiles and website links to share with other event-goers.
                    </p>
                    <div className="space-y-4">
                        {fields.map((field, index) => {
                            const Icon = iconMap[field.platform as IconName] || iconMap.default;

                            return (
                                <div key={field.id} className="flex gap-4">
                                    <FormField
                                        control={form.control}
                                        name={`${`links`}.${index}.platform`}
                                        render={({field}) => (
                                            <FormItem>
                                                <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                    <SelectTrigger>
                                                        <SelectValue placeholder="Select a platform"/>
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectItem value="website">Website</SelectItem>
                                                        <SelectItem value="facebook">Facebook</SelectItem>
                                                        <SelectItem value="twitter">Twitter</SelectItem>
                                                        <SelectItem value="instagram">Instagram</SelectItem>
                                                        <SelectItem value="linkedin">LinkedIn</SelectItem>
                                                        <SelectItem value="youtube">YouTube</SelectItem>
                                                        <SelectItem value="email">Email</SelectItem>
                                                        <SelectItem value="phone">Phone</SelectItem>
                                                    </SelectContent>
                                                </Select>
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={form.control}
                                        name={`${`links`}.${index}.url`}
                                        render={({field}) => (
                                            <FormItem className="flex-1">
                                                <FormControl>
                                                    <div className="relative">
                                                        <div
                                                            className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                            <Icon className="h-4 w-4 text-muted-foreground"/>
                                                        </div>
                                                        <Input {...field} className="pl-10" placeholder="Enter URL"/>
                                                    </div>
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        size="icon"
                                        onClick={() => {
                                            remove(index);
                                        }}
                                    >
                                        <Trash2 className="h-5 w-5"/>
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                    <Button
                        type="button"
                        variant="outline"
                        size="sm"
                        className="mt-4"
                        onClick={() => append({id: crypto.randomUUID(), platform: "website", url: ""})}
                    >
                        <Plus className="h-4 w-4 mr-2"/>
                        Add Another Link
                    </Button>
                </div>

                <div className="flex justify-end">
                    <Button type="submit" disabled={form.formState.isSubmitting}>
                        {form.formState.isSubmitting ? "Saving Changes..." : "Save Changes"}
                    </Button>
                </div>
            </form>
        </Form>
    );
};

export default SocialForm;
