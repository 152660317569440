import React from "react";
import { Helmet } from "react-helmet";
import Partner from "../../assets/coming-soon/your-partner.png";
import Connect from "../../assets/coming-soon/connect.png";
import Dashboard from "../../assets/coming-soon/dashboard.png";
import Market from "../../assets/coming-soon/market.png";
import Features from "../../assets/coming-soon/features.png";
import Admin from "../../assets/coming-soon/admin.png";
import Publish from "../../assets/coming-soon/publish.png";
import Report from "../../assets/coming-soon/report.png";
import Ticket from "../../assets/coming-soon/ticket.png";
import Support from "../../assets/coming-soon/support.png";
import More from "../../assets/coming-soon/and_more.png";
import BePartner from "../../assets/coming-soon/be-a-partner.png";
import GoogleAd from "../../components/Google/GoogleAd";
import { Modal } from "../../components/Modal";
import { RegisterOrganization } from "../../features";

export const BecomePartner = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="bg-gray-100">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ticketnation Philippines | Partner</title>
        <link rel="canonical" href="https://ticketnation.ph/partner" />
      </Helmet>
      <div className="bg-blue-1100 ">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between md:pb-10">
          <div className="p-10 text-center md:text-left md:p-14">
            <h1 className="text-2xl font-semibold text-white uppercase md:text-4xl">
              Your Partner
            </h1>
            <h2 className="text-2xl font-semibold text-white uppercase md:text-4xl">
              For ticket management
            </h2>
            <p className="text-sm text-white md:text-base">
              Your partner for event management. We got you covered.
            </p>
          </div>
          <div className="order-first p-6 md:order-last md:p-14">
            <img src={Partner} alt="partner" />
          </div>
        </div>
      </div>
      <div className="max-w-6xl mx-auto">
        <GoogleAd googleAdId="ca-pub-7346463941554522" slot="1189205775" />
        <div className="text-4xl font-semibold text-center uppercase text-blue-1100 my-14">
          What we do
        </div>
        <div className="grid grid-cols-1 gap-20 p-5 md:p-0 md:grid-cols-3 py-14">
          <div className="p-5 bg-white border-gray-200 rounded-lg drop-shadow">
            <img src={Connect} alt="connect" />
            <h3 className="pb-3 text-2xl font-semibold text-center uppercase">
              Connections
            </h3>
            <p className="text-base font-normal text-center">
              Connecting the performers to the productions and vice versa for
              your events.
            </p>
          </div>
          <div className="p-5 bg-white border-gray-200 rounded-lg drop-shadow">
            <img src={Dashboard} alt="connect" />
            <h3 className="pb-3 text-2xl font-semibold text-center uppercase">
              Dashboard
            </h3>
            <p className="text-base font-normal text-center">
              Creation and keeping track of your future events and past events.
            </p>
          </div>
          <div className="p-5 bg-white border-gray-200 rounded-lg drop-shadow">
            <img src={Market} alt="connect" />
            <h3 className="pb-3 text-2xl font-semibold text-center uppercase">
              Market place{" "}
            </h3>
            <p className="text-base font-normal text-center">
              This is the place where partners can post their events. For the
              partners and event goers.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center md:flex-row">
        <div className="w-full p-5 md:p-0 md:pt-16 md:mr-16 md:w-1/2">
          <div className="p-5 bg-blue-1100 md:p-0">
            <div className="flex justify-center pt-10 md:pt-16">
              <img src={Features} alt="feature" />
            </div>
            <div className="py-10">
              <p className="text-2xl font-semibold text-center text-white uppercase md:text-4xl">
                Features that
              </p>
              <p className="text-2xl font-semibold text-center text-white uppercase md:text-4xl">
                Fits your needs
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-start bg-gray-100 md:w-2/5 md:flex-row py-14">
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3 justify-items-center">
            <div className="flex flex-col items-center justify-center w-40 h-40 bg-white border-gray-100 drop-shadow md:flex-row lg:flex-col">
              <img src={Admin} alt="admin" className="pt-5" />
              <p className="pt-5 text-base font-medium text-center uppercase">
                Admin
              </p>
            </div>
            <div className="flex flex-col items-center justify-center w-40 h-40 bg-white border-gray-100 drop-shadow md:flex-row lg:flex-col">
              <img src={Publish} alt="publish" className="pt-5" />
              <p className="pt-3 text-base font-medium text-center uppercase">
                Publish
              </p>
            </div>
            <div className="flex flex-col items-center justify-center w-40 h-40 bg-white border-gray-100 drop-shadow md:flex-row lg:flex-col">
              <img src={Report} alt="report" className="pt-5" />
              <p className="pt-3 text-base font-medium text-center uppercase">
                Reports
              </p>
            </div>
            <div className="flex flex-col items-center justify-center w-40 h-40 bg-white border-gray-100 drop-shadow md:flex-row lg:flex-col">
              <img src={Ticket} alt="ticket" className="pt-2" />
              <p className="pt-1 text-base font-medium text-center uppercase">
                Ticket
              </p>
              <p className="text-base font-medium text-center uppercase">
                Creation
              </p>
            </div>
            <div className="flex flex-col items-center justify-center w-40 h-40 bg-white border-gray-100 drop-shadow md:flex-row lg:flex-col">
              <img src={Support} alt="support" className="pt-2" />
              <p className="pt-3 text-base font-medium text-center uppercase">
                Support
              </p>
            </div>
            <div className="flex flex-col items-center justify-center w-40 h-40 bg-white border-gray-100 drop-shadow md:flex-row lg:flex-col">
              <img src={More} alt="more" className="pt-2" />
              <p className="pt-3 text-base font-medium text-center uppercase">
                And more...
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col items-center justify-center w-full p-5 md:w-1/2 md:flex-col md:p-0">
          <p className="text-lg font-normal text-center md:text-left">
            We're along side you throughout the journey.
          </p>
          <p className="text-lg font-normal text-center md:text-left">
            Helping you out and adding more features
          </p>
          <p className="text-lg font-normal text-center md:text-left">
            that will further assist you with management.
          </p>
          <button
            type="button"
            onClick={() => setOpen(true)}
            className="bg-blue-1100 font-semibold text-2xl w-full md:w-96 rounded-[20px] drop-shadow text-white uppercase mt-5 p-3"
          >
            Let's get started
          </button>
        </div>
        <div className="order-first w-full p-5 md:order-last md:w-1/2 md:p-0">
          <div className="p-5 bg-blue-1100 md:p-0">
            <div className="flex justify-center pt-16">
              <img src={BePartner} alt="be-a-partner" />
            </div>
            <div className="pb-14">
              <p className="py-4 pl-16 text-2xl font-semibold text-center text-white uppercase md:text-4xl">
                Be a partner.
              </p>
              <p className="pl-20 text-2xl font-semibold text-center text-white uppercase md:text-4xl">
                We got you.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        setOpen={setOpen}
        title="SIGN UP ORGANIZATION"
        size="xl"
      >
        <RegisterOrganization setOpen={setOpen} />
      </Modal>
    </div>
  );
};
