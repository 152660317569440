import { ChevronDown, Menu } from "lucide-react";
import { useState } from "react";
import Loading from "../../../components/Loading";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useGetUserQuery } from "@/services/user.api";
import OrganizationDetailsTab from "./OrganizationDetailsTab";
import PersonalInfoTab from "./ProfileTab/PersonalInfoTab";
import SecuritySettingsTab from "./SecuritySettingsTab";
import LinkTab from "@/pages/Profile/ProfileSettings/LinkTab";

export default function ProfileSettings() {
  const { data: user, isLoading } = useGetUserQuery({});
  const [activeTab, setActiveTab] = useState<string>("personal");

  if (isLoading) {
    return <Loading />;
  }
  if (!user) {
    return <div>User not found.</div>;
  }

  return (
    <div className="container p-4 mx-auto">
      <h1 className="mb-4 text-2xl font-bold">Profile Settings</h1>

      {/* Web View */}
      <div className="hidden md:block">
        <Tabs
          defaultValue="personal"
          className="w-full"
          onValueChange={setActiveTab}
        >
          <TabsList className="grid w-full grid-cols-4 mb-4">
            <TabsTrigger value="personal">Personal</TabsTrigger>
            <TabsTrigger value="links">Links</TabsTrigger>
            {user?.organization && (
              <TabsTrigger value="organization">Organization</TabsTrigger>
            )}
            {/* <TabsTrigger value="security">Security</TabsTrigger>
             */}
            {/* <TabsTrigger value="addresses">Addresses</TabsTrigger> */}
          </TabsList>

          {/* Render tab content */}
          {activeTab === "personal" && <PersonalInfoTab user={user} />}
          {activeTab === "links" && <LinkTab />}
          {activeTab === "security" && <SecuritySettingsTab user={user} />}
          {activeTab === "organization" && (
            <OrganizationDetailsTab user={user} />
          )}
          {/* {activeTab === "addresses" && <ManageAddressesTab />} */}
        </Tabs>
      </div>

      {/* Mobile View */}
      <div className="md:hidden">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="w-full mb-4">
              <Menu className="w-4 h-4 mr-2" />
              {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
              <ChevronDown className="w-4 h-4 ml-auto" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-full">
            <DropdownMenuItem onSelect={() => setActiveTab("personal")}>
              Personal
            </DropdownMenuItem>
            <DropdownMenuItem onSelect={() => setActiveTab("links")}>
              Links
            </DropdownMenuItem>
            {user?.organization && (
              <DropdownMenuItem onSelect={() => setActiveTab("organization")}>
                Organization
              </DropdownMenuItem>
            )}
            {/* <DropdownMenuItem onSelect={() => setActiveTab("security")}>
              Security
            </DropdownMenuItem>
         
            <DropdownMenuItem onSelect={() => setActiveTab("addresses")}>
              Addresses
            </DropdownMenuItem> */}
          </DropdownMenuContent>
        </DropdownMenu>
        {/* Render tab content */}

        {activeTab === "personal" && <PersonalInfoTab user={user} />}
        {activeTab === "links" && <LinkTab  />}
        {activeTab === "security" && <SecuritySettingsTab user={user} />}
        {activeTab === "organization" && <OrganizationDetailsTab user={user} />}
        {/* {activeTab === "addresses" && <ManageAddressesTab />} */}
      </div>
    </div>
  );
}
