import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Redirect = () => {
  const navigate = useNavigate();
  const query = useQuery();

  React.useEffect(() => {
    let redirect: NodeJS.Timeout | null = null;

    redirect = setTimeout(() => {
      navigate(
        `/event/${query.get("eventSlug")}/order/${query.get("orderId")}/success`
      );
    }, 1000);

    return () => {
      if (redirect) {
        redirect = null;
      }
    };
  }, []);

  return <div>Redirect</div>;
};

export default Redirect;
