import React from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import OrderContextProvider from "./context/OrderContextProvider";
import OrderExpirationNav from "./OrderExpirationNav";
import OrderStepNav from "./OrderStepNav";
import ShopSummary from "../../features/Event/SelectedEvent/Shop/ShopSummary";

type UrlParams = {
  id: string;
  orderId: string;
};

const OrderPage = () => {
  const { id, orderId } = useParams<UrlParams>() as UrlParams;
  const path = useLocation().pathname;

  return (
    <div className="flex flex-col justify-between flex-grow p-2 ">
      <div className="grid-cols-2 gap-2">
        <OrderContextProvider orderId={orderId} eventId={id}>
          <OrderStepNav />
          <OrderExpirationNav />

          <Outlet />
        </OrderContextProvider>
      </div>
    </div>
  );
};

export default OrderPage;
