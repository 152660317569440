import React from "react";
import { useAppSelector } from "../../../app/hooks";
import {
  getDiscountedValue,
  getTotalDiscount,
  roundDown,
} from "../../../helpers/priceHelper";
import { selectedEventSelect } from "./eventSlice";

interface ComponentProps {
  // currency: string;
}

export const EventPaymentSummary: React.FC<ComponentProps> = () => {
  const { payload, voucher, orderFee, currency } =
    useAppSelector(selectedEventSelect);
  const totalTicketQuantity = payload.reduce(
    (previousValue, currentValue) =>
      previousValue + currentValue.quantity * currentValue.multiplier,
    0
  );
  const totalTickets = payload.reduce(
    (previousValue, currentValue) => previousValue + currentValue.quantity,
    0
  );

  const subTotal = payload.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.total;
  }, 0);

  const totalTicketFee = payload.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.perTicketFee * currentValue.quantity;
  }, 0);

  const eventOrderFee = totalTickets > 0 ? orderFee : 0;
  const totalDiscount = payload.reduce((previousValue, currentValue) => {
    let value = 0;
    if (voucher) {
      value = getTotalDiscount(
        currentValue.total,
        voucher.discountPercentage / 100
      );
    }
    return previousValue + value;
  }, 0);

  const getPrice = (basePrice: number) => {
    let value = 0;
    if (voucher) {
      const discount = voucher.discountPercentage / 100;
      value = basePrice - getTotalDiscount(basePrice, discount);
    } else {
      value = basePrice;
    }

    return value;
  };


  return (
    <div className="flex flex-col w-full mt-2">
      {payload.length > 0 && payload[0].eventName}
      <div>
        {payload
          .filter((o) => o.quantity > 0)
          .map((orderEventTicket, index) => {
            return (
              <div className="grid grid-cols-2 border-b" key={index}>
                <div>
                  <div>{orderEventTicket.name}</div>
                  <span>{}</span>
                  <div className="text-sm">{`${orderEventTicket.quantity} x 
                  ${currency === "PHP" ? "₱" : "$"}
                  ${getPrice(orderEventTicket.price)}`}</div>
                </div>
                <div className="flex justify-end">
                  {currency === "PHP" ? "₱" : "$"}
                  {orderEventTicket.total}
                </div>
                {orderEventTicket.perTicketFee > 0 && (
                  <>
                    <div>
                      <div>E - Ticket Fee</div>
                      <span>{}</span>
                      <div className="text-sm">{`${
                        orderEventTicket.quantity
                      } x ${currency === "PHP" ? "₱" : "$"}${
                        orderEventTicket.perTicketFee
                      }`}</div>
                    </div>
                    <div className="flex justify-end">
                      {currency === "PHP" ? "₱" : "$"}
                      {orderEventTicket.perTicketFee *
                        orderEventTicket.quantity}
                    </div>
                  </>
                )}
              </div>
            );
          })}
      </div>
      <div className="flex justify-between">
        <div className="text-md">Sub-total: </div>
        <div>
          {currency === "PHP" ? "₱" : "$"}
          {subTotal}
        </div>
      </div>
      {/* {totalDiscount > 0 && (
        <div className="flex justify-between">
          <div className="text-md">Total Discount: </div>
          <div>₱{(totalDiscount)}</div>
        </div>
      )} */}
      {totalTicketFee > 0 && (
        <div className="flex justify-between">
          <div className="text-md">Total E - Ticket Fee: </div>
          <div>
            {currency === "PHP" ? "₱" : "$"}
            {totalTicketFee}
          </div>
        </div>
      )}

      {eventOrderFee > 0 && (
        <div className="flex justify-between">
          <div className="text-md">Processing Fee: </div>
          <div>
            {currency === "PHP" ? "₱" : "$"}
            {eventOrderFee}
          </div>
        </div>
      )}
      <div className="flex justify-between">
        <div className="text-lg font-bold">TOTAL: </div>
        <div>
          <div className="">
            <div className="font-bold">{`${totalTicketQuantity} Ticket(s)`}</div>
            <div>
              {currency === "PHP" ? "₱" : "$"}
              {subTotal + totalTicketFee + eventOrderFee}.00
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
