import { Check, Clipboard, Info } from "lucide-react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { Slider } from "../../components/ui/slider";
import { Switch } from "../../components/ui/switch";
import { useToast } from "../../hooks/use-toast";

export default function PricingCalculatorPage() {
  const { toast } = useToast();
  const [basePrice, setBasePrice] = useState<number>(1000);
  const [quantity, setQuantity] = useState<number>(1);
  const [commission, setCommission] = useState<number>(10);
  const [taxRate, setTaxRate] = useState<number>(0); // 12% VAT in Philippines
  const [includeTax, setIncludeTax] = useState<boolean>(false);
  const [deductCommissionFromOrganizer, setDeductCommissionFromOrganizer] =
    useState<boolean>(true);
  const [ticketFee, setTicketFee] = useState<number>(30);
  const [orderProcessingFee] = useState<number>(30); // Fixed at 30 pesos per order
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    setTicketFee(basePrice <= 500 ? 30 : 60);
  }, [basePrice]);

  const calculatePrices = () => {
    let organiserEarnings = basePrice * quantity;
    let customerPrice = basePrice * quantity;
    let taxAmount = 0;
    let commissionAmount = ((basePrice * commission) / 100) * quantity;
    const totalTicketFee = ticketFee * quantity;
    const totalFees = totalTicketFee + orderProcessingFee;

    if (deductCommissionFromOrganizer) {
      organiserEarnings -= commissionAmount;
    } else {
      customerPrice += commissionAmount;
    }

    customerPrice += totalFees;

    if (includeTax) {
      taxAmount = (customerPrice * taxRate) / 100;
      customerPrice += taxAmount;
    } else {
      taxAmount = (customerPrice * taxRate) / (100 + taxRate);
      organiserEarnings -= taxAmount;
    }

    return {
      organiserEarnings: organiserEarnings.toFixed(2),
      customerPrice: customerPrice.toFixed(2),
      taxAmount: taxAmount.toFixed(2),
      commissionAmount: commissionAmount.toFixed(2),
      totalTicketFee: totalTicketFee.toFixed(2),
      orderProcessingFee: orderProcessingFee.toFixed(2),
    };
  };

  const prices = calculatePrices();

  const copyToClipboard = () => {
    const text = `
Ticketnation Philippines Pricing Calculation:
Base Ticket Price: ₱${basePrice}
Quantity: ${quantity}
Commission: ${commission}% (${
      deductCommissionFromOrganizer
        ? "deducted from organizer"
        : "added to customer price"
    })
Tax Rate: ${taxRate}%
Tax on top: ${includeTax ? "Yes" : "No"}
Ticket Fee: ₱${ticketFee} per ticket
Order Processing Fee: ₱${orderProcessingFee}

Results:
Organiser Earnings: ₱${prices.organiserEarnings}
Customer Price: ₱${prices.customerPrice}
Tax Amount: ₱${prices.taxAmount}
Commission Amount: ₱${prices.commissionAmount}
Total Ticket Fee: ₱${prices.totalTicketFee}
Order Processing Fee: ₱${prices.orderProcessingFee}
    `.trim();

    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      toast({
        title: "Copied to clipboard",
        description:
          "The calculation details have been copied to your clipboard.",
      });
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const InfoPopover = ({ content }: { content: string }) => (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="sm" className="h-auto p-0">
          <Info className="w-4 h-4 ml-2 text-muted-foreground cursor-help" />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <p>{content}</p>
      </PopoverContent>
    </Popover>
  );

  return (
    <>
      <Helmet>
        <title>Ticketnation Philippines Pricing Calculator</title>
        <meta
          name="description"
          content="Calculate event pricing and organizer earnings for Ticketnation Philippines events."
        />
      </Helmet>
      <div className="container px-4 py-8 mx-auto">
        <h1 className="mb-6 text-3xl font-bold text-center">
          Ticketnation Philippines Pricing Calculator
        </h1>

        <div className="max-w-3xl mx-auto mb-8">
          <p className="mb-4 text-lg">
            This calculator helps event organizers estimate their earnings and
            customer pricing when using Ticketnation Philippines. Adjust the
            variables below to see how different factors affect your event's
            pricing structure.
          </p>
          <ul className="pl-5 space-y-2 list-disc">
            <li>
              The commission can be deducted from the organizer's earnings or
              added to the customer's price.
            </li>
            <li>
              The ticket fee changes based on the quantity of tickets sold.
            </li>
            <li>
              You can choose to include or exclude tax in the final customer
              price.
            </li>
          </ul>
        </div>

        <Card className="w-full max-w-3xl mx-auto">
          <CardHeader>
            <CardTitle>Pricing Calculator</CardTitle>
            <CardDescription>
              Adjust the values to calculate ticket prices and earnings
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="space-y-2">
              <Label htmlFor="basePrice">Base Ticket Price (PHP)</Label>
              <Input
                id="basePrice"
                type="number"
                value={basePrice}
                onChange={(e) => setBasePrice(Number(e.target.value))}
                min={0}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="quantity">Ticket Quantity</Label>
              <Input
                id="quantity"
                type="number"
                value={quantity}
                onChange={(e) =>
                  setQuantity(Math.max(1, Number(e.target.value)))
                }
                min={1}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="commission" className="flex items-center">
                Ticketnation Commission (%)
                <InfoPopover content="This is the percentage Ticketnation charges for using our platform. It can be deducted from the organizer's earnings or added to the customer's price." />
              </Label>
              <Slider
                id="commission"
                min={0}
                max={30}
                step={0.1}
                value={[commission]}
                onValueChange={(value) => setCommission(value[0])}
              />
              <div className="text-sm text-muted-foreground">
                {commission.toFixed(1)}%
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <Switch
                id="deductCommissionFromOrganizer"
                checked={deductCommissionFromOrganizer}
                onCheckedChange={setDeductCommissionFromOrganizer}
              />
              <Label
                htmlFor="deductCommissionFromOrganizer"
                className="flex items-center"
              >
                Deduct commission from organizer earnings
                <InfoPopover content="If enabled, the commission will be deducted from the organizer's earnings. If disabled, it will be added to the customer's price." />
              </Label>
            </div>

            <div className="space-y-2">
              <Label htmlFor="taxRate" className="flex items-center">
                Tax Rate (%)
                <InfoPopover content="The default is set to 12% VAT for the Philippines. Adjust if necessary." />
              </Label>
              <Input
                id="taxRate"
                type="number"
                value={taxRate}
                onChange={(e) => setTaxRate(Number(e.target.value))}
                min={0}
                max={100}
              />
            </div>

            <div className="flex items-center space-x-2">
              <Switch
                id="includeTax"
                checked={includeTax}
                onCheckedChange={setIncludeTax}
              />
              <Label htmlFor="includeTax" className="flex items-center">
                Add tax on top of final price
                <InfoPopover content="If enabled, tax will be added to the final price. If disabled, tax will be included in the price and deducted from organizer earnings." />
              </Label>
            </div>

            <div className="space-y-2">
              <Label htmlFor="ticketFee" className="flex items-center">
                Ticket Fee (PHP per ticket)
                <InfoPopover content="This fee is automatically set based on quantity: ₱30 for 1-500 pesos, ₱60 for 501+ pesos." />
              </Label>
              <Input id="ticketFee" type="number" value={ticketFee} disabled />
            </div>

            <div className="space-y-2">
              <Label htmlFor="orderProcessingFee" className="flex items-center">
                Order Processing Fee (PHP per order)
                <InfoPopover content="This is a fixed fee of ₱30 per order to cover processing costs." />
              </Label>
              <Input
                id="orderProcessingFee"
                type="number"
                value={orderProcessingFee}
                disabled
              />
            </div>

            <div className="p-4 mt-6 space-y-2 rounded-lg bg-muted">
              <div className="flex items-center justify-between">
                <span className="flex items-center">
                  Organiser Earnings:
                  <InfoPopover content="The amount the organizer receives after deducting all fees and taxes." />
                </span>
                <span className="font-semibold">
                  ₱{prices.organiserEarnings}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="flex items-center">
                  Customer Price:
                  <InfoPopover content="The final price the customer pays, including all fees and taxes." />
                </span>
                <span className="font-semibold">₱{prices.customerPrice}</span>
              </div>
              <div className="flex items-center justify-between text-sm text-muted-foreground">
                <span className="flex items-center">
                  Tax Amount:
                  <InfoPopover content="The total tax amount based on the current tax rate." />
                </span>
                <span>₱{prices.taxAmount}</span>
              </div>
              <div className="flex items-center justify-between text-sm text-muted-foreground">
                <span className="flex items-center">
                  Commission Amount:
                  <InfoPopover content="The total commission charged by Ticketnation." />
                </span>
                <span>₱{prices.commissionAmount}</span>
              </div>
              <div className="flex items-center justify-between text-sm text-muted-foreground">
                <span className="flex items-center">
                  Total Ticket Fee:
                  <InfoPopover content="The total fee charged per ticket, based on the ticket price." />
                </span>
                <span>₱{prices.totalTicketFee}</span>
              </div>
              <div className="flex items-center justify-between text-sm text-muted-foreground">
                <span className="flex items-center">
                  Order Processing Fee:
                  <InfoPopover content="A fixed fee charged per order to cover processing costs." />
                </span>
                <span>₱{prices.orderProcessingFee}</span>
              </div>
            </div>

            <Button onClick={copyToClipboard} className="w-full">
              {copied ? (
                <Check className="w-4 h-4 mr-2" />
              ) : (
                <Clipboard className="w-4 h-4 mr-2" />
              )}
              {copied ? "Copied!" : "Copy Calculation Details"}
            </Button>
          </CardContent>
        </Card>

        <div className="max-w-3xl mx-auto mt-8">
          <p className="mt-4 font-semibold text-center">
            For further inquiries, please contact us at:{" "}
            <a
              href="mailto:corporate@ticketnation.ph"
              className="text-blue-600 hover:underline"
            >
              corporate@ticketnation.ph
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
