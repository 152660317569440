import { Helmet } from "react-helmet";
import { UpcomingEvents } from "../../features";
import { HomeBanner } from "../../features/Banner";

const HomePage = () => {
  return (
    <div className="flex flex-col">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ticketnation Philippines</title>
        <link rel="canonical" href="https://ticketnation.ph" />
      </Helmet>
      {/* Banner */}
      <HomeBanner />

      {/* Upcoming Events */}
      <UpcomingEvents />
    </div>
  );
};

export default HomePage;
