import React from "react";
import Banner from "../../../assets/images/artistbannerblue.png";
import ArtistSearch from "../../Search/ArtistSearch.tsx";

const ArtistBanner: React.FC = () => {
  return (
    <div className="relative">
      <div
        className="h-[208px] lg:h-[468px]"
        style={{
          background: `url(${Banner})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex items-center justify-center h-full ">
          <div>
            <div className="mt-16 text-xl font-semibold text-center text-white lg:text-2xl">
              CHECK OUT YOUR FAVORITE ARTISTS
            </div>
          </div>
        </div>
      </div>

      <ArtistSearch placeholder={"Search Artist Name"} />
    </div>
  );
};

export default ArtistBanner;
