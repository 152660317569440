import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from "../../../components/ui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import { EventTicket } from "../../../models/event.model";
import EventTicketOffer from "./EventTicketOffer";
import { EventTickets } from "./EventTickets";

interface ComponentProps {
  eventTickets: EventTicket[];
  id: string;
  orderFee: number;
  currency: string;
  dateDropDown: any;
}

const TabsDemo: React.FC<ComponentProps> = ({
  dateDropDown,
  eventTickets,
  id,
  orderFee,
  currency,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Get the active tab from query parameters
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab") || "sell"; // Default to "sell" tab

  // Update the tab in query parameters when tab is changed
  const handleTabChange = (newValue: string) => {
    queryParams.set("tab", newValue);
    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  };

  return (
    <Tabs value={activeTab} onValueChange={handleTabChange} className="">
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value="sell">Tickets for Sale</TabsTrigger>
        <TabsTrigger value="buy">Buy Requests</TabsTrigger>
      </TabsList>

      <TabsContent value="sell">
        <Card>
          <CardHeader className="p-2">
            <CardDescription>
              Browse through all the tickets currently listed by sellers. If you
              have tickets to sell, you can list them on the marketplace. Set
              your desired price and find buyers who are actively searching for
              tickets. To get started, visit{" "}
              <a
                href="https://resell.ticketnation.ph"
                className="font-semibold text-blue-400"
              >
                resell.ticketnation.ph
              </a>{" "}
              and create your listing today!
            </CardDescription>
          </CardHeader>
          <CardContent className="px-0 space-y-2">
            <EventTickets
              dateDropDown={dateDropDown}
              eventTickets={eventTickets}
              id={id}
              orderFee={orderFee}
              currency={currency}
            />
          </CardContent>
        </Card>
      </TabsContent>

      <TabsContent value="buy">
        <Card>
          <CardHeader className="p-2">
            <CardDescription>
              Explore the tickets buyers are looking to purchase. If you have
              tickets that match these requests, you can make an offer directly
              to the buyer. If you're looking to buy tickets within your budget,
              head over to{" "}
              <a
                href="https://resell.ticketnation.ph"
                className="font-semibold text-blue-400"
              >
                resell.ticketnation.ph
              </a>{" "}
              and submit your buying request with your preferred price.".
            </CardDescription>
          </CardHeader>
          <CardContent className="px-0 space-y-2">
            <EventTicketOffer eventId={id} />
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  );
};

export default TabsDemo;
