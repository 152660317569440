import React, { useContext, useState } from "react";
import { Modal } from "../../../../components/Modal";
import { ProductOrderVariant } from "../../../../models/order.model";
import OrderContext from "../../../../pages/Order/context/OrderContext";
import { OrderContextType } from "../../../../pages/Order/context/OrderContextTypes";
import { useDeleteProductOrderVariantMutation } from "../../../../services/order.api";

const ShopSummary = () => {
  const [open, setOpen] = useState(false);
  const [productOrderVariant, setProductOrderVariant] =
    useState<ProductOrderVariant | null>(null);
  const { order, event } = useContext<OrderContextType>(OrderContext);
  const [deleteProductVariant, { isLoading }] =
    useDeleteProductOrderVariantMutation();
  React.useEffect(() => {
    return () => {
      setOpen(false);
      setProductOrderVariant(null);
    };
  }, []);
  if (!order || !event) return null;
  const handleDeleteProductVariant = () => {
    if (productOrderVariant === null) return;
    deleteProductVariant({
      orderId: order?.id,
      productVariantOrderid: productOrderVariant?.id,
    });
    setOpen(false);
    setProductOrderVariant(null);
  };

  if (!order || !event) {
    return null;
  }

  const { productOrder } = order;
  const { currency } = event;

  if (isLoading) return <div>Loading...</div>;
  return (
    <div>
      {productOrder?.ProductOrderVariant &&
        productOrder?.ProductOrderVariant.length > 0 && (
          <div>
            <h2 className="font-semibold">Shop Summary</h2>
            {productOrder?.ProductOrderVariant.map((variant, index) => {
              return (
                <div className="grid grid-cols-2 border-b" key={index}>
                  <div>
                    <div>{variant.ProductVariant.name}</div>
                    <div className="text-sm">{`${variant.quantity} x ${
                      currency === "PHP" ? "₱" : "$"
                    }${variant.ProductVariant.price}`}</div>
                  </div>
                  <div className="flex items-center justify-end">
                    {currency === "PHP" ? "₱" : "$"}
                    {variant.subtotal}
                    <button
                      onClick={() => {
                        setProductOrderVariant(variant);
                        setOpen(true);
                      }}
                      className="w-8 h-8 p-1 ml-2 text-red-500 border border-red-500 rounded-full border-red border-1"
                    >
                      X
                    </button>
                  </div>
                </div>
              );
            })}
            <Modal title="Delete Product" open={open} setOpen={setOpen}>
              <div className="p-4">
                <p>Are you sure you want to delete this product?</p>
                <div className="my-2">
                  {productOrderVariant?.ProductVariant.name} - x
                  {productOrderVariant?.quantity}
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <button
                    onClick={() => handleDeleteProductVariant()}
                    className="px-3 py-2 text-white bg-red-500 border rounded-md border-red border-1"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => setOpen(false)}
                    className="px-3 py-2 border rounded-md border-blue-1100 text-blue-1100 border-1"
                  >
                    No
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        )}
    </div>
  );
};

export default ShopSummary;
