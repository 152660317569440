import {
  CloudWatchLogsClient,
  PutLogEventsCommand,
  PutLogEventsCommandInput,
} from "@aws-sdk/client-cloudwatch-logs";

type LogLevel = "LOG" | "INFO" | "WARN" | "ERROR";

class Logger {
  private readonly isProduction: boolean;
  private readonly cloudWatchClient?: CloudWatchLogsClient;
  private readonly logGroupName?: string;
  private streamTokens: Record<string, string | null> = {};

  constructor() {
    this.isProduction = process.env.REACT_APP_NODE_ENV === "production";

    if (this.isProduction) {
      this.cloudWatchClient = new CloudWatchLogsClient({
        region: process.env.REACT_APP_AWS_REGION,
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID!,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
        },
      });

      this.logGroupName = process.env.REACT_APP_CLOUDWATCH_LOG_GROUP_NAME!;
    }
  }

  // Send logs to CloudWatch
  private async logToCloudWatch(
    message: string,
    level: LogLevel = "INFO",
    context?: Record<string, any>
  ): Promise<void> {
    const logStreamName = `${level.toLowerCase()}-logs`;
    if (!this.cloudWatchClient || !this.logGroupName) return;
    try {
      // Ensure the log stream exists

      const logEvent = {
        message: JSON.stringify({
          level,
          message,
          context,
          timestamp: new Date().toISOString(),
        }),
        timestamp: Date.now(),
      };

      // Validate logEvents before sending
      const logEvents = [logEvent];
      if (logEvents.length === 0) {
        console.warn("No log events to send to CloudWatch");
        return; // Avoid sending empty payloads
      }

      const params: PutLogEventsCommandInput = {
        logGroupName: this.logGroupName,
        logStreamName,
        logEvents,
      };

      // Add sequence token if it exists
      if (this.streamTokens[logStreamName]) {
        params.sequenceToken = this.streamTokens[logStreamName]!;
      }

      const command = new PutLogEventsCommand(params);
      const response = await this.cloudWatchClient.send(command);

      // Update sequence token for next log
      this.streamTokens[logStreamName] = response.nextSequenceToken ?? null;
    } catch (error) {
      console.error(`Failed to log to CloudWatch (${level}):`, error);
    } finally {
    }
  }

  // General log method
  async log(message: string, context?: Record<string, any>): Promise<void> {
    if (!message) return; // Avoid unnecessary processing for empty messages
    if (this.isProduction) {
      await this.logToCloudWatch(message, "LOG", context);
    } else {
      console.log(message, context);
    }
  }

  async info (message: string, context?: Record<string, any>): Promise<void> {
    if (!message) return; // Avoid unnecessary processing for empty messages
    if (this.isProduction) {
     await this.logToCloudWatch(message, "INFO", context);
    } else {
      console.info(message, context);
    }
  }

  async warn(message: string, context?: Record<string, any>): Promise<void> {
    if (!message) return; // Avoid unnecessary processing for empty messages
    if (this.isProduction) {
      await this.logToCloudWatch(message, "WARN", context);
    } else {
      console.warn(message, context);
    }
  }

  async error(message: string, context?: Record<string, any>): Promise<void> {
    if (!message) return; // Avoid unnecessary processing for empty messages
    if (this.isProduction) {
     await this.logToCloudWatch(message, "ERROR", context);
    } else {
      console.error(message, context);
    }
  }
}

const logger = new Logger();

export default logger;
