import { Category } from "./category.model";
import { Image } from "./image.model";

export interface Deal {
  id: string;
  organizationId: string;
  name: string;
  description?: string;
  category: Category;
  categoryId: string;
  startDate: string;
  endDate: string;
  isFeatured: boolean;
  isPublished: boolean;
  dealDetails?: DealDetail[];
  dealVariants?: DealVariant[];
  dealImages?: DealImage[];
}

export interface CreateDeal {
  name: string;
  description?: string;
  categoryId: string;
  startDate: Date;
  endDate: Date;
  isFeatured: boolean;
  isPublished: boolean;
  dealDetails?: CreateDealDetail[];
  dealVariants?: CreateDealVariant[];
}

export interface UpdateDeal {
  id: string;
  name: string;
  description?: string;
  categoryId: string;
  startDate: string;
  endDate: string;
  isFeatured: boolean;
  isPublished: boolean;
  dealDetails?: CreateDealDetail[];
  dealVariants?: CreateDealVariant[];
}

export interface DealDetail {
  id: string;
  title: string;
  description?: string;
  inclusions?: string[];
  exclusions?: string[];
}

export interface CreateDealDetail {
  dealId: string;
  title: string;
  description?: string;
  inclusions?: string[];
  exclusions?: string[];
}

export interface DealVariant {
  id: string;
  name: string;
  isPublished: boolean;
  dealVariantImages?: DealVariantImage[];
  availabilities: DealVariantAvailability[];
}

export interface DealVariantAvailability {
  id: string;
  createdAt: string;
  updatedAt: string;
  dealVariantId: string;
  availabilityType: AvailabilityType;
  date?: string | null; // Only for SPECIFIC type
  time?: string | null; // For SPECIFIC and RANGE types
  startDate?: string | null; // Only for RANGE type
  endDate?: string | null; // Only for RANGE type
  price: number;
  quantity: number;
  sold: number;
  remaining: number;
  isPublished: boolean;
}

export enum AvailabilityType {
  SPECIFIC = "SPECIFIC",
  RANGE = "RANGE",
}

export interface CreateDealVariant {
  dealId: string;
  name: string;
  price: number;
  discountValue?: number;
  availability: number;
  isPublished: boolean;
}

export interface DealImage {
  id: string;
  isPrimary: boolean;
  image: Image;
}

export interface DealVariantImage {
  id: string;
  isPrimary: boolean;
  image: Image;
}

export interface DealsResponse {
  data: Deal[];
  skip: number;
  take: number;
  count: number;
}
