import React, { useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useUpdateUserMutation } from "../../services/user.api";
import { useAppDispatch } from "../../app/hooks";
import { updateEmail } from "../Authentication/authSlice";

interface FormValues {
  email: string;
  mobileNumber: string;
}

const initialValues: FormValues = {
  email: "",
  mobileNumber: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  mobileNumber: Yup.string().required("Required"),
});

const UpdateUserInfo = () => {
  const dispatch = useAppDispatch();
  const [updateUser, { data, isLoading, isError, isSuccess }] =
    useUpdateUserMutation();

  useEffect(() => {
    if (isSuccess) {
      dispatch(updateEmail(data?.email || ""));
    }
  }, [isSuccess, dispatch, data]);
  return (
    <div className="p-2 mx-auto space-x-4 bg-white shadow-md rounded-xl">
      <h2 className="text-black">Please update information before payment</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          updateUser(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <ErrorMessage
                name="email"
                component="div"
                className="mt-2 text-sm text-red-500"
              />
            </div>

            <div>
              <label
                htmlFor="mobileNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Mobile Number
              </label>
              <div className="mt-1">
                <Field
                  type="text"
                  id="mobileNumber"
                  name="mobileNumber"
                  className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <ErrorMessage
                name="mobileNumber"
                component="div"
                className="mt-2 text-sm text-red-500"
              />
            </div>

            <div>
              <button
                type="submit"
                className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-blue-1100 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={isLoading}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateUserInfo;
