import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SuccessPayment from "../../../components/Success/SuccessPayment";
import SuccessLogo from "../../../assets/images/paymentSuccessLogo.png";
import { useAppSelector } from "../../../app/hooks";
import {
  paymentSelect,
  setEWalletOption,
} from "../../../features/Payment/paymentSlice";
import { useDispatch } from "react-redux";

const Success: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { eWalletOption } = useAppSelector(paymentSelect);
  let [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const [active, setActive] = React.useState<boolean>(true);
  const [counter, setCounter] = React.useState(3);

  React.useEffect(() => {
    counter > 1 && setTimeout(() => setCounter(counter - 1), 1000);
    const timer = setTimeout(() => {
      navigate("/profile");

      if (eWalletOption === "PAYPAL") {
        window.close();
        window.open(`${window.location.origin}/profile`, "_self");
      }
    }, 2500);

    return () => {
      clearTimeout(timer);
      dispatch(setEWalletOption(""));
    };
  }, [counter, navigate]);

  return (
    <>
      <div className="flex items-center justify-center h-screen ">
        <div>
          <div className="mb-6">
            <img src={SuccessLogo} alt="successlogo" />
          </div>
          <div className="my-2 text-2xl font-bold text-center">
            <p>Payment Successful</p>
          </div>
          <div className="my-4 text-center">
            <p>Redirecting to Profile in {counter} ... </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;
