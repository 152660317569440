import React from "react";
import Product1 from "../../assets/images/product1.png";
import TNLogo from "../../assets/images/tnlogo.png";
import Dashboard1 from "../../assets/images/dashboard1.png";
import Pos from "../../assets/images/pos.png";
import Qrcode from "../../assets/images/qrcode.png";
import Gpple from "../../assets/images/gpple.png";
import { Helmet } from "react-helmet";

const OurProducts = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ticketnation Philippines | Our Products</title>
        <link rel="canonical" href="https://ticketnation.ph/about-us" />
      </Helmet>
      <div className="max-w-6xl mx-auto mt-12">
        <div className="flex items-center justify-center my-12">
          <div className="text-4xl font-bold text-center">Products</div>
        </div>
        <div className="flex-row mx-4 bg-white lg:flex lg:mx-0 rounded-3xl">
          <div className="flex items-center justify-center lg:w-1/2 ">
            <div className="px-6">
              <h1 className="mt-2 text-xl font-semibold text-center ">
                Ticketnation User
              </h1>
              <p className="mt-2 text-sm text-center">
                We have a ticketing platform making it easy
              </p>
              <p className="text-sm text-center">
                for event goers to book their tickets.
              </p>
              <p className="text-sm text-center ">Easy, Fast, Trusted</p>

              <h1 className="mt-4 text-xl font-semibold text-center">
                Features
              </h1>
              <p className="mt-2 text-sm text-center">
                Single Event, Group Events, Maps, Instructions,
              </p>
              <p className="text-sm text-center">
                Venue Pointers ,Description, Vouchers and Artist Information
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center lg:w-1/2 ">
            <img src={Product1} alt="ProductImage" />
          </div>
        </div>
        <div className="flex-row mx-4 mt-6 bg-white lg:flex lg:mx-0 rounded-3xl">
          <div className="flex items-center justify-center lg:w-2/5 ">
            <img src={Dashboard1} alt="Dashboard" />
          </div>
          <div className="flex items-center justify-center lg:w-3/5 ">
            <div className="px-6">
              <h1 className="text-xl font-semibold text-center ">
                Ticketnation Dashboard
              </h1>
              <p className="mt-4 text-sm text-center">
                We have a features that fit to our partner
              </p>

              <h1 className="mt-4 text-xl font-semibold text-center">
                Features
              </h1>
              <p className="mt-4 text-sm text-center">
                Orders, Inventory, Withdraw Request, Create/Edit of Events
              </p>
              <p className="text-sm text-center">
                Create/Edit Tickets, Adding of other Members
              </p>
            </div>
          </div>
        </div>

        <div className="px-4 py-12 mx-6 mt-16 shadow-xl lg:px-16 rounded-3xl">
          <div className="flex items-center justify-center">
            <div className="">
              <div className="text-2xl font-bold text-center">
                Ticketnation Forum
              </div>
              <div className="max-w-xl mt-6 text-sm text-center">
                We have a private room for discussions for the Event Producers,
                Organizers and Productions to incline the Events, Artist Pricing
                and Venues.
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row mx-4 mt-6 lg:flex lg:mx-0 rounded-3xl">
          <div className="flex items-center justify-center lg:w-2/5 ">
            <img src={Pos} alt="pos" />
          </div>
          <div className="flex items-center justify-end lg:w-3/5 ">
            <div className="px-6">
              <h1 className="text-xl font-semibold text-center lg:text-right">
                Ticketnation POS
                <span className="font-normal "> for Event Producers</span>
              </h1>

              <h1 className="mt-4 text-xl font-semibold text-center lg:text-right">
                Features
              </h1>
              <p className="mt-4 text-sm text-center">
                Plugin, Your Dashboard/Style, Manage your own Event,
              </p>
              <p className="text-sm text-center lg:text-right">
                Good for Amusements/Attraction Places
              </p>
            </div>
          </div>
        </div>
        <div className="flex mx-4 mt-6 lg:mx-0 rounded-3xl">
          <div className="flex items-center justify-start w-3/5 ">
            <div className="px-6">
              <h1 className="text-xl font-semibold ">Ticketnation Scanner</h1>
              <p className="mt-4 text-sm ">
                We have a Mobile Scanner exclusive for
              </p>
              <p className="text-sm ">
                ticketnation user to scan their QR tickets.
              </p>
            </div>
          </div>
          <div className="flex justify-end w-2/5 ">
            <img src={Qrcode} alt="qrcode" />
          </div>
        </div>

        <div className="flex items-center justify-center px-16 py-6 mt-6 bg-white ">
          <div className="">
            <h1 className="text-xl font-semibold text-center">
              Ticketnation Mobile
            </h1>
            <p className="mt-2 text-sm">
              We also have mobile application you can
            </p>
            <p className="text-sm text-center">
              download it on App Store and Playstore
            </p>

            <h1 className="mt-4 text-xl font-semibold text-center">Features</h1>
            <p className="mt-2 text-sm text-center">
              Same with User, Chat Exclusive
            </p>
            <p className="text-sm text-center">
              or Mobile App, Chat for Event Goers,
            </p>
            <p className="text-sm text-center">
              They can find event partners on the chat.
            </p>

            <div className="flex items-center justify-center mt-6 ">
              <img className="h-56" src={Gpple} alt="gpple" />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center px-2 mt-12 ">
          <div className="">
            <h1 className="text-4xl font-bold text-center">
              Fees and Commissions
            </h1>
            <p className="mt-10 text-2xl font-semibold text-center">
              7% Exclusive Ticketing Platform
            </p>
            <p className="mt-2 text-sm text-center">
              These is if we’re Exclusive Ticketing Platform for your selling
              ticket event.
            </p>

            <p className="mt-4 text-2xl font-semibold text-center">
              10% Non-exclusive Ticketing Platform
            </p>
            <p className="mt-2 text-sm text-center">
              These are if they have another Ticketing Platform Selling your
              tickets.
            </p>

            <p className="mt-4 text-2xl font-semibold text-center">5% Fees</p>
            <p className="mt-2 text-sm text-center">
              These are to provide the fees on our payment gateway,
            </p>
            <p className="mt-2 text-sm text-center">
              and also the e-mail service that helps us to make things faster.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProducts;
