const ProfileListings = () => {
  return (
    <div className="p-4">
      <h1 className="mb-4 text-2xl font-bold">Profile Listings</h1>
      <p>
        This is the Profile Settings page. Customize your account settings here.
      </p>

      <p>Coming Soon</p>
    </div>
  );
};

export default ProfileListings;
