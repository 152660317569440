import React from "react";

interface ComponentProps{
  classNames?: string;
  slot: string;
  timeout?: number;
  googleAdId: string;
  style?: React.CSSProperties
  format?: string
}

const GoogleAd : React.FC<ComponentProps> = (props) => {
  React.useEffect(() => {
    let googleInit: any = null;
    const { timeout = 200 } = props;
    googleInit = setTimeout(() => {
      if (typeof window !== "undefined")
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, timeout);
    return () => {
      if (googleInit) clearTimeout(googleInit);
    };
  }, [props]);

  const { classNames = "", slot, googleAdId, style, format } = props;

  return (
    <div className={classNames}>
      <ins
        className="adsbygoogle"
        style={style || { display: "block", textAlign: "center" }}
        data-ad-client={googleAdId}
        data-ad-slot={slot}
        data-ad-format={format || "auto"}
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default GoogleAd;
