import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";
import { Badge } from "../../../components/ui/badge";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Order } from "../../../models/order.model";
import TicketDialog from "./TicketDialog";

interface OrderListProps {
  orders: Order[];
}

const OrderList: React.FC<OrderListProps> = ({ orders }) => {
  return (
    <>
      {orders?.map((order: Order) => (
        <Card key={order.id} className="mb-2">
          <CardHeader className="flex flex-row items-center justify-between gap-2 p-2 text-lg">
            <CardTitle className="">
              <span>Order #{order.orderNumber}</span>
            </CardTitle>
            {order.isRefunded && (
              <Badge className="px-2 py-1 mt-0 text-white bg-red-400">
                Refunded
              </Badge>
            )}
          </CardHeader>
          <CardContent className="p-2">
            <Accordion type="single" collapsible className="w-full">
              {order.events.map((event, eventIndex) => (
                <AccordionItem
                  key={`${order.id}-event-${eventIndex}`}
                  value={`event-${eventIndex}`}
                >
                  <AccordionTrigger className="py-2 hover:no-underline">
                    <div className="flex items-center space-x-4 text-left">
                      <div className="relative w-16 h-16">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_BASE_URL}${event.image}`}
                          alt={event.name}
                          className="rounded-md"
                        />
                      </div>
                      <div>
                        <h3 className="font-semibold">{event.name}</h3>
                        <p className="text-sm text-gray-500">
                          {new Date(event.dateTime).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="space-y-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          {event.organization.name}
                        </p>
                        <p className="text-sm text-gray-500">
                          {event.venue.name}, {event.venue.city},{" "}
                          {event.venue.state}
                        </p>
                      </div>
                      <div className="space-y-2">
                        {event.tickets.map((ticket, ticketIndex) => (
                          <div
                            key={`${order.id}-ticket-${ticketIndex}`}
                            className="flex items-center justify-between p-2 rounded-md bg-gray-50"
                          >
                            <div>
                              <p className="font-medium">{ticket.type}</p>
                              <p className="text-sm text-gray-500">
                                Section {ticket.section}, Row {ticket.row}, Seat{" "}
                                {ticket.seat}
                              </p>
                              <p className="text-sm">
                                Price:{" "}
                                {new Intl.NumberFormat("en-PH", {
                                  style: "currency",
                                  currency: "PHP",
                                }).format(ticket?.price ?? 0)}
                                {ticket?.price !== ticket.originalPrice && (
                                  <span className="ml-2 text-gray-400 line-through">
                                    {new Intl.NumberFormat("en-PH", {
                                      style: "currency",
                                      currency: "PHP",
                                    }).format(ticket?.originalPrice ?? 0)}
                                  </span>
                                )}
                              </p>
                            </div>
                            <div className="flex flex-col items-end space-y-2">
                              <Badge
                                variant={
                                  ticket.isClaimed ? "secondary" : "default"
                                }
                              >
                                {ticket.isClaimed ? "Claimed" : "Unclaimed"}
                              </Badge>
                              {!event.isResale && (
                                <TicketDialog
                                  order={order}
                                  event={event}
                                  ticket={ticket}
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default OrderList;
