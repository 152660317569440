import React from "react";
import { useGetBannerImagesQuery } from "../../services/banner.api";
import Search from "./Search";
import Banner from "../../assets/images/Banner.png";
import { Carousel } from "../../components/Carousel/Carousel";
import Loading from "../../components/Loading";

export const HomeBanner = () => {
  const { data, isLoading } = useGetBannerImagesQuery({});
  const openInNewTab = (url: string): void => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  if (isLoading) return <Loading />;
  const getButtonPosition = (position?: string) => {
    switch (position) {
      case "left":
        return "left-4 bottom-4";
      case "right":
        return "right-4 bottom-4";
      case "center":
        return "left-1/2 bottom-4 transform -translate-x-1/2 -translate-y-1/2 ";
      default:
        return "left-1/2 bottom-4 transform -translate-x-1/2 -translate-y-1/2 ";
    }
  };
  return (
    <div className="relative">
      {data && data.length > 0 ? (
        <Carousel autoSlide>
          {data
            .slice()
            .filter((data) => data.image)
            .sort((a, b) => a.position - b.position)
            .map((carousel, index) => (
              <div
                key={index}
                id={`carousel-${index}`}
                style={{
                  background: `url(${
                    process.env.REACT_APP_IMAGE_BASE_URL +
                    carousel.image.fileName
                  })`,
                  height: "100%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
                className="relative"
              >
                <div className="flex items-center justify-center h-full">
                  <h2 className="text-4xl font-semibold text-white bg-none">
                    {carousel.title}
                  </h2>
                </div>
                {carousel.buttonText && carousel.buttonUrl && (
                  <div
                    className={`absolute ${getButtonPosition(
                      carousel.buttonPosition
                    )}`}
                  >
                    <button
                      className="relative px-3 py-2 font-semibold text-white rounded-md shadow-md bg-blue-1100 h-14 hover:bg-blue-600"
                      onClick={() => openInNewTab(carousel.buttonUrl || "")}
                    >
                      {carousel.buttonText}
                      <span className="absolute p-1 text-xs text-white bg-red-500 rounded-md shadow-md -right-2 -top-2 animate-pulse">New</span>
                    </button>
                  </div>
                )}
              </div>
            ))}
        </Carousel>
      ) : (
        <div
          className=""
          style={{
            background: `url(${Banner})`,
            height: "360px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="flex items-center justify-center ">
            <div>
              <div className="flex justify-center mt-20 text-4xl text-white">
                LOREM IPSUM
              </div>
              <div className="mt-6 text-xl text-center text-white lg:text-2xl">
                Amet venenatis nam fermentum est dignissim ac nunc, in.
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="px-2 mb-2 lg:px-0 lg:mb-4">
        <Search placeholder={"Search Event, Performer, Genre"} />
      </div>
    </div>
  );
};
