import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { Carousel } from "../models/carousel.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

export const bannerApi = createApi({
  reducerPath: "bannerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      // Add custom headers here
      headers.set("X-Pinggy-No-Screen", "CustomUserAgent/1.0");
      return headers;
    },
    // prepareHeaders: (headers, { getState, endpoint }) => {
    //   let jwtToken;
    //   if (endpoint === "refresh" || endpoint === "logout") {
    //     jwtToken = localStorage.getItem("refreshToken");
    //   } else {
    //     jwtToken = (getState() as RootState).auth.refreshToken;
    //   }
    //   if (jwtToken) {
    //     headers.set("Authorization", `Bearer ${jwtToken}`);
    //   }
    //   return headers;
    // },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  endpoints: (builder) => ({
    getBannerImages: builder.query<Carousel[], unknown>({
      query: () => ({
        url: `/public/carousel`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetBannerImagesQuery } = bannerApi;
