import {
  ArrowRight,
  ClipboardList,
  CreditCard,
  DollarSign,
  MessageSquare,
  Search,
  ShieldCheck,
  Zap,
} from "lucide-react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import bannerImage from "../../../assets/images/resell/banner-3.png";
import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { ResellBreadCrumbs } from "../ResellBreadcrumbs";

export default function BuyerHub() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Find Your Next Event Ticket | TicketNation</title>
        <meta
          name="description"
          content="Looking for tickets to sold-out events? Browse through listings or request tickets at your preferred price. Get notified when sellers match your offer!"
        />
        <meta
          name="keywords"
          content="buy tickets, event tickets, ticket marketplace, TicketNation"
        />
      </Helmet>
      <div className="min-h-screen bg-gray-50">
        <ResellBreadCrumbs
          items={[{ label: "Resell", href: "/resell" }, { label: "Buyers" }]}
        />

        <header
          className="py-20 text-white bg-blue-1100"
          style={{
            backgroundImage: `url(${bannerImage})`,
            backgroundSize: "cover",
          }}
        >
          <div className="container px-4 mx-auto">
            <h1 className="mb-4 text-4xl font-bold">
              Find Your Next Event Ticket
            </h1>
            <p className="mb-8 text-xl">
              Looking for tickets to sold-out events? Browse through listings or
              request tickets at your preferred price. Get notified when sellers
              match your offer!
            </p>
            <div className="flex flex-col gap-4 sm:flex-row">
              <Button
                size="lg"
                className="bg-white text-blue-1100 hover:bg-blue-50"
                onClick={() => {
                  navigate("/search");
                }}
              >
                Browse Tickets
                <Search className="w-4 h-4 ml-2" />
              </Button>
              <Button
                size="lg"
                variant="outline"
                className="text-white bg-transparent border-white hover:bg-blue-1100"
                onClick={() =>
                  (window.location.href = "https://resell.ticketnation.ph")
                }
              >
                Submit a Request
                <ArrowRight className="w-4 h-4 ml-2" />
              </Button>
            </div>
          </div>
        </header>

        <main className="container px-4 py-16 mx-auto">
          <section className="mb-20">
            <h2 className="mb-8 text-3xl font-bold text-center">
              How Buying on TicketNation Works
            </h2>
            <div className="grid gap-8 md:grid-cols-2">
              {[
                {
                  title: "Browse Listings",
                  icon: <Search className="w-8 h-8 mb-4" />,
                  description:
                    "Search for tickets to your favorite events. Whether it's concerts, sports, or shows, you can find tickets for sale from trusted sellers.",
                },
                {
                  title: "Submit a Buyer Request",
                  icon: <ClipboardList className="w-8 h-8 mb-4" />,
                  description:
                    "If you have a specific budget, submit a request, and let sellers reach out to you with matching offers.",
                },
                {
                  title: "Receive Offers",
                  icon: <MessageSquare className="w-8 h-8 mb-4" />,
                  description:
                    "Once a seller finds your request, they will send an offer for your approval.",
                },
                {
                  title: "Secure Checkout",
                  icon: <CreditCard className="w-8 h-8 mb-4" />,
                  description:
                    "Once you accept the offer or purchase directly, we handle the transaction securely, so your tickets and payments are safe.",
                },
              ].map((item, index) => (
                <Card key={index}>
                  <CardHeader>
                    <CardTitle className="flex flex-col items-center">
                      {item.icon}
                      {item.title}
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-center text-gray-600">
                      {item.description}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </section>

          <section className="mb-20">
            <h2 className="mb-8 text-3xl font-bold text-center">
              Why Buy with TicketNation
            </h2>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
              {[
                {
                  title: "Trusted Sellers",
                  icon: <ShieldCheck className="w-8 h-8 mb-4" />,
                  description:
                    "All tickets are sold by verified sellers, ensuring authenticity.",
                },
                {
                  title: "Find Tickets Faster",
                  icon: <Zap className="w-8 h-8 mb-4" />,
                  description:
                    "Explore available tickets or submit a request and get notified when sellers respond.",
                },
                {
                  title: "Secure Transactions",
                  icon: <CreditCard className="w-8 h-8 mb-4" />,
                  description:
                    "Our secure payment gateway ensures you're covered throughout the process.",
                },
                {
                  title: "Transparent Pricing",
                  icon: <DollarSign className="w-8 h-8 mb-4" />,
                  description:
                    "Know exactly what you're paying – no hidden fees.",
                },
              ].map((item, index) => (
                <Card key={index}>
                  <CardHeader>
                    <CardTitle className="flex flex-col items-center">
                      {item.icon}
                      {item.title}
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-center text-gray-600">
                      {item.description}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </section>

          <section className="text-center">
            <h2 className="mb-4 text-3xl font-bold">
              Ready to get your tickets?
            </h2>
            <p className="mb-8 text-xl">
              Find your next event and enjoy a hassle-free purchase experience!
            </p>
            <div className="flex flex-col justify-center gap-4 sm:flex-row">
              <Button
                size="lg"
                className="text-white bg-blue-1100 hover:bg-blue-1100"
                onClick={() => {
                  navigate("/search");
                }}
              >
                Browse Tickets
                <Search className="w-4 h-4 ml-2" />
              </Button>
              <Button
                size="lg"
                variant="outline"
                onClick={() =>
                  (window.location.href = "https://resell.ticketnation.ph")
                }
              >
                Submit a Request
                <ArrowRight className="w-4 h-4 ml-2" />
              </Button>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}
