import React from "react";
import TNBanner from "../../assets/images/tnbanner.png";
import LoginBanner from "../../assets/images/loginbanner.png";
import Event1 from "../../assets/images/image1.png";
import Event2 from "../../assets/images/image2.png";
import Image3 from "../../assets/images/image3.png";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Business = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ticketnation Philippines | Business</title>
        <link rel="canonical" href="https://ticketnation.ph/about-us" />
      </Helmet>
      <div className="max-w-4xl mx-auto mt-12">
        <div className="flex px-6 py-20 mx-4 bg-white lg:mx-0 rounded-3xl">
          <div className="flex items-center w-1/2 ">
            <div>
              <p className="text-4xl font-bold ">Onboard Now!</p>
              <p className="text-md ">
                Your Fast, Easy, Trusted to book events online using Mobile and
                Web Application.
              </p>
              <button
                onClick={() => navigate(`/about-us?tab=Contact Us`)}
                className="px-4 py-2 mt-4 font-bold text-white rounded-full bg-blue-1100"
              >
                GET STARTED!
              </button>
            </div>
          </div>
          <div className="flex items-center justify-center w-1/2 ">
            <img src={TNBanner} alt="TNBanner" />
          </div>
        </div>
      </div>
      <div className="px-4 py-12 mx-6 mt-16 shadow-xl lg:px-16 rounded-3xl">
        <div className="flex items-center justify-center">
          <div className="">
            <div className="text-4xl font-bold text-center">How it works</div>
            <div className="max-w-xl mt-12 text-center">
              All-in-one Online Event ticketing Platform that automates ticket
              and can manage your event to streamline the concerts & live events
              including the online payment transaction and generating QR ticket.
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mt-12">
          <div className="">
            <div className="text-2xl font-bold text-center">
              Login/Create Account
            </div>
            <div className="mt-12 font-semibold text-center">
              Enter your information to create account/ you can link your
              Google, or Facebook Account.
            </div>
            <div className="flex items-center justify-center ">
              <img src={LoginBanner} alt="LoginBanner" />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 mt-12 lg:grid-cols-2 justify-items-center">
          <div className="">
            <div className="text-xl font-semibold text-center">Events</div>
            <div className="mt-6 text-center">Choose your desire event.</div>
            <div className="flex items-center justify-center mt-2 ">
              <img src={Event1} alt="Event1" />
            </div>
          </div>
          <div>
            <div className="text-xl font-semibold text-center">Tickets</div>
            <div className="max-w-lg mt-2 text-center">
              Ticket tracking on your profile which is Qr Ticket also providing
              a offline copy through your e-mail.
            </div>
            <div className="flex items-center justify-center ">
              <img src={Event1} alt="Event1" />
            </div>
          </div>
        </div>
        <div className="">
          <div className="text-xl font-semibold text-center">Payment</div>
          <div className="my-4 text-center">
            Can choose your payment via Digital wallet/Bank
          </div>
          <div className="flex items-center justify-center ">
            <img src={Event2} alt="Event1" />
          </div>
        </div>

        <div className="flex-row py-20 mt-12 lg:flex">
          <div className="flex items-center lg:w-1/2 ">
            <div className="space-y-4">
              <p className="mb-10 text-4xl font-bold ">How to be a partner</p>
              <p className="text-lg font-semibold ">
                Steps on How to be Partner (Event and Attractions)
              </p>
              <p className="text-sm">
                Sign up and create account it only takes 3 minutes to set-up
              </p>
              <p className="text-sm">
                Dashboard (Both) - We provide your own dashboard so you can
                manage your events and tickets.
              </p>
              <p className="text-sm">
                Negotiation (Event Only) - We’ll talk about the offers and our
                inclusions.
              </p>
              <p className="text-sm">
                Start Selling tickets (Both) - Can create your own event and
                pricing.
              </p>
              <p className="text-sm">
                Manage your events (Attractions) - Get detailed data on tickets
                sales.Order confirmation and email ticket delivery is all
                automated.
              </p>
              <p className="text-sm ">
                POS (Attractions) - Support for Attractions or Amusement Parks.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center shadow-xl lg:w-1/2 ">
            <img src={Image3} alt="laptop" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;
