import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../app/store";
import { TnSellListing } from "../models/resell.model";

// Interface for errors
export interface CustomError {
  data: {
    message: string;
  };
  status: number;
}

// Define response structure for fetching BuyRequests
interface BuyRequestsResponse {
  data: TnSellListing[];
  skip: number;
  take: number;
}

interface BuyRequestParams {
  skip?: number;
  take?: number;
  search?: string;
}

export const buyerRequestApi = createApi({
  reducerPath: "buyerRequestApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/buyer-requests",
    prepareHeaders: (headers, { getState, endpoint }) => {
      headers.set("X-Pinggy-No-Screen", "CustomUserAgent/1.0");
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["BuyRequest"],
  endpoints: (builder) => ({
    // List Buy Requests
    getBuyRequests: builder.query<BuyRequestsResponse, BuyRequestParams>({
      query: ({ take, skip, search }) => {
        return {
          url: "",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["BuyRequest"],
    }),
  }),
});

export const { useGetBuyRequestsQuery } = buyerRequestApi;
