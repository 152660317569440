import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import usePlacesAutoComplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Image } from "../../../models/image.model";

interface ComponentProps {
  image: Image;
}

type Library = "places" | "drawing" | "geometry" | "visualization";
const libraries: Library[] = ["places"];

const EventVenueMap: React.FC<ComponentProps> = ({ image }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY || "",
    libraries,
  });

  const [view, setView] = useState(false);
  const [center, setCenter] = useState<
    { lat: number; lng: number } | undefined
  >();

  const containerStyle = {
    width: "100%",
    height: "400px",
    display: "flex",
  };

  if (!isLoaded) {
    return <h1>Loading ...</h1>;
  }

  return (
    <div
      className={`md:col-span-2 rounded-md overflow-hidden my-2 md:my-5 ${
        view && "border-b-2  border-gray-300"
      }`}
    >
      <button
        className="flex justify-between w-full px-2 py-2 text-white bg-blue-1100"
        onClick={() => setView(!view)}
      >
        <p className="font-semibold uppercase ">Venue Map</p>
        <p className="flex items-center gap-5">
          Click Me for the Map View
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`h-6 transition-all duration-500 ${
              view && "rotate-180"
            }`}
          />
        </p>
      </button>
      <div
        className={`transition-all duration-500 ${
          view ? "max-h-[500px]" : "max-h-0"
        }`}
      >
        {view && center && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={19}
            clickableIcons={false}
            options={{
              disableDefaultUI: true,
              zoomControl: true,
              keyboardShortcuts: false,
            }}
          >
            <Marker position={center} />
          </GoogleMap>
        )}
        <img
          className="object-contain object-center h-72"
          src={process.env.REACT_APP_IMAGE_BASE_URL + image.fileName}
          alt="venue_map"
        />
      </div>
    </div>
  );
};

interface PlacesLoadProps {
  venueLocation?: string;
  setCenter?: React.Dispatch<
    React.SetStateAction<
      | {
          lat: number;
          lng: number;
        }
      | undefined
    >
  >;
}

const PlacesLoad: React.FC<PlacesLoadProps> = ({
  venueLocation,
  setCenter,
}) => {
  const {
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutoComplete();

  const suggestion = data[0];

  useEffect(() => {
    setValue(venueLocation || "");
  }, [setValue, venueLocation]);

  useEffect(() => {
    const handleResults = async () => {
      if (suggestion) {
        const results = await getGeocode({
          address: suggestion.description,
        });
        const { lat, lng } = await getLatLng(results[0]);

        if (setCenter) {
          setCenter({
            lat,
            lng,
          });
        }
      }
    };

    if (status === "OK" && suggestion) {
      handleResults();
    }

    return () => {
      clearSuggestions();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestion, setCenter, clearSuggestions, status]);

  return null;
};

export default EventVenueMap;
export { PlacesLoad };
