import {
  ArtistInquiry,
  CreateArtistInquiry,
} from "./../models/artist-inquiry.model";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface ArtistInquiryies {
  data: ArtistInquiry[];
  skip: number;
  take: number;
  count: number;
}

interface ArtistInquiryiesQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const artistInquiryApi = createApi({
  reducerPath: "artistInquiryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/public/artist-inquiries",
    prepareHeaders: (headers) => {
      // Add custom headers here
      headers.set("X-Pinggy-No-Screen", "CustomUserAgent/1.0");

      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["ArtistInquiry", "ArtistInquiries"],
  endpoints: (builder) => ({
    createArtistInquiry: builder.mutation<
      ArtistInquiryies,
      CreateArtistInquiry
    >({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useCreateArtistInquiryMutation } = artistInquiryApi;
