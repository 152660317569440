import React from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { Voucher } from "../../models/voucher.model";
import {
  CustomError,
  useLazyVoucherValidateQuery,
} from "../../services/user.api";
import { selectedEventSelect } from "../Event/SelectedEvent/eventSlice";
import { authSelect } from "../Authentication/authSlice";
interface ComponentProps {
  eventId: string;
  setVoucher: (value: Voucher | undefined) => void;
}

export const VoucherValidate: React.FC<ComponentProps> = ({
  eventId,
  setVoucher,
}) => {
  const { user } = useAppSelector(authSelect);
  const { voucher } = useAppSelector(selectedEventSelect);
  const [code, setCode] = React.useState("");
  const [validateCode, { isError, error, data }] =
    useLazyVoucherValidateQuery();
  const [searchParams] = useSearchParams();

  const referral = searchParams.get("referral");

  React.useEffect(() => {
    if (referral) {
      const encodedCode = encodeURIComponent(referral).toString();
      validateCode({ encodedCode, eventId })
        .unwrap()
        .then((value: Voucher) => {
          setVoucher(value);
        })
        .catch((err) => {
          setVoucher(undefined);
        });
    }
  }, [referral]);

  const handleSubmit = () => {
    window.fbq('trackCustom', 'ApplyVoucher', {
      em: user?.email ? user?.email.toLowerCase() : "",
      ln: user?.lastName ? user?.lastName.toLowerCase() : "",
      fn: user?.firstName ? user?.firstName.toLowerCase() : "",
    });
    if (code) {
      const encodedCode = encodeURIComponent(code).toString();
      validateCode({ encodedCode, eventId })
        .unwrap()
        .then((value: Voucher) => {
          setVoucher(value);
        })
        .catch((err) => {
          setVoucher(undefined);
        });
    }
  };

  const handleVoucherRemove = () => {
    setVoucher(undefined);
    setCode("");
  };

  return (
    <>
      {voucher ? (
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
          <p className="text-lg">
            Successfully applied Code:{" "}
            <span className="text-xl font-semibold underline uppercase text-blue-1100">
              {voucher.code}
            </span>
          </p>
          {data?.type !== "REFERRAL" && (
            <button
              onClick={handleVoucherRemove}
              className="px-4 py-2 text-xl font-semibold text-white bg-red-500 rounded-sm"
            >
              Remove Code
            </button>
          )}
        </div>
      ) : (
        <>
          <div className="flex items-center mb-1">
            <input
              className="flex-1 p-2 border rounded-sm border-blue-1100"
              name="code"
              placeholder="Voucher Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          {isError && (
            <span className="text-red-400">
              {(error as CustomError).data.message}
            </span>
          )}
          <button
            className="w-full p-2 font-semibold text-white uppercase border rounded-sm drop-shadow bg-blue-1100 hover:border-blue-1100 hover:bg-white hover:text-blue-1100"
            onClick={handleSubmit}
          >
            Apply Code
          </button>
        </>
      )}
    </>
  );
};
