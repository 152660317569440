import { faCartPlus, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Modal } from "../../../../../components/Modal";
import { formatCurrency } from "../../../../../helpers/priceHelper";
import { ProductOrderVariant } from "../../../../../models/order.model";
import { Product, ProductVariant } from "../../../../../models/product.model";
import OrderContext from "../../../../../pages/Order/context/OrderContext";
import { useGetProductQuery } from "../../../../../services/event.api";
import {
  useAddProductOrderMutation,
  useUpdateProductOrderMutation,
} from "../../../../../services/order.api";

interface ComponentProps {
  product: Product;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectedProduct: React.FC<ComponentProps> = ({ product, setIsOpen }) => {
  const { order } = useContext(OrderContext);
  const { data, isLoading } = useGetProductQuery(product.id);
  const [
    addProductOrder,
    { isSuccess: addProductSuccess, isLoading: addProductLoading },
  ] = useAddProductOrderMutation();
  const [
    updateProductOrder,
    { isSuccess: updateProductSuccess, isLoading: updateProductLoading },
  ] = useUpdateProductOrderMutation();

  const [selectedVariant, setSelectedVariant] = useState<ProductVariant | null>(
    null
  );
  const [productOrderVariant, setProductOrderVariant] =
    useState<ProductOrderVariant | null>(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState("");
  const [openMoreDetails, setOpenMoreDetails] = useState(false);

  useEffect(() => {
    // Checking Selected Variant to set initial image
    if (
      selectedVariant?.ProductVariantImage &&
      selectedVariant?.ProductVariantImage?.length > 0 &&
      selectedVariant?.ProductVariantImage[0]?.image.fileName
    )
      setSelectedImage(
        process.env.REACT_APP_IMAGE_BASE_URL +
          selectedVariant?.ProductVariantImage[0]?.image.fileName
      );
    if (!order?.productOrder?.ProductOrderVariant || !selectedVariant) return;

    const foundProductOrderVariant =
      order.productOrder.ProductOrderVariant.find(
        (productOrderVariant: ProductOrderVariant) =>
          productOrderVariant.ProductVariant.id === selectedVariant.id
      );

    if (foundProductOrderVariant) {
      setProductOrderVariant(foundProductOrderVariant);
      setQuantity(foundProductOrderVariant.quantity);
    } else {
      setProductOrderVariant(null);
      setQuantity(1);
    }
  }, [order, selectedVariant]);

  useEffect(() => {
    if (updateProductSuccess || addProductSuccess) {
      setIsOpen(false);
    }
  }, [addProductSuccess, updateProductSuccess, setIsOpen]);

  useEffect(() => {
    if (!data) return;
    if (!data.ProductVariant) return;
    if (data.ProductVariant.length > 0) {
      setSelectedVariant(data.ProductVariant[0] ?? null);
      if (data.ProductVariant[0]?.ProductVariantImage.length > 0) {
        setSelectedImage(
          process.env.REACT_APP_IMAGE_BASE_URL +
            data.ProductVariant[0]?.ProductVariantImage[0].image.fileName ??
            null
        );
      }
    }
  }, [data?.ProductVariant, data]);

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(parseInt(event.target.value));
  };

  const handleIncrement = useCallback(() => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  }, []);

  const handleDecrement = useCallback(() => {
    setQuantity((prevQuantity) =>
      prevQuantity > 1 ? prevQuantity - 1 : prevQuantity
    );
  }, []);

  const handleAddProductOrder = useCallback(() => {
    if (selectedVariant && order) {
      addProductOrder({
        productOrderId: order.productOrder?.id || "",
        id: selectedVariant.id,
        orderId: order.id || "",
        quantity,
      });
    }
  }, [selectedVariant, order, addProductOrder, quantity]);

  const handleUpdateProductOrder = useCallback(() => {
    if (productOrderVariant && order) {
      updateProductOrder({
        orderId: order.id || "",
        productVariantOrderid: productOrderVariant.id || "",
        quantity,
      });
    }
  }, [productOrderVariant, order, updateProductOrder, quantity]);

  const getPriceRange = useCallback(
    (productVariant: ProductVariant[]): [number, number] => {
      if (productVariant.length === 0) {
        return [0, 0];
      }

      let minPrice = productVariant[0].price;
      let maxPrice = productVariant[0].price;

      for (const product of productVariant) {
        minPrice = Math.min(minPrice, product.price);
        maxPrice = Math.max(maxPrice, product.price);
      }

      return [minPrice, maxPrice];
    },
    []
  );

  const displayStringAndNumberProps = useCallback(
    (obj: ProductVariant): React.ReactNode[] => {
      const excludedProps = [
        "id",
        "createdAt",
        "updatedAt",
        "productId",
        "ProductVariantImage",
        "ProductVariantOption",
        "ProductVariantOptionValue",
        "ProductVariantPrice",
        "ProductVariantStock",
        "ProductVariantTax",
        "ProductVariantWeight",
      ];
      return Object.entries(obj)
        .filter(
          ([key]) =>
            !excludedProps.includes(key) &&
            (typeof obj[key] === "string" || typeof obj[key] === "number")
        )
        .map(([key, value]) => (
          <div key={key} className="">
            <p className="font-semibold">{key}:</p>
            <p className="pl-5">- {value}</p>
          </div>
        ));
    },
    []
  );

  if (isLoading || addProductLoading || updateProductLoading)
    return <div>Loading...</div>;
  const range = getPriceRange(data?.ProductVariant || []);

  return (
    <div className="p-2">
      <div className="w-full">
        {product.image ? (
          <div
            className="w-full bg-no-repeat bg-cover rounded-xl h-[300px] md:h-[400px] flex items-end justify-center overflow-hidden"
            style={{
              backgroundImage: `url(${
                (selectedImage.length > 0 && selectedImage) ||
                process.env.REACT_APP_IMAGE_BASE_URL + product.image.fileName
              })`,
            }}
            onMouseLeave={() => {
              setSelectedImage("");
            }}
          >
            <div className="flex items-center justify-between w-full p-4 text-2xl font-semibold text-white bg-gradient-to-b from-black to-transparent backdrop-blur-md">
              <h1 className="text-xs md:text-md">{product.name}</h1>
              <span className="text-xs md:text-md">
                {`${formatCurrency(range[0])} - ${formatCurrency(range[1])}`}
              </span>
            </div>
          </div>
        ) : (
          <div className="w-full bg-gray-300 rounded-xl h-[400px] flex items-end justify-center overflow-hidden">
            <div className="flex items-center justify-between w-full p-4 text-2xl font-semibold text-white bg-gradient-to-b from-black to-transparent backdrop-blur-md">
              <h1>{product.name}</h1>
              <span className="text-xs md:text-md">
                {`${formatCurrency(range[0])} - ${formatCurrency(range[1])}`}
              </span>
            </div>
          </div>
        )}
        {selectedVariant &&
          selectedVariant?.ProductVariantImage?.length > 0 && (
            <div className="grid grid-cols-4 gap-1 p-2 mt-2 bg-gray-100 rounded-md">
              {selectedVariant.ProductVariantImage.map(({ image }) => (
                <img
                  src={process.env.REACT_APP_IMAGE_BASE_URL + image.fileName}
                  alt={product.name}
                  className={`w-24 h-24 rounded-md `}
                  onClick={() =>
                    setSelectedImage(
                      process.env.REACT_APP_IMAGE_BASE_URL + image.fileName
                    )
                  }
                  onMouseEnter={() => {
                    setSelectedImage(
                      process.env.REACT_APP_IMAGE_BASE_URL + image.fileName
                    );
                  }}
                />
              ))}
            </div>
          )}
      </div>
      <p className="text-lg font-semibold">
        Price:{" "}
        <span className="text-green-400">
          {formatCurrency(selectedVariant?.price)}
        </span>
      </p>
      <div className="flex flex-wrap items-center gap-1 py-2">
        <p className="text-lg font-semibold">Variants:</p>
        {data?.ProductVariant.map((variant: any) => (
          <button
            onClick={() => setSelectedVariant(variant)}
            key={variant.id}
            className={`px-2 py-1 shadow-md ${
              variant.id === selectedVariant?.id
                ? "border-blue-1100 border-2 text-blue-1100 font-semibold "
                : "font-medium"
            } rounded-md`}
          >
            {variant.name}
          </button>
        ))}
      </div>
      <div className="flex flex-wrap items-center gap-2 py-2">
        <p className="text-lg font-semibold">Details:</p>
        {selectedVariant && (
          <>
            <p>Size: {selectedVariant?.size}</p>
            <p>Color: {selectedVariant?.color}</p>
            <button
              onClick={() => setOpenMoreDetails(true)}
              className="px-2 py-1 text-xs font-semibold border-2 rounded-md border-blue-1100 text-blue-1100 md:text-md"
            >
              More Details
            </button>
          </>
        )}
      </div>
      <div className="flex items-center">
        <div className="mb-2">
          {/* <h3 className="font-semibold">Quantity</h3> */}
          <div className="flex items-center ">
            <button
              type="button"
              className="flex items-center justify-center w-8 h-8 text-gray-600 transition duration-150 ease-in-out border border-gray-300 rounded-full shadow-md hover:text-gray-900 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700"
              onClick={handleDecrement}
            >
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <input
              type="number"
              name="quantity"
              id="quantity"
              className="w-10 py-2 text-center border-gray-300 rounded appearance-none focus:outline-none focus:ring focus:ring-blue-200 focus:border-blue-500"
              min="1"
              max="999"
              value={quantity}
              onChange={handleQuantityChange}
            />
            <button
              type="button"
              className="flex items-center justify-center w-8 h-8 text-gray-600 transition duration-150 ease-in-out border border-gray-300 rounded-full shadow-md hover:text-gray-900 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700"
              onClick={handleIncrement}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>
        <div className="w-full p-2">
          {order?.productOrder?.ProductOrderVariant.some(
            (productOrderVariant: ProductOrderVariant) => {
              return (
                productOrderVariant.ProductVariant.id === selectedVariant?.id
              );
            }
          ) ? (
            <button
              onClick={() => handleUpdateProductOrder()}
              className={`px-4 py-3 text-xl text-white rounded-md w-full gap-3 flex items-center justify-center ${
                selectedVariant ? "bg-blue-1100" : "bg-gray-500"
              }`}
            >
              Update Cart
            </button>
          ) : (
            <button
              disabled={!selectedVariant}
              className={`w-full px-4 py-3 text-xl text-white rounded-md gap-3 flex items-center justify-center ${
                selectedVariant ? "bg-blue-1100" : "bg-gray-500"
              }`}
              onClick={() => {
                handleAddProductOrder();
              }}
            >
              <span>Add to Cart</span>
              <FontAwesomeIcon className="mr-2" icon={faCartPlus} />
            </button>
          )}
        </div>
      </div>

      <Modal
        setOpen={setOpenMoreDetails}
        open={openMoreDetails}
        title="More Details"
      >
        <div id="output-container" className="flex flex-col p-5">
          {selectedVariant && displayStringAndNumberProps(selectedVariant)}
        </div>
      </Modal>
    </div>
  );
};

export default SelectedProduct;
