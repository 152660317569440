import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import {
  SignInFormValues,
  SignupFormValues,
} from "../../../interfaces/auth/Interface";
import logger from "../../../lib/logger";
import {
  useSigninMutation,
  useSignupMutation,
} from "../../../services/auth.api";
import { setUser } from "../authSlice";
import SignIn from "../SignIn";
import SignUp from "../SignUp";

interface ComponentProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthModal: React.FC<ComponentProps> = ({ open, setOpen }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [
    signin,
    { data, isSuccess, isLoading, error: errorSignIn, isError: isErrorSignIn },
  ] = useSigninMutation();
  const [signIn, setSignIn] = React.useState(true);

  // Sign In
  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      logger.info("Sign-in successful", { user: data.userId });
      setOpen(false);
      localStorage.setItem("refreshToken", data.refreshToken);
      dispatch(
        setUser({
          refreshToken: data.refreshToken,
          accessToken: data.accessToken,
        })
      );
    } else if (isErrorSignIn) {
      logger.error("Sign-in error", { error: errorSignIn });
    }
  }, [
    isSuccess,
    isLoading,
    isErrorSignIn,
    data,
    dispatch,
    setOpen,
    errorSignIn,
  ]);

  const handleSubmitSignIn = (values: SignInFormValues) => {
    logger.info("Attempting sign-in", { email: values.email });
    signin(values);
  };

  // Sign Up
  const [
    signup,
    { error, isError, isSuccess: isSuccessSignUp, isLoading: isLoadingSignUp },
  ] = useSignupMutation();

  React.useEffect(() => {
    if (isSuccessSignUp) {
      logger.info("Sign-up successful");
      navigate("/signup/success");
    } else if (isError) {
      logger.error("Sign-up error", { error });
    }
  }, [isSuccessSignUp, isError, error, navigate]);

  const handleSubmitSignUp = (values: SignupFormValues) => {
    logger.info("Attempting sign-up", { email: values.email });
    signup(values);
  };

  return (
    <>
      {open ? (
        <div
          id="auth-modal"
          aria-hidden="true"
          className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center h-screen overflow-y-auto bg-gray-400 bg-opacity-50 md:inset-0 h-modal md:h-full"
        >
          <div
            className={`relative max-w-2xl bg-white rounded-sm min-h-32 px-3`}
          >
            <div className="absolute right-0 z-20 pt-3 pr-3">
              <button
                className={`px-4 py-1 border rounded-sm border-red-500 text-sm hover:border-blue-1100 hover:bg-white hover:text-blue-1100`}
                onClick={() => {
                  setOpen(false);
                  setSignIn(true);
                }}
              >
                CLOSE
              </button>
            </div>
            <div className="grid grid-cols-2">
              <div className="relative col-span-2">
                <div className="flex justify-center mt-16 mb-4">
                  <Link to="/">
                    <h1 className="text-lg font-bold sm:text-xl lg:text-2xl font-rostave text-blue-1100">
                      TICKETNATION
                    </h1>
                  </Link>
                </div>
                {signIn ? (
                  <div className="flex items-center justify-center p-2 lg:p-4">
                    <SignIn
                      setSignIn={setSignIn}
                      isError={isErrorSignIn}
                      error={errorSignIn}
                      isModal={true}
                      signInhandleSubmit={handleSubmitSignIn}
                    />
                  </div>
                ) : (
                  <div className="flex items-center justify-center p-2 lg:p-4">
                    <SignUp
                      setSignIn={setSignIn}
                      isModal={true}
                      isError={isError}
                      error={error}
                      signupHandleSubmit={handleSubmitSignUp}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
