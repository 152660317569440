import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";


const SignInGoogle = () => {
  const google = () => {
    window.open(`${process.env.REACT_APP_API_URL}/auth/google`, "_self");
  };
  return (
    <button type="button" onClick={google} className="text-white bg-[#4285F4] px-2 py-1 rounded-sm">
      <FontAwesomeIcon icon={faGoogle} className="mr-2" />
      Google
    </button>
  );
};

export default SignInGoogle;
