import { useState } from "react";
import { useGetOrdersQuery } from "../../../services/order.api";
import OrderList from "./OrderList";

const PastOrders = () => {
  const [skip, setSkip] = useState(0);
  const [take] = useState(80);

  // Fetch past orders
  const {
    data: userOrders,
    isLoading,
    isError,
  } = useGetOrdersQuery({
    skip,
    take,
    dateFilter: "past", // Pass the type as 'past'
    search: "",
  });

  return (
    <div>
      {isLoading && <p>Loading...</p>}
      {isError && <p>Error fetching past orders</p>}
      {userOrders?.data && <OrderList orders={userOrders.data} />}
    </div>
  );
};

export default PastOrders;
