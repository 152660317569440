import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SigninImage from "../../assets/images/signin-pic.png";
import Loading from "../../components/Loading";
import {
  setUser,
  setUserDetail,
} from "../../features/Authentication/authSlice";
import SignIn from "../../features/Authentication/SignIn";
import { SignInFormValues } from "../../interfaces/auth/Interface";
import logger from "../../lib/logger";
import { CustomError, useSigninMutation } from "../../services/auth.api";

const SignInPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signin, { data, isSuccess, isLoading, error, isError }] =
    useSigninMutation();
  const [userDetails, setUserDetails] = React.useState<SignInFormValues>({});

  // Handle successful sign-in
  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      localStorage.setItem("refreshToken", data.refreshToken);
      dispatch(
        setUser({
          refreshToken: data.refreshToken,
          accessToken: data.accessToken,
        })
      );

      // Log success
      logger.info("User successfully signed in", {
        userId: data.userId,
        email: userDetails.email,
      });
    }
  }, [isSuccess, isLoading, data, dispatch, userDetails]);

  // Handle errors during sign-in
  React.useEffect(() => {
    if (isError) {
      const customError = error as CustomError;

      if (customError?.data?.isActive === false) {
        dispatch(setUserDetail(userDetails));
        navigate("/verification");

        // Log inactive account error
        logger.warn("Inactive account sign-in attempt", {
          email: userDetails.email,
        });
      } else {
        // Log generic error
        logger.error("Sign-in error", {
          email: userDetails.email,
          error: customError?.data?.message,
        });
      }
    }
  }, [isError, error, navigate, dispatch, userDetails]);

  // Log loading state
  React.useEffect(() => {
    if (isLoading) {
      logger.info("Sign-in process started", { email: userDetails.email });
    }
  }, [isLoading, userDetails]);

  // Handle form submission
  const handleSubmit = (values: SignInFormValues) => {
    logger.info("Sign-in attempt", { email: values.email });
    signin(values);
    setUserDetails(values);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex items-center justify-center">
      <div className="w-full md:w-2/5">
        <div className="flex flex-col items-center justify-center">
          <div className="flex justify-center my-12">
            <Link to="/">
              <h1 className="flex flex-col gap-4 text-4xl font-bold font-rostave text-blue-1100">
                <span>TICKET</span>
                <span>NATION</span>
              </h1>
            </Link>
          </div>
          <div>
            <SignIn signInhandleSubmit={handleSubmit} />
            <div className="mr-6 text-center text-red-500">
              {isError && (error as CustomError).data.message}
            </div>
          </div>
        </div>
      </div>

      <div className="relative h-screen md:w-3/5 bg-blue-1100">
        <img
          className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          src={SigninImage}
          alt="signin-pic"
        />
      </div>
    </div>
  );
};

export default SignInPage;
