import React from "react";

interface ComponenProps {
  min: number;
  sec: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const OrderExpiration: React.FC<ComponenProps> = ({
  min,
  sec,
  setOpen,
}) => {
  const [minutes, setMinutes] = React.useState(min);
  const [seconds, setSeconds] = React.useState(sec);
  React.useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          setOpen(true);
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  return (
    <div className="flex justify-end">
      <div className="text-2xl">
        Order Expires in:{" "}
        <span className="font-bold tracking-widest text-blue-1100">{`${String(
          minutes >= 0 ? minutes : 0
        ).padStart(2, "0")}:${String(seconds >= 0 ? seconds : 0).padStart(
          2,
          "0"
        )}`}</span>
      </div>
    </div>
  );
};
