import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { DealVariant } from "../models/deal.model";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getLowestPriceDealVariant(dealVariants: DealVariant[]) {
  if (!dealVariants || dealVariants.length === 0) {
    return null; // or throw an error if preferred
  }

  let lowestPrice: number | null = null;

  for (const variant of dealVariants) {
    // Check if the variant has availabilities and pick the lowest price within those
    const variantPrices = variant.availabilities?.map(
      (availability) => availability.price
    );
    const variantLowestPrice = variantPrices
      ? Math.min(...variantPrices)
      : null;

    if (
      variantLowestPrice !== null &&
      (lowestPrice === null || variantLowestPrice < lowestPrice)
    ) {
      lowestPrice = variantLowestPrice;
    }
  }

  return lowestPrice;
}
