export const parseDecimal = (price: number) => {
  return parseFloat(parseFloat(price.toString()).toFixed(2));
};

export const roundDown = (amount: number) => {
  return Math.floor(amount)
}

export const getTotalDiscount = (amount:number, discountPercentage:number)=>{
  return roundDown(amount * discountPercentage)
}

export const getDiscountedValue = (price: number, discountPercentage: number) => {
  return price - getTotalDiscount(price, discountPercentage);
};


export function formatCurrency(amount: number = 0, currency: string = "PHP") {
  const formatAmount = amount !== null ? amount : 0
  return formatAmount.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
  });
}