// OrderContext.tsx
import { createContext } from "react";
import { OrderContextType } from "./OrderContextTypes";

const defaultOrderContext: OrderContextType = {
  event: null,
  order: null,
  isLoading: false,
  isError: false,
  error: null,
  isSuccess: false,
  step: 1,
  setStep: () => {},
};

const OrderContext = createContext<OrderContextType>(defaultOrderContext);

export default OrderContext;
