import DealBanner from "./DealBanner";
import DealCategories from "./DealCategories";
import Deals from "./Deals";

export function DealsPage() {
  return (
    <div className="min-h-screen bg-background">
      <main>
        <DealBanner />
        <DealCategories />
        <Deals />
      </main>
    </div>
  );
}

export default DealsPage;
