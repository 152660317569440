import React, { useContext } from "react";

import { OrderContextType } from "../../../../pages/Order/context/OrderContextTypes";
import OrderContext from "../../../../pages/Order/context/OrderContext";
import { getTotalDiscount } from "../../../../helpers/priceHelper";
import ShopSummary from "../Shop/ShopSummary";
import dayjs from "dayjs";

interface ComponentProps {}

const OrderSummary: React.FC<ComponentProps> = () => {
  const { order, event } = useContext<OrderContextType>(OrderContext);

  if (!order || !event) {
    return null;
  }

  const {
    orderEventTickets,
    voucher,
    orderFee,
    subTotal,
    total,
    totalPerTicketFee,
  } = order;
  const { currency } = event;


  const payload = orderEventTickets || [];

  const totalTicketQuantity = payload.reduce(
    (previousValue, currentValue) =>
      previousValue +
      currentValue.quantity * currentValue.eventTicket.multiplier,
    0
  );

  const totalDiscount = payload.reduce((previousValue, currentValue) => {
    let value = 0;
    if (voucher) {
      value = getTotalDiscount(
        currentValue.total,
        voucher.discountPercentage / 100
      );
    }
    return previousValue + value;
  }, 0);

  const getPrice = (basePrice: number) => {
    let value = 0;
    if (voucher) {
      const discount = voucher.discountPercentage / 100;
      value = basePrice - getTotalDiscount(basePrice, discount);
    } else {
      value = basePrice;
    }

    return value;
  };

  return (
    <div className="flex flex-col w-full mt-2">
      <div className="flex items-center gap-3 mb-5">
        <div
          className="relative w-20 h-20 bg-center bg-cover"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_IMAGE_BASE_URL + event.image?.fileName
            })`,
          }}
        />
        <div className="leading-tight">
          <h2 className="text-xl font-semibold">{event.name}</h2>
          <p>
            {dayjs(event.dateTime).tz(event.timezone).format("MMMM DD, YYYY")}
          </p>
          <p>{dayjs(event.dateTime).tz(event.timezone).format("hh:mm A")}</p>
        </div>
      </div>

      <h2 className="font-semibold">Ticket Summary</h2>
      <div>
        {payload
          .filter((o) => o.quantity > 0)
          .map((orderEventTicket, index) => {
            return (
              <div className="grid grid-cols-2 border-b" key={index}>
                <div>
                  <div>{orderEventTicket.eventTicket.name}</div>
                  <span>{}</span>
                  <div className="text-sm">{`${orderEventTicket.quantity} x 
                  ${currency === "PHP" ? "₱" : "$"}
                  ${getPrice(orderEventTicket.price)}`}</div>
                </div>
                <div className="flex justify-end">
                  {currency === "PHP" ? "₱" : "$"}
                  {orderEventTicket.total}
                </div>
                {orderEventTicket.perTicketFee > 0 && (
                  <>
                    <div>
                      <div>E - Ticket Fee</div>
                      <span>{}</span>
                      <div className="text-sm">{`${
                        orderEventTicket.quantity
                      } x ${currency === "PHP" ? "₱" : "$"}${
                        orderEventTicket.perTicketFee
                      }`}</div>
                    </div>
                    <div className="flex justify-end">
                      {currency === "PHP" ? "₱" : "$"}
                      {orderEventTicket.perTicketFee *
                        orderEventTicket.quantity}
                    </div>
                  </>
                )}
              </div>
            );
          })}
      </div>
      <div className="flex justify-between">
        <div className="text-md">Ticket Sub-total: </div>
        <div>
          {currency === "PHP" ? "₱" : "$"}
          {subTotal}
        </div>
      </div>

      {/* {totalDiscount > 0 && (
        <div className="flex justify-between">
          <div className="text-md">Total Discount: </div>
          <div>₱{(totalDiscount)}</div>
        </div>
      )} */}
      {totalPerTicketFee > 0 && (
        <div className="flex justify-between">
          <div className="text-md">Total E - Ticket Fee: </div>
          <div>
            {currency === "PHP" ? "₱" : "$"}
            {totalPerTicketFee}
          </div>
        </div>
      )}
      <ShopSummary />
      {orderFee > 0 && (
        <div className="flex justify-between">
          <div className="text-md">Processing Fee: </div>
          <div>
            {currency === "PHP" ? "₱" : "$"}
            {orderFee}
          </div>
        </div>
      )}
      <div className="flex justify-between">
        <div className="text-lg font-bold">TOTAL: </div>
        <div>
          <div className="">
            <div className="font-bold">{`${totalTicketQuantity} Ticket(s)`}</div>
            <div>
              {currency === "PHP" ? "₱" : "$"}
              {total}.00
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
