import React from "react";
import { useSearchParams } from "react-router-dom";
import SearchInput from "../../../components/Search";
import { ArtistList } from "../../../features/Artist";

const ArtistSearchPage: React.FC = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="">
      <div className="px-2 mb-2 lg:px-0 lg:mb-4">
        <SearchInput placeholder={"Search Artist Name"} />
      </div>
      <ArtistList search={searchParams.get("search") || ""} />
    </div>
  );
};

export default ArtistSearchPage;
