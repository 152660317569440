import { Search } from "lucide-react";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

const DealBanner = () => {
  return (
    <section className="relative py-14">
      <div className="absolute inset-0 bg-black/50"></div>
      <div className="container relative z-10 px-4 mx-auto">
        <h2 className="mb-4 text-4xl font-bold text-white md:text-5xl">
          Attraction tickets
        </h2>
        <p className="mb-8 text-xl text-white">
          Discover the best theme parks, museums and more must-sees
        </p>
        <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
          <Select>
            <SelectTrigger className="w-full md:w-[200px]">
              <SelectValue placeholder="Choose destination" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="ny">New York</SelectItem>
              <SelectItem value="la">Los Angeles</SelectItem>
              <SelectItem value="ch">Chicago</SelectItem>
            </SelectContent>
          </Select>
          <div className="relative flex-1">
            <Input
              type="text"
              placeholder="What do you want to do?"
              className="w-full pr-10"
            />
            <Search className="absolute w-5 h-5 transform -translate-y-1/2 right-3 top-1/2 text-muted-foreground" />
          </div>
          <Button size="lg" className="w-full md:w-auto">
            Search
          </Button>
        </div>
      </div>
    </section>
  );
};

export default DealBanner;
