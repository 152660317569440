import React from "react";
import { useNavigate } from "react-router-dom";
import Error from "../../../../components/Error";
import Loading from "../../../../components/Loading";
import {
  CustomError,
  useNewPasswordMutation,
} from "../../../../services/auth.api";
import { useSearchParams } from "react-router-dom";

const ResetPassword: React.FC = () => {
  const [otpResetPass, setOtpResetPass] = React.useState("");
  const [newPass, setNewPass] = React.useState("");

  let [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");

  const navigate = useNavigate();

  const [newPassword, { data, isSuccess, isLoading, isError, error }] =
    useNewPasswordMutation();

  const customError = error as CustomError;

  const handleNewPassword = () => {
    const userData = {
      otp: otpResetPass,
      email: email,
      password: newPass,
    };
    newPassword(userData);
  };

  React.useEffect(() => {
    if (isSuccess) {
      navigate("/reset-password/success");
    }
  }, [isSuccess, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="mt-8">
        <input
          type="text"
          className="py-2 px-4 lg:w-[345px] w-[300px] drop-shadow rounded-sm "
          placeholder="Six Digit Code"
          onChange={(e) => setOtpResetPass(e.target.value)}
        />
      </div>
      <div className="mt-8">
        <input
          type="password"
          className="py-2 px-4 lg:w-[345px] w-[300px] drop-shadow rounded-sm "
          placeholder="New Password"
          onChange={(e) => setNewPass(e.target.value)}
        />
      </div>
      <div className="mt-8">
        <button
          onClick={() => handleNewPassword()}
          className="w-[300px] p-2 font-bold text-white uppercase rounded-sm bg-blue-1100 lg:w-[345px]"
        >
          Reset Password
        </button>
      </div>
      <div className="mt-4">
        {isError && <p className="text-red-700">{customError.data.message}</p>}
      </div>
    </div>
  );
};

export default ResetPassword;
