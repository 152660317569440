import parse from "html-react-parser";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Gcash from "../../../assets/images/Gcash.png";
import MastercardLogo from "../../../assets/images/Mastercard-logo.png";
import Mastercard from "../../../assets/images/Mastercard.png";
import VisaLogo from "../../../assets/images/Visa-logo.jpg";
import Loading from "../../../components/Loading";
import { Modal } from "../../../components/Modal";
import OrderContext from "../../../pages/Order/context/OrderContext";
import { authSelect } from "../../Authentication/authSlice";
import { CreditCard } from "../../Payment/CreditCard";
import { EWallet } from "../../Payment/EWallet";
import OnlineDebit from "../../Payment/OnlineDebit";
import UpdateUserInfo from "../../User/UpdateUserInfo";
import { setOrderFee, setSelectedPayload } from "./eventSlice";
import OrderSummary from "./Order/OrderSummary";
import {
  CircleCheckBig,
  CircleX,
  CreditCardIcon,
  HandCoinsIcon,
  WalletCardsIcon,
} from "lucide-react";
import Paylater from "../../Payment/Paylater";
import { Socket, io } from "socket.io-client";
import "../../../css/loading-animation.css";
import { faL } from "@fortawesome/free-solid-svg-icons";
const socket: Socket = io(
  process.env.REACT_APP_BASE_URL || "http://localhost:3000",
  {
    transports: ["websocket"], // Force WebSocket only
    path: "/ws", // Specify the WebSocket path if needed
  }
);

interface ComponentProps {
  orderId: string;
  eventId: string;
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const EventPayment: React.FC<ComponentProps> = ({ orderId, eventId }) => {
  const { user } = useAppSelector(authSelect);
  const searchParams = useQuery();
  const dispatch = useAppDispatch();
  const {
    order: data,
    event,
    isLoading,
    isError,
    error,
    isSuccess,
    setStep,
  } = useContext(OrderContext);

  function stringContains(haystack: string, needle: string) {
    return haystack.indexOf(needle) !== -1;
  }

  const [acknowledgement, setAcknowledgement] = useState(
    !event?.terms ? true : false
  );
  const [open, setOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);

  const [paymentOption, setPaymentOption] = React.useState(
    event?.currency === "PHP" ? "credit-card" : "e-wallet"
  );
  const [status, setStatus] = React.useState<string>();
  const [urlStatus, setUrlStatus] = React.useState<string>();

  const renderStatus = () => {
    if (urlStatus === "success" && !status) {
      return <div className="loader bg-blue-1100 w-52 h-52"></div>;
    }
    if (urlStatus === "failed" || status === "FAILED") {
      return (
        <div className="text-center transition-all ease-in-out scale-50 hover:scale-100">
          <CircleX className="w-[200px] h-[200px] text-red-500 " />
          <h2 className="text-3xl font-semibold text-red-500">Failed</h2>
        </div>
      );
    }

    if (urlStatus === "success" && status === "COMPLETED") {
      return (
        <div className="text-center transition-all ease-in-out scale-50 hover:scale-100">
          <CircleCheckBig className="w-[200px] h-[200px] text-green-500" />
          <h2 className="text-3xl font-semibold text-green-500">Failed</h2>
        </div>
      );
    }

    return <div className="loader bg-blue-1100 w-52 h-52"></div>;
  };

  React.useEffect(() => {
    const urlPaymentOption = searchParams.get("payment_option");
    const urlPaymentStatus = searchParams.get("payment");
    if (urlPaymentStatus) {
      setUrlStatus(urlPaymentStatus);
    }
    if (urlPaymentOption) {
      setPaymentOption(urlPaymentOption);
    }
  }, [searchParams]);

  React.useEffect(() => {
    setStep(3);
  }, [setStep]);

  React.useEffect(() => {
    if (data && isSuccess) {
      const voucher = data.voucher;

      dispatch(setOrderFee(data.orderFee));
      dispatch(
        setSelectedPayload(
          data.orderEventTickets.map((orderEventTicket) => {
            if (voucher) {
              const discount =
                (voucher && voucher.discountPercentage / 100) || 0;
              const totalDiscountPrice = Math.floor(
                parseInt(orderEventTicket.price.toString()) * discount
              );
              const getTotalPrice = Math.floor(
                parseInt(orderEventTicket.price.toString()) - totalDiscountPrice
              );

              return {
                id: orderEventTicket.id,
                name: orderEventTicket.eventTicket.name,
                price: orderEventTicket.price,
                multiplier: orderEventTicket.eventTicket.multiplier,
                quantity: orderEventTicket.quantity,
                total: getTotalPrice * orderEventTicket.quantity,
                perTicketFee: orderEventTicket.eventTicket.perTicketFee,
                eventName: orderEventTicket.eventTicket.event.name,
              };
            }

            return {
              id: orderEventTicket.id,
              name: orderEventTicket.eventTicket.name,
              price: orderEventTicket.price,
              quantity: orderEventTicket.quantity,
              multiplier: orderEventTicket.eventTicket.multiplier,
              total: orderEventTicket.total,
              perTicketFee: orderEventTicket.eventTicket.perTicketFee,
              eventName: orderEventTicket.eventTicket.event.name,
            };
          })
        )
      );
    }
    return () => {
      dispatch(setSelectedPayload([]));
    };
  }, [data, isSuccess, dispatch]);

  React.useEffect(() => {
    socket.emit("connected", "paymentInit");

    socket.on("paymentStatusUpdate", (data) => {
      setStatus(data.status);
      console.log(data);
    });

    return () => {
      socket.off("paymentStatusUpdate");
    };
  }, []);

  React.useEffect(() => {
    if (urlStatus) {
      setStatusOpen(true);
    }

    return () => {
      setStatusOpen(false);
    };
  }, [urlStatus]);

  if (isLoading) {
    return <Loading />;
  }

  if (!data || !event) {
    return null;
  }
  return (
    <div className="flex flex-col justify-center p-2 lg:p-4">
      {/* Summary */}
      <div className="flex justify-center mb-2 text-2xl font-semibold lg:text-4xl lg:mb-4">
        SUMMARY
      </div>

      <div className="">
        <div className="w-full bg-white">
          <div className="grid grid-cols-2 py-2 font-semibold text-white bg-blue-1100 rounded-tl-xl rounded-tr-xl ">
            <div className="flex justify-between col-span-2 px-2 lg:col-span-1">
              <div>TICKETS</div>
              <div>AMOUNT</div>
            </div>
          </div>
          <div className="grid grid-cols-1 p-2 lg:p-4 lg:grid-cols-2">
            <div className="p-2 lg:border-r lg:p-2">
              {/* <EventPaymentSummary /> */}
              <OrderSummary />
            </div>
            <div className="p-0 lg:p-2">
              <div className="flex justify-center mb-2 text-2xl font-semibold lg:text-4xl lg:mb-4">
                PAYMENT
              </div>
              {event?.terms && (
                <div className="flex items-center justify-between">
                  <p className="label-text">
                    I have read and agreed to the{" "}
                    <span
                      className="font-semibold text-blue-500 hover:cursor-pointer"
                      onClick={() => setOpen(true)}
                    >
                      Terms and Conditions
                    </span>
                  </p>

                  <div className="form-control">
                    <label className="cursor-pointer label">
                      <input
                        checked={acknowledgement}
                        onClick={() => setAcknowledgement(!acknowledgement)}
                        type="checkbox"
                        className="checkbox checkbox-accent"
                      />
                    </label>
                  </div>
                </div>
              )}
              {/* Acknowledgement */}
              {stringContains(user?.email || "", "@no-fb-email.com") ? (
                <div>
                  <UpdateUserInfo />
                </div>
              ) : (
                <>
                  <div
                    className={`grid ${
                      event?.currency === "PHP" && "grid-cols-3"
                    } gap-2 lg:px-32`}
                  >
                    <button
                      onClick={() => setPaymentOption("e-wallet")}
                      type="button"
                      className={`py-3 drop-shadow rounded-lg border border-blue-1100 ${
                        paymentOption === "e-wallet" && "border-green-400"
                      }`}
                    >
                      <div className="flex justify-center ">
                        <WalletCardsIcon className="h-12 text-blue-500 w-96" />
                      </div>
                      <div className="mt-2 text-center text-blue-500">
                        E-Wallet
                      </div>
                    </button>
                    {event?.currency === "PHP" && (
                      <>
                        {/* <button
                      onClick={() => setPaymentOption("debit")}
                      type="button"
                      className={`py-3 drop-shadow rounded-lg border border-blue-1100 ${
                        paymentOption === "debit" && "border-green-400"
                      }`}
                    >
                      <div className="flex justify-center ">
                        <img
                          src={MoneyBillIcon}
                          className="w-16 h-12"
                          alt="gcash"
                        />
                      </div>
                      <div className="mt-2 text-center text-blue-500">
                        Online Debit
                      </div>
                    </button> */}
                        <button
                          onClick={() => setPaymentOption("paylater")}
                          type="button"
                          className={`py-3 drop-shadow rounded-lg border border-blue-1100 ${
                            paymentOption === "paylater" && "border-green-400"
                          }`}
                        >
                          <div className="flex justify-center ">
                            <HandCoinsIcon className="h-12 text-blue-500 w-96" />
                          </div>
                          <div className="mt-2 text-center text-blue-500">
                            Paylater
                          </div>
                        </button>
                        <button
                          onClick={() => setPaymentOption("credit-card")}
                          type="button"
                          className={`py-3 drop-shadow rounded-lg border border-blue-1100 ${
                            paymentOption === "credit-card" &&
                            "border-green-400"
                          }`}
                        >
                          <div className="flex justify-center">
                            <CreditCardIcon className="h-12 text-blue-500 w-96" />
                          </div>
                          <div className="mt-2 text-center text-blue-500">
                            Credit Card
                          </div>
                          <div className="flex justify-center"></div>
                        </button>
                      </>
                    )}
                  </div>
                  <>
                    {event?.currency === "PHP" && (
                      <>
                        {paymentOption === "credit-card" && (
                          <>
                            <div className="flex justify-center gap-3 pt-4">
                              <div className="px-2 py-1 border rounded-md">
                                <img
                                  src={MastercardLogo}
                                  className="w-8 h-6"
                                  alt="mastercard"
                                />
                              </div>
                              <div className="px-2 py-1 border rounded-md">
                                <img
                                  src={VisaLogo}
                                  className="w-8 h-6"
                                  alt="mastercard"
                                />
                              </div>
                            </div>
                            <CreditCard
                              amount={data.total}
                              orderId={data.id}
                              acknowledgement={acknowledgement}
                            />
                          </>
                        )}
                        {paymentOption === "paylater" && (
                          <Paylater amount={data.total} orderId={data.id} />
                        )}
                      </>
                    )}
                    {paymentOption === "e-wallet" && (
                      <EWallet
                        acknowledgement={acknowledgement}
                        amount={data.total}
                        orderId={data.id}
                        currency={event?.currency}
                        eventId={eventId}
                      />
                    )}
                  </>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} setOpen={setOpen} title="Terms and Conditions">
        <div className="p-2">
          <div className="h-screen col-span-6 pb-20 overflow-y-auto text-sm text-justify break-normal lg:text-base">
            {event && event.terms && parse(event?.terms.toString())}
          </div>
        </div>
      </Modal>

      <Modal
        open={statusOpen}
        setOpen={setStatusOpen}
        title="Payment Status"
        fullHeight
        modalClassName="lg:max-h-[500px]"
      >
        <div className="flex flex-col items-center justify-center h-full">
          {renderStatus()}
        </div>
      </Modal>
    </div>
  );
};

export default EventPayment;
