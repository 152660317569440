"use client";

import { ArrowDownRight, ArrowRight } from "lucide-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "src/app/hooks";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import { authSelect } from "src/features/Authentication/authSlice";
import { ReactComponent as TicketnationLogo } from "../../../assets/logo/ticketnation-modal.svg";
import { Progress } from "../../../components/ui/progress";
import { useOtpContext } from "../../../pages/OtpEmail/context/OtpContext";
import { useCreateOfferMutation } from "../../../services/offer.api";
import { EmailForm } from "./EmailForm";
import { TicketGroup } from "./EventTicketOffer";
import { OfferForm } from "./OfferForm";
import { OtpForm } from "./OtpForm";

const TICKETNATION_API_URL =
  process.env.NEXT_PUBLIC_TICKETNATION_API_URL || "https://api.ticketnation.ph";

type TSteps = "email" | "otp" | "offer" | "completed";

export default function OfferWallBlocker({
  isOpen,
  handleOpen,
  selectedTicket,
  eventId,
}: {
  isOpen: boolean;
  handleOpen: (isOpen: boolean) => void;
  selectedTicket: TicketGroup | null;
  eventId: string;
}) {
  const { user } = useAppSelector(authSelect);
  const [
    createOffer,
    {
      data: offer,
      isSuccess,
      isLoading,
      error: errorOffer,
      isError: isErrorOffer,
    },
  ] = useCreateOfferMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data } = useOtpContext();
  const [step, setStep] = useState<TSteps>("email");
  const [email, setEmail] = useState("");
  const [otpId, setOtpId] = useState("");
  const [timer, setTimer] = useState(60);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [offerPrice, setOfferPrice] = useState(0);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setOfferPrice(0);
        setOtpId("");
        setStep("email");
      }, 300);
    }
  }, [isOpen]);

  // useEffect(() => {
  //   if (data) {
  //     setStep("offer");
  //     handleOpen(true);
  //   }
  // }, [data]);

  useEffect(() => {
    const progressMap = {
      email: 25,
      otp: 50,
      offer: 75,
      completed: 100,
    };
    setProgress(progressMap[step]);
  }, [step]);

  // Placeholder function for sending OTP to email
  const sendOtpToEmail = async (email: string) => {
    console.log("API Call: Sending OTP to", email);
    try {
      const response = await fetch(`${TICKETNATION_API_URL}/email-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, userType: "Seller" }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed sending OTP");
      }

      await new Promise((resolve) => setTimeout(resolve, 1000));

      return await response.json();
    } catch (error) {
      console.error("Error sending OTP:", error);
      throw error;
    }
  };

  // Placeholder function for verifying the OTP
  const verifyOtp = async (otp: string) => {
    console.log("API Call: Verifying OTP", otp);
    try {
      const response = await fetch(`${TICKETNATION_API_URL}/email-otp/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, otp }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        return responseData;
      }
      setOtpId(responseData.data.id || "");
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return responseData;
    } catch (error) {
      console.error("Error verifying OTP:", error);
      throw error;
    }
  };

  const handleBackButton = () => {
    switch (step) {
      case "email":
        handleOpen(false);
        break;
      case "otp":
        setStep("email");
        break;
      default:
        break;
    }
  };

  const handleEmailSubmit = async (submittedEmail: string) => {
    setLoading(true);
    if (!submittedEmail) return;
    try {
      const response = await sendOtpToEmail(submittedEmail);
      if (response) {
        setEmail(submittedEmail);
        setStep("otp");
        setTimer(60);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (otp: string) => {
    setLoading(true);
    if (!otp) return;
    try {
      const response = await verifyOtp(otp);
      if (response.success) {
        setStep("offer");
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    if (timer > 0) return;
    try {
      const response = await sendOtpToEmail(email);
      if (response) {
        setTimer(60);
      }
    } catch (error) {}
  };

  const handleSubmitOffer = async (offerPrice: number) => {
    if (!offerPrice || !selectedTicket) {
      console.error("Offer price or selected ticket is missing.");
      return;
    }

    setLoading(true); // Set loading to true while processing

    try {
      // Validate required fields before proceeding
      const { id: tnSellId, quantity, name } = selectedTicket;
      const sellerId = user?.email;

      if (!tnSellId || !quantity || !sellerId || !eventId) {
        throw new Error("Missing required data to submit the offer.");
      }

      console.log(`Submitting an offer of ₱${offerPrice} for ${name}`);

      // Send the offer to the backend
      await createOffer({
        quantity,
        tnSellId,
        sellerId,
        eventId,
        priceOffered: offerPrice,
      });

      // If the offer is successfully submitted, move to the next step
      setStep("completed");
      setOfferPrice(offerPrice);
      console.log(`Offer of ₱${offerPrice} successfully submitted.`);
    } catch (error) {
      console.error("Failed to submit the offer:", error);
      alert("Failed to submit the offer. Please try again.");
    } finally {
      // Set loading to false once the operation is done (either success or fail)
      setLoading(false);
    }
  };

  const handleCompleted = async () => {
    handleOpen(false);
    setTimeout(() => {
      // Set offer price
      setOfferPrice(offerPrice);

      // Remove query parameters
      // const newSearchParams = new URLSearchParams(searchParams);
      // newSearchParams.delete("otpId"); // Remove the 'otpId' parameter
      // setSearchParams(newSearchParams); // Update the URL without the params

      setStep("email");
    }, 300); // Adjust the delay (300ms) to your needs
  };
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (step === "otp" && timer > 0) {
      interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
    }
    return () => clearInterval(interval);
  }, [step, timer]);

  return (
    <div className="relative">
      <Dialog open={isOpen} onOpenChange={handleOpen}>
        <DialogContent className="fixed text-start flex w-full bg-background opacity-90 max-w-full h-full max-h-full !rounded-none flex-col items-center justify-center">
          <Progress
            value={progress}
            className="absolute top-32 w-full max-w-[90%] mb-8"
          />
          <div className="flex flex-col gap-4 sm:min-w-96">
            <div className="flex flex-col items-center justify-center">
              <TicketnationLogo />
              <span className="text-[9px] text-center max-w-[200px]">
                Buy. Sell. Connect. Experience seamless ticket reselling with
                Ticketnation Resell.
              </span>
            </div>
            <DialogHeader>
              <DialogTitle>
                <h2 className="relative flex flex-col items-start text-xl font-semibold sm:flex-row">
                  <span className="flex items-center mr-2 text-sm text-blue-500 sm:absolute top-1 -left-10">
                    {step === "email"
                      ? "1"
                      : step === "otp"
                      ? "2"
                      : step === "offer"
                      ? "3"
                      : "4"}
                    <ArrowRight className="hidden w-4 h-4 ml-1 sm:flex" />
                    <ArrowDownRight className="flex w-4 h-4 ml-1 sm:hidden" />
                  </span>
                  <span>
                    {step === "email" ? (
                      <>
                        <span className="text-gray-700">
                          Ready to Offer a Price for Your Tickets?
                        </span>
                        <br />
                        <span className="text-[11px] font-normal leading-tight max-w-xs text-center block mx-auto">
                          This form is for sellers to offer a price based on the
                          buyer's request. If you are not a seller, please press
                          'Back' to return.
                        </span>
                      </>
                    ) : step === "otp" ? (
                      <span className="text-gray-700">
                        🔒 Enter OTP to Proceed! 🔒*
                      </span>
                    ) : step === "offer" ? (
                      "Make an Offer"
                    ) : (
                      "Offer Successful!"
                    )}
                  </span>
                </h2>
              </DialogTitle>
              <DialogDescription className="text-left text-[10px]  sm:max-w-md">
                {step === "email" ? (
                  "🔐 We respect your privacy—your email will only be used for verification purposes."
                ) : step === "otp" ? (
                  <span className="pb-4 text-xs text-gray-600">
                    We’ve just sent a 6-digit OTP to <strong>{email}</strong>.
                  </span>
                ) : step === "offer" ? (
                  <span>
                    Enter your offer price for {selectedTicket?.name} (Quantity:{" "}
                    {selectedTicket?.quantity}). Please note that the offer
                    price is per ticket.
                  </span>
                ) : (
                  <span>
                    You have successfully offered ₱{offerPrice} per ticket for{" "}
                    {selectedTicket?.quantity}{" "}
                    {selectedTicket?.quantity === 1 ? "ticket" : "tickets"}.
                    Your offer has been sent to the buyer who requested it.
                    Please select 'Continue' to proceed.
                  </span>
                )}
              </DialogDescription>
            </DialogHeader>
            {step === "email" && user ? (
              <EmailForm
                onSubmit={handleEmailSubmit}
                loading={loading}
                defaultEmail={user?.email}
              />
            ) : step === "otp" ? (
              <OtpForm
                email={email}
                onSubmit={handleOtpSubmit}
                onResend={handleResendOtp}
                timer={timer}
                loading={loading}
              />
            ) : step === "offer" ? (
              <OfferForm
                selectedTicket={selectedTicket}
                onSubmitOffer={handleSubmitOffer}
              />
            ) : (
              <div className="flex flex-col space-y-2">
                <Button
                  onClick={handleCompleted}
                  className="w-full py-6 text-lg font-bold text-white bg-blue-500 hover:bg-blue-600 hover:text-white"
                >
                  Continue
                </Button>
              </div>
            )}

            {(step === "email" || step == "otp") && (
              <Button
                onClick={handleBackButton}
                variant={"outline"}
                type="button"
                className="text-gray-700"
              >
                Back
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
