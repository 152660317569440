import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { CategoriesResponse, Category } from "@/models/category.model";
import { Deal, DealsResponse } from "@/models/deal.model";
import {SocialProfile} from "@/models/social-profile.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface CategoriesQuery {
  skip?: number;
  take?: number;
  search?: string;
  type?: string; // Should align with CategoryType (but using string here for query params)
  hasParent?: boolean; // Optional parameter to filter based on whether categories have a parent
}

interface DealsQuery {
  skip?: number;
  take?: number;
  search?: string;
  categoryId?: string; // To filter deals by category
  organizationId?: string; // To filter deals by organization
  isFeatured?: boolean; // Optional parameter to filter featured deals
  isPublished?: boolean; // Optional parameter to filter published deals
  startDate?: string; // To filter deals starting after a date
  endDate?: string; // To filter deals ending before a date
}

export const publicApi = createApi({
  reducerPath: "publicApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/public",
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Categories", "Category", "Deals", "Deal"],
  endpoints: (builder) => ({
    getCategories: builder.query<CategoriesResponse, CategoriesQuery>({
      query: ({ skip, take, search, type, hasParent }) => {
        const params = new URLSearchParams();
        if (skip) params.append("skip", String(skip));
        if (take) params.append("take", String(take));
        if (search) params.append("search", search);
        if (type) params.append("type", type);
        if (hasParent !== undefined)
          params.append("hasParent", String(hasParent));

        return {
          url: `/categories?${params.toString()}`,
        };
      },
      providesTags: ["Categories"],
    }),
    getCategory: builder.query<Category, string>({
      query: (id) => ({
        url: `/categories/${id}`,
      }),
      providesTags: ["Category"],
    }),
    getDeals: builder.query<DealsResponse, DealsQuery>({
      query: ({
        skip,
        take,
        search,
        categoryId,
        organizationId,
        isFeatured,
        isPublished,
        startDate,
        endDate,
      }) => {
        const params = new URLSearchParams();
        if (skip) params.append("skip", String(skip));
        if (take) params.append("take", String(take));
        if (search) params.append("search", search);
        if (categoryId) params.append("categoryId", categoryId);
        if (organizationId) params.append("organizationId", organizationId);
        if (isFeatured !== undefined)
          params.append("isFeatured", String(isFeatured));
        if (isPublished !== undefined)
          params.append("isPublished", String(isPublished));
        if (startDate) params.append("startDate", startDate);
        if (endDate) params.append("endDate", endDate);

        return {
          url: `/deals?${params.toString()}`,
        };
      },
      providesTags: ["Deals"],
    }),
    getDeal: builder.query<Deal, string>({
      query: (id) => ({
        url: `/deals/${id}`,
      }),
      providesTags: ["Deal"],
    }),
    getPublicUserLink: builder.query<SocialProfile, string>({
      query: (username) => ({
        url: `/links/${username}`,
      }),
      providesTags: ["Deal"],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useGetDealQuery,
  useGetDealsQuery,
    useGetPublicUserLinkQuery
} = publicApi;
