import React from "react";
import { Link } from "react-router-dom";
import TicketNationLogo from "../../../assets/images/ticketnationlogo.png";
import Facebook from "../../../assets/coming-soon/facebook.png";
import Instagram from "../../../assets/coming-soon/instagram.png";
import LinkedIn from "../../../assets/coming-soon/linked_in.png";
import { Helmet } from "react-helmet";

function PrivacyPage() {
  const [open, setOpen] = React.useState(false);

  const openNav = () => {
    setOpen(!open);
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ticketnation Philippines | Privacy</title>
        <link rel="canonical" href="https://ticketnation.ph/privacy" />
      </Helmet>
      <div className="max-w-6xl px-5 py-5 mx-auto">
        <div className="py-4 text-4xl text-gray-600">Privacy Policy</div>
        <div className="py-4 text-4xl text-gray-600">Introduction</div>
        <div className="py-2 text-gray-500 text-md">
          Ticketnation Philippines (hereinafter referred to as "Ticketnation,"
          "we," "us," or "our") values your privacy and is committed to
          protecting your personal information. This Privacy Policy outlines the
          types of personal information we collect, how we use and disclose this
          information, and the steps we take to safeguard it when you use our
          website (
          <a href="https://www.ticketnation.ph/" className="text-blue-500">
            <u>www.ticketnation.ph</u>
          </a>
          ) or mobile application. By using our platform, you consent to the
          collection, use, and disclosure of your personal information in
          accordance with this Privacy Policy. You can revoke this consent at
          any time by contacting us at [Your Contact Email Address].
        </div>
        <div className="py-4 text-4xl text-gray-600">
          Information We Collect
        </div>
        <div className="py-2 text-gray-500 text-md">
          <h2>a. Personal Information</h2>
          When you register for an account or purchase tickets through
          Ticketnation, we may collect personal information, such as:
          <ul className="pt-2 pl-8 list-disc md:pl-12">
            <li>Full name</li>
            <li>Email address</li>
            <li>Contact number</li>
            <li>Billing address</li>
            <li>Credit card or other payment information</li>
          </ul>
        </div>

        <div className="py-2 text-gray-500 text-md">
          <h2>b. Non-Personal Information</h2>
          We may also collect non-personal information about your usage of our
          platform, including:
          <ul className="pt-2 pl-8 list-disc md:pl-12">
            <li>IP address</li>
            <li>Browser type and version</li>
            <li>Operating system</li>
            <li>Referral source</li>
            <li>Duration and frequency of visits</li>
          </ul>
        </div>

        <div className="py-4 text-4xl text-gray-600">
          How We Use Your Information
        </div>
        <div className="py-2 text-gray-500 text-md">
          <h2>We use your personal information for the following purposes:</h2>
          <ul className="pt-2 pl-8 list-disc md:pl-12">
            <li>
              To process your ticket purchases and provide you with e-tickets
            </li>
            <li>
              To communicate with you about your account, transactions, or
              events you have expressed interest in
            </li>
            <li>To provide customer support and respond to your inquiries</li>
            <li>
              To personalize your experience and tailor our content and
              recommendations to your preferences
            </li>
            <li>
              To conduct market research and improve our platform, products, and
              services
            </li>
            <li>
              To send promotional materials or other communications, with your
              consent
            </li>
          </ul>
        </div>

        <div className="py-4 text-4xl text-gray-600">
          How We Share Your Information
        </div>
        <div className="py-2 text-gray-500 text-md">
          <h2>
            We may disclose your personal information to third parties in the
            following circumstances:
          </h2>
          <ul className="pt-2 pl-8 list-disc md:pl-12">
            <li>
              Event Organizers: We share your information with event organizers
              for the purpose of ticket validation and admission to events.
            </li>
            <li>
              Service Providers: We may share your information with third-party
              service providers who perform functions on our behalf, such as
              payment processing, data analysis, and marketing services.
            </li>
            <li>
              Legal Requirements: We may disclose your information when required
              by law or in response to legal processes, such as subpoenas or
              court orders.
            </li>
            <li>
              Business Transfers: In the event of a merger, acquisition, or sale
              of assets, we may transfer your personal information to the
              acquiring party, subject to the terms of this Privacy Policy.
            </li>
          </ul>
          <h2 className="pt-2">Cookies</h2>
          Our platform uses cookies to enhance your user experience, track usage
          patterns, and improve our services. You can manage your cookie
          preferences through your browser settings.
        </div>

        <div className="py-4 text-4xl text-gray-600">Security</div>
        <div className="py-2 text-gray-500 text-md">
          We implement appropriate technical and organizational measures to
          protect your personal information from unauthorized access,
          disclosure, alteration, or destruction. These measures may include
          encryption, secure sockets layer (SSL) technology, firewalls, and
          access controls.
        </div>

        <div className="py-4 text-4xl text-gray-600">Third-Party Links</div>
        <div className="py-2 text-gray-500 text-md">
          Our platform may contain links to third-party websites, which are not
          governed by this Privacy Policy. We are not responsible for the
          privacy practices or content of such websites, and we encourage you to
          review their privacy policies before providing any personal
          information.
        </div>

        <div className="py-4 text-4xl text-gray-600">Children's Privacy</div>
        <div className="py-2 text-gray-500 text-md">
          Ticketnation does not knowingly collect personal information from
          children under the age of 13. If we become aware that we have
          collected personal information from a child under 13, we will promptly
          delete the information from our records.
        </div>

        <div className="py-4 text-4xl text-gray-600">Access and Correction</div>
        <div className="py-2 text-gray-500 text-md">
          You may access and update your personal information by logging into
          your Ticketnation account or contacting us at support@ticketnation.ph.
        </div>

        <div className="py-4 text-4xl text-gray-600">Data Deletion Request</div>
        <div className="py-2 text-gray-500 text-md">
          If you wish to request the deletion of your personal data held by us,
          you can do so at any time by contacting our Data Protection Officer at
          corporate@ticketnation.ph. Please include "Data Deletion Request" in the
          subject line of your email. Upon receiving your request, we will
          confirm receipt and begin the process of deleting your data from our
          systems. We will require some information from you to verify your
          identity before we can proceed with your request. This is to ensure
          that your personal data is not disclosed to anyone who has no right to
          receive it. Your personal data will be deleted from our systems within
          30 days of your request, except where we are obliged or permitted by
          law to retain it. If this is the case, we will let you know. If you
          have concerns about how we handle your personal data, please contact
          us. We will do our best to resolve your concern. Alternatively, you
          have the right to lodge a complaint with a supervisory authority.
        </div>
        <a
          href="https://forms.gle/4tjoHaSUqbdppFPq5"
          className="text-blue-500 underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here for user deletion
        </a>

        <div className="py-4 text-4xl text-gray-600">
          Changes to This Privacy Policy
        </div>
        <div className="py-2 text-gray-500 text-md">
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes will be effective immediately upon posting on our
          platform, and we will notify you of significant changes by email or
          other appropriate means.
        </div>

        <div className="py-4 text-4xl text-gray-600">Contact Us</div>
        <div className="py-2 text-gray-500 text-md">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at corporate@ticketnation.ph.
        </div>

        <div className="py-4 text-4xl text-gray-600">Last Updated</div>
        <div className="py-2 text-gray-500 text-md">
          This Privacy Policy was last updated on May 26, 2023.
        </div>
      </div>
    </div>
  );
}

export default PrivacyPage;
