import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube, FaEnvelope, FaPhone, FaGlobe, FaLink } from 'react-icons/fa';

export const iconMap = {
    facebook: FaFacebook,
    instagram: FaInstagram,
    linkedin: FaLinkedin,
    twitter: FaTwitter,
    youtube: FaYoutube,
    email: FaEnvelope,
    phone: FaPhone,
    website: FaGlobe,
    default: FaLink
}

export type IconName = keyof typeof iconMap;