import React from "react";
import { Performer } from "../../models/performer.model";
import BannerArtistDefault from "../../assets/images/defaultartistpic.jpg";
import { getImageURL } from "../../helpers/imagehelper";
import { Link } from "react-router-dom";
interface ComponentsProps {
  members: Performer[];
}
export const Members: React.FC<ComponentsProps> = ({ members }) => {
  return (
    <>
      <h2 className="text-xl font-semibold lg:text-2xl">Members</h2>

      <div className="grid grid-cols-3 gap-1 lg:gap-4 lg:grid-cols-8 ">
        {members.map((member, index) => {
          return (
            <Link to={`/artist/${member.id}/performer`} key={index}>
              <div key={member.id} className="flex flex-col">
                <img
                  className="object-cover rounded-sm shadow-md h-36 w-36"
                  alt={`performer-${member.position}`}
                  src={
                    getImageURL(member.image?.fileName || "") ||
                    BannerArtistDefault
                  }
                />
                <span className="text-lg font-semibold">{member.name}</span>
                <span className="text-sm">{member.position}</span>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
};
