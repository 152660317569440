import React from "react";
import { ArtistCard } from "../../components";
import { getImageURL } from "../../helpers/imagehelper";
import { useGetArtistsQuery } from "../../services/artist.api";
import DefaultArtistPic from "../../assets/images/defaultartistpic.jpg";

export const ArtistList = ({ search = "" }) => {
  const initTake = 8;
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take, setTake] = React.useState(initTake);

  const { data, isLoading, isError } = useGetArtistsQuery({
    skip,
    take: initTake * page,
    search: search || "",
  });

  if (isLoading) {
    return <h2>Loading...</h2>;
  }

  return (
    <div className="px-2 mb-2 lg:px-0">
      {data && data.data.length > 0 ? (
        <div className="grid grid-cols-1 gap-2 lg:gap-4 md:grid-cols-3 lg:grid-cols-4">
          {data.data.map((artist, index) => {
            return (
              <ArtistCard
                name={artist.name}
                description={artist.description}
                image={
                  getImageURL(artist.image?.fileName || "") || DefaultArtistPic
                }
                id={artist.id}
                key={index}
              />
            );
          })}
          {data?.count > initTake * page && (
            <div className="flex justify-center my-4 lg:col-span-4 md:grid-cols-3">
              <button
                className="w-48 p-2 font-semibold text-white uppercase border rounded-sm drop-shadow bg-blue-1100 hover:border-blue-1100 hover:bg-white hover:text-blue-1100"
                onClick={() => setPage(page + 1)}
              >
                See more
              </button>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="text-xl">
            <p>No Available Artist</p>
          </div>
        </>
      )}
    </div>
  );
};
